import request from '@/utils/request';
import {
  envInfo
} from '@/constants/envInfo';

/**
 * 清除设备影子的相应属性期望值
 * @param params
 */
export function clearShadowDesired(params) {
  return request({
    url: envInfo.bgApp.setPath + '/device/shadow/desired/clear',
    method: 'post',
    data: params,
  });
}

/**
 * 设置设备影子的相应属性期望值
 * @param params
 */
export function setShadowDesired(params) {
  return request({
    url: envInfo.bgApp.setPath + '/device/shadow/desired/set',
    method: 'post',
    data: params,
  });
}
/**
 * 设置设备影子的相应属性期望值
 * @param params
 */
export function setDeviceProperties(params) {
  return request({
    url: envInfo.bgApp.setPath + '/device/setDeviceProperties',
    method: 'post',
    data: params,
  });
}