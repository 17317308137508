<template>
  <div class="device-info-comp" v-loading="loading">
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.product.basicInformation") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.device.basicInfoTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 设备标识码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceName") }}
          </div>
          <div class="detail-value">
            {{ detailInfo && detailInfo.deviceName }}
          </div>
        </div>
      </div>
      <div class="product-info">
        <div
          class="product-detail-info"
          @click="showAllProductInfo = !showAllProductInfo"
        >
          <div class="left-part">
            <span class="detail-header-title">
              {{ `所属产品信息（${detailInfo.productName || "-"}）` }}
            </span>
            <span class="detail-header-tips">
              {{ $t("vlink.product.basicInfoTips") }}，
            </span>
            <span class="look-detail" @click.stop="jumpToProductHandler">
              查看详情
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="right-part">
            {{ showAllProductInfo ? "收起所属产品信息" : "展开所属产品信息" }}
            <i class="el-icon-arrow-up" v-if="showAllProductInfo"></i>
            <i class="el-icon-arrow-down" v-else></i>
          </div>
        </div>
        <div v-show="showAllProductInfo" class="detail-cont">
          <!-- 产品名称 -->
          <div>
            <div class="detail-label">
              {{ $t("vlink.product.productName") }}
            </div>
            <div class="detail-value">
              {{ (detailInfo && detailInfo.productName) || "-" }}
            </div>
          </div>
          <!-- 节点类型 -->
          <div>
            <div class="detail-label">
              {{ $t("vlink.product.nodeType") }}
            </div>
            <div class="detail-value">
              {{ nodeTypeRender(detailInfo && detailInfo.nodeType) }}
            </div>
          </div>
          <!-- 数据格式 -->
          <div>
            <div class="detail-label">
              {{ $t("vlink.product.dataType") }}
            </div>
            <div class="detail-value">
              {{ dataTypeRender(detailInfo && detailInfo.dataType) }}
            </div>
          </div>
          <!-- 认证方式 -->
          <div>
            <div class="detail-label">
              {{ $t("vlink.product.authType") }}
            </div>
            <div class="detail-value">
              {{ authTypeRender(detailInfo && detailInfo.authType) }}
            </div>
          </div>
          <!-- 协议类型 -->
          <div class="last-line">
            <div class="detail-label">
              {{ $t("vlink.product.protocol") }}
            </div>
            <div class="detail-value">
              {{ detailInfo && $t("vlink.commons." + detailInfo.protocol) }}
            </div>
          </div>
          <!-- 产品标识码 -->
          <div>
            <div class="detail-label">
              {{ $t("vlink.product.productKey") + " ProductKey" }}
            </div>
            <div class="detail-value">
              {{ detailInfo && detailInfo.productKey }}
            </div>
          </div>
        </div>
      </div>

      <div class="product-info" v-if="detailInfo.nodeType === 'SUBDEVICE'">
        <div
          class="product-detail-info"
          @click="showAllGatewayInfo = !showAllGatewayInfo"
        >
          <div class="left-part">
            <span class="detail-header-title">
              {{
                `所属网关信息（${
                  detailInfo && detailInfo.parentDeviceDescription
                    ? detailInfo.parentDeviceDescription
                    : detailInfo.parentDeviceName || "-"
                }）`
              }}
            </span>
            <span class="detail-header-tips">
              {{ $t("vlink.product.basicInfoTips") }}，
            </span>
            <span class="look-detail" @click.stop="jumpToGatewayHandler">
              查看详情
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="right-part">
            {{ showAllGatewayInfo ? "收起所属网关信息" : "展开所属网关信息" }}
            <i class="el-icon-arrow-up" v-if="showAllGatewayInfo"></i>
            <i class="el-icon-arrow-down" v-else></i>
          </div>
        </div>
        <div class="detail-cont" v-show="showAllGatewayInfo">
          <!-- 网关产品名称 -->
          <div>
            <div class="detail-label">网关产品名称</div>
            <div class="detail-value">
              {{ (detailInfo && detailInfo.parentProductName) || "-" }}
            </div>
          </div>
          <!-- 网关产品标识码 -->
          <div>
            <div class="detail-label">网关产品标识码 ProductKey</div>
            <div class="detail-value">
              {{ (detailInfo && detailInfo.parentProductKey) || "-" }}
            </div>
          </div>
          <!-- 网关名称 -->
          <div>
            <div class="detail-label">网关名称</div>
            <div class="detail-value">
              {{ (detailInfo && detailInfo.parentDeviceDescription) || "-" }}
            </div>
          </div>
          <!-- 网关设备标识码 -->
          <div>
            <div class="detail-label">网关设备标识码 DeviceName</div>
            <div class="detail-value">
              {{ (detailInfo && detailInfo.parentDeviceName) || "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.product.certificateAndTime") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.device.certificateAndTimeTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 产品标识码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.product.productKey") + " ProductKey" }}
          </div>
          <div class="detail-value">
            {{ detailInfo && detailInfo.productKey }}
          </div>
        </div>
        <!-- 创建时间 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.createTime") }}
          </div>
          <div class="detail-value">
            {{
              detailInfo &&
              detailInfo.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}
          </div>
        </div>
        <!-- 设备标识码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceName") + " DeviceName" }}
          </div>
          <div class="detail-value">
            {{ detailInfo && detailInfo.deviceName }}
          </div>
        </div>
        <!-- 激活时间 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.activationTime") }}
          </div>
          <div class="detail-value">
            {{
              detailInfo &&
              detailInfo.activationTime | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}
          </div>
        </div>
        <!-- 设备秘钥 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceSecret") + " DeviceSecret" }}
            <span class="copy-container" @click="copyTheSecret">
              <i class="el-icon-document-copy"></i>
              复制证书
            </span>
            <span class="copy-container" @click="dialogTableVisible = true">
              <i class="el-icon-tickets"></i>
              查看MQTT连接参数
            </span>
          </div>
          <div class="detail-value">
            <el-tooltip
              class="item view-tooltips"
              effect="dark"
              :content="$t('vlink.product.seeProductSecret')"
              placement="bottom"
            >
              <span @click="showeSecret = !showeSecret">
                <i
                  v-if="showeSecret"
                  class="iconfont icon-yanjing-1 el-icon-view"
                ></i>
                <i v-else class="iconfont icon-biyan el-icon-view"></i>
              </span>
            </el-tooltip>
            <span
              :style="{
                position: 'relative',
                top: showeSecret ? '0' : '2px',
              }"
            >
              {{ showeSecret ? certInfo.deviceSecret : "******" }}
            </span>
          </div>
        </div>
        <!-- 最后上线时间 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.lastOnlineTime") }}
            <span class="copy-container" @click="deviceOpeHistory">
              <i class="el-icon-tickets"></i>
              设备上下线历史
            </span>
          </div>
          <div class="detail-value">
            {{
              detailInfo &&
              detailInfo.lastOnlineTime | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}
          </div>
        </div>
        <!-- <div class="download-cont" @click="sequenceDownload">
          <i class="el-icon-download"></i>
          下载证书
        </div> -->
      </div>
    </div>
    <div class="card-container">
      <div class="header-cont">
        <span class="header-title">
          {{ $t("vlink.device.deviceConfig") }}
        </span>
        <span class="header-tips">
          <i class="el-icon-info" />
          {{ $t("vlink.device.productConfigTips") }}
        </span>
      </div>
      <div class="detail-cont">
        <!-- 设备标识码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceStatus") }}
            <span class="copy-container" @click="refreshData">
              <i class="el-icon-refresh"></i>
              {{ $t("commons.refresh") }}
            </span>
          </div>
          <div class="detail-value">
            <el-tag
              style="margin-right: 8px"
              :type="
                detailInfo.deviceStatus == 'INACTIVE'
                  ? 'info'
                  : detailInfo.deviceStatus == 'ONLINE'
                  ? 'success'
                  : 'danger'
              "
            >
              <span v-if="detailInfo.deviceStatus == 'INACTIVE'">{{
                $t("vlink.commons.inactive")
              }}</span>
              <span v-if="detailInfo.deviceStatus == 'ONLINE'">{{
                $t("vlink.commons.online")
              }}</span>
              <span v-if="detailInfo.deviceStatus == 'OFFLINE'">{{
                $t("vlink.commons.offline")
              }}</span>
            </el-tag>

            <span
              class="copy-container"
              v-if="
                detailInfo.nodeType === 'GATEWAY' &&
                (detailInfo.deviceStatus == 'ONLINE' ||
                  (detailInfo.deviceStatus == 'OFFLINE' &&
                    detailInfo.deviceName != null))
              "
              @click="deviceStateDialogShow"
            >
              <i class="el-icon-s-marketing"></i>
              查看设备状态探测
            </span>
          </div>
        </div>
        <!-- 设备名称 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceDescription") }}
          </div>
          <div class="detail-value">
            <div class="detail-value">
              <span v-if="descriptionEdit">
                <el-input
                  ref="descInput"
                  maxlength="50"
                  v-model="detailInfo.description"
                  @keyup.enter.native="updateDesc"
                  size="mini"
                ></el-input>
              </span>
              <span v-else>
                {{
                  detailInfo && detailInfo.description
                    ? detailInfo.description
                    : "-"
                }}
              </span>
              <i
                v-if="!descriptionEdit"
                class="el-icon-edit"
                @click="showDescriptionEdit"
              ></i>
              <span v-else style="margin-left: 10px">
                <el-button
                  @click="updateDesc"
                  type="success"
                  size="mini"
                  icon="el-icon-check"
                  circle
                ></el-button>
                <el-button
                  @click="
                    descriptionEdit = false;
                    detailInfo.description = descriptionBak;
                  "
                  size="mini"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </span>
            </div>
          </div>
        </div>
        <!-- 设备编码 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.deviceCode") }}
          </div>
          <div class="detail-value">
            <div class="detail-value">
              <span v-if="deviceCodeEdit">
                <el-input
                  ref="codeInput"
                  maxlength="50"
                  v-model.trim="detailInfo.deviceCode"
                  @keyup.enter.native="updateDeviceCode"
                  size="mini"
                ></el-input>
              </span>
              <span v-else>
                {{
                  detailInfo && detailInfo.deviceCode
                    ? detailInfo.deviceCode
                    : "-"
                }}
              </span>
              <i
                v-if="!deviceCodeEdit"
                class="el-icon-edit"
                @click="showDeviceCodeEdit"
              ></i>
              <span v-else style="margin-left: 10px">
                <el-button
                  @click="updateDeviceCode"
                  type="success"
                  size="mini"
                  icon="el-icon-check"
                  circle
                ></el-button>
                <el-button
                  @click="
                    deviceCodeEdit = false;
                    detailInfo.deviceCode = deviceCodeBak;
                  "
                  size="mini"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </span>
            </div>
          </div>
        </div>
        <!-- 启用设备 -->
        <div>
          <div class="detail-label">启用设备</div>
          <div class="detail-value">
            <el-switch
              @change="updateEnableStatus"
              v-model="detailInfo.enableStatus"
              active-value="ENABLE"
              inactive-value="DISABLE"
              active-color="#13ce66"
            >
            </el-switch>
          </div>
        </div>
        <!-- 隔离状态 -->
        <div>
          <div class="detail-label">
            隔离状态
            <span
              v-if="showISOButton(detailInfo.isolateEndTime)"
              class="copy-container"
              @click="isoHandler(detailInfo)"
            >
              <i class="el-icon-timer"></i>
              隔离
            </span>
            <span
              v-else
              class="copy-container"
              @click="cancelIsoHandler(detailInfo)"
            >
              <i class="el-icon-refresh-left"></i>
              取消隔离
            </span>
          </div>
          <div>
            <!-- <div v-if="showISOButton(detailInfo.isolateEndTime)">
              <el-button size="mini" type="primary" @click="isoHandler(detailInfo)"
                >隔离</el-button
              >
            </div>
            <div v-else>
              <el-button
                size="mini"
                type="primary"
                @click="cancelIsoHandler(detailInfo)"
                >取消隔离</el-button
              >
            </div> -->
            <span class="detail-value">
              {{ isoTimeRender(detailInfo.isolateEndTime) }}
            </span>
          </div>
        </div>
        <!-- 启用设备 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.localLogReport") }}
          </div>
          <div class="detail-value">
            <el-switch
              @change="updateLocalLogReport"
              v-model="detailInfo.localLogReport"
              active-value="ENABLE"
              inactive-value="DISABLE"
              active-color="#13ce66"
            >
            </el-switch>
          </div>
        </div>
        <!-- 设备标签 -->
        <div>
          <div class="detail-label">
            {{ $t("vlink.device.label") }}
          </div>
          <div>
            <productTagBind
              v-if="detailInfo && detailInfo.tagList"
              :tagValueList="detailInfo.tagList"
              tagType="device"
              @tagDelete="handleTagDelete"
              @tagAdd="handleTagAdd"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 扩展配置 -->
    <div class="card-container" v-if="extendInfoList.length">
      <div class="header-cont config-header">
        <span class="header-cont">
          <span class="header-title"> 扩展信息 </span>
          <span class="header-tips">
            <i class="el-icon-info" />
            用户自由配置的扩展信息
          </span>
        </span>
        <span class="edit-span" @click="openEditDialog">
          <i class="el-icon-edit"></i>
          编辑
        </span>
      </div>
      <div class="detail-cont">
        <el-row class="config-row">
          <el-col
            v-for="item in extendInfoList"
            :key="item.label"
            :span="item.layout === 'all' ? 24 : 12"
            style="margin-top: 20px"
          >
            <div class="detail-label">
              {{ item.label }}
            </div>
            <div class="detail-value">
              <span
                :id="item.id"
                class="desc-span"
                v-html="item.value"
                :title="item.value"
              >
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 设备上下线历史弹框 -->
    <el-dialog width="900px" top="50px" :visible.sync="deviceOpeHistoryShow">
      <template slot="title">
        <span>设备上下线历史</span>
      </template>
      <deviceOpeHistory v-if="deviceOpeHistoryShow" :currentRow="currentRow" />
    </el-dialog>

    <!-- MQTT连接参数 -->
    <el-dialog title="MQTT 连接参数" :visible.sync="dialogTableVisible">
      <el-descriptions class="margin-top" :column="1" border>
        <el-descriptions-item>
          <template slot="label"> 客户机标识 </template>
          {{ mqttForm.clientId }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 账号 </template>
          {{ mqttForm.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 密码 </template>
          {{ mqttForm.password }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 主机地址 </template>
          {{ mqttForm.hostUrl }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 端口号 </template>
          {{ mqttForm.port }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="copyMqtt">复 制</el-button>
      </span>
    </el-dialog>

    <!-- 设备状态探测 -->
    <el-dialog
      title="设备状态探测"
      width="960px"
      :visible.sync="dialogDeviceStatus"
      :before-close="handleClose"
    >
      <template>
        <div id="container">
          <!--图表容器-->
          <div id="newCharts"></div>
          <div class="chart-table">
            <template>
              <el-table
                id="tableEchats"
                :data="tableData"
                ref="echatsTable"
                height="380"
                :row-class-name="tableRowClassName"
                style="width: 100%"
              >
                <el-table-column prop="date" label="日期" width="160">
                </el-table-column>
                <el-table-column prop="diff" label="耗时(ms)" width="150">
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </template>
    </el-dialog>

    <!-- 隔离时间 -->
    <el-dialog
      title="隔离时间"
      :visible.sync="timeShow"
      width="30%"
      :before-close="cancelTime"
      style="height: 600px"
    >
      <template>
        <el-select v-model="isolateTimeValue" placeholder="请选择">
          <el-option
            v-for="item in isolateTimeSelect"
            :key="item.isolateTimeValue"
            :label="item.isolateTime"
            :value="item.isolateTimeValue"
          >
          </el-option>
        </el-select>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTime">取 消</el-button>
        <el-button type="primary" @click="batchIsolateTime">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 扩展信息编辑弹框 -->
    <ExtendEditDialog :dataset="extendDialog" @close="extendDialogClose" />
  </div>
</template>
<!--&& -->
<script>
// import deviceTagBind from "./deviceTag";
import productTagBind from "../product/productTag/index.vue";
import { copy2Clip } from "@/utils/global";
import deviceOpeHistory from "./deviceOpeHistory.vue";
import ExtendEditDialog from "./components/extendEditDialog.vue";
import * as echarts from "echarts";
import {
  findDeviceDetail,
  viewCert,
  updateEnableStatus,
  updateLocalLogReport,
  deleteTagByTagNameAndDeviceId,
  addTagByTagNameAndDeviceId,
  updateDeviceName,
  updateDeviceCode,
  findMqttParam,
  callDeviceService,
  cancelIsolateTime,
  batchIsolateTime,
} from "@/api/ruge/vlink/device/device";
import { dynamicLabelRender } from "@/utils/dynamicUtils.js";

export default {
  components: {
    productTagBind,
    deviceOpeHistory,
    ExtendEditDialog,
  },
  data() {
    return {
      extendDialog: {
        show: false,
        extendJson: null,
        deviceId: null,
      },
      extendInfoList: [],
      isolateTimeSelect: [
        { isolateTime: this.$t("commons.min30"), isolateTimeValue: "30" },
        { isolateTime: this.$t("commons.min60"), isolateTimeValue: "60" },
        { isolateTime: this.$t("commons.min120"), isolateTimeValue: "120" },
        { isolateTime: this.$t("commons.min180"), isolateTimeValue: "180" },
        { isolateTime: this.$t("commons.min360"), isolateTimeValue: "360" },
        { isolateTime: this.$t("commons.min720"), isolateTimeValue: "720" },
        { isolateTime: this.$t("commons.min1440"), isolateTimeValue: "1440" },
      ],
      timeShow: false,
      callFlag: true,
      myChart: null,
      categories: [],
      seriesData: [],
      dialogDeviceStatus: false,
      deviceOpeHistoryShow: false,
      descriptionBak: null,
      deviceCodeBak: null,
      showAllProductInfo: false,
      showAllGatewayInfo: false,
      descriptionEdit: false,
      deviceCodeEdit: false,
      loading: false,
      detailInfo: {},
      tagList: [],
      certDialogVisible: false,
      certInfo: {},
      showeSecret: false,
      dialogTableVisible: false,
      tableData: [],
      instance: null,
      form: {
        productKey: "",
        deviceName: "",
        moduleName: "systemInfo",
        serviceName: "deviceStatus",
        callType: "sync",
        inputData: null,
        timeout: 2000,
      },
      mqttForm: {
        clientId: null,
        userName: null,
        password: null,
        hostUrl: null,
        port: null,
      },
      isoForm: {
        deviceId: "",
        isolateTime: "",
      },
      isolateTimeValue: "30",
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productKey() {
      return this.currentRow.productKey;
    },
  },
  watch: {
    // productKey: {
    //   immediate: true,
    //   handler: function () {
    //     this.getDetail();
    //   },
    // },
    currentRow: {
      handler(rows) {
        this.detailInfo = rows;
      },
      immediate: true,
      deep: true,
    },
    detailInfo: {
      handler(val) {
        if (JSON.stringify(this.certInfo) === "{}") {
          this.viewCert();
          this.openMqtt();
        }
        if (this.descriptionBak === null && val.description) {
          this.descriptionBak = val.description || "";
        }
        if (this.deviceCodeBak === null && val) {
          this.deviceCodeBak = val.deviceCode || "";
        }
        if (val && val.extendInfoTemplate) {
          this.extendInfoRender(val);
        }
      },
    },
  },
  methods: {
    openEditDialog() {
      this.extendDialog.deviceId = this.detailInfo.deviceId;
      this.extendDialog.show = true;
    },
    extendDialogClose(freshFlag) {
      this.extendDialog.deviceId = null;
      this.extendDialog.show = false;
      freshFlag === true && this.$emit("fresh");
    },
    extendInfoRender(datas) {
      try {
        const parsedList = JSON.parse(datas.extendInfoTemplate).json;
        const simpleMap = datas.extendInfo ? JSON.parse(datas.extendInfo) : {};
        // extendInfo中的值覆盖掉产品配置中的值
        const coveredList = parsedList.map((item) => {
          const itemCode = item.propsList.filter(
            (single) => single.key === "code"
          )[0].value;
          item.propsList.forEach((single) => {
            if (single.key === "default" && simpleMap[itemCode]) {
              single.value = simpleMap[itemCode];
            }
          });
          return item;
        });
        this.extendDialog.extendJson = coveredList;
        this.extendInfoList = coveredList.map((item) => {
          return {
            label: item.propsList.filter((single) => single.key === "label")[0]
              .value,
            value: dynamicLabelRender(item),
            layout: item.propsList.filter(
              (single) => single.key === "layout"
            )[0].value,
            id: item.id,
          };
        });
      } catch (error) {
        console.error("error - extendInfo parse failed:", error);
      }
    },
    batchIsolateTime() {
      this.isoForm.isolateTime = this.isolateTimeValue;
      batchIsolateTime(this.isoForm)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.cancelTime();
          this.getDetail();
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelTime() {
      this.isolateTimeValue = "30";
      this.isoForm.isolateTime = null;
      this.timeShow = false;
    },
    cancelIsoHandler(row) {
      this.$confirm(
        this.$t("message.CancelConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.isoForm.deviceId = row.deviceId;
          // 取消隔离时间
          cancelIsolateTime(this.isoForm).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.saveSuccess"),
              type: "success",
            });
            this.getDetail();
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    isoHandler(row) {
      this.timeShow = true;
      this.isoForm.deviceId = row.deviceId;
    },
    isoTimeRender(time) {
      if (!time) return "未隔离";
      const endTime = new Date(time).getTime();
      const currentTime = new Date().getTime();
      if (currentTime >= endTime) return "未隔离";
      return this.dealIosTime(endTime - currentTime);
    },
    dealIosTime(timeDiff) {
      let date3 = timeDiff; //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      if (
        (days && days > 0) ||
        (hours && hours > 0) ||
        (minutes && minutes > 0) ||
        (seconds && seconds > 0)
      ) {
        return (
          (days > 0 ? days + "天" : "") +
          (hours > 0 ? hours + "小时" : "") +
          (minutes > 0 ? minutes + "分" : "") +
          (seconds > 0 ? seconds + "秒" : "")
        );
      } else {
        return 0 + "分";
      }
    },
    showISOButton(time) {
      if (!time) return true;
      const endTime = new Date(time).getTime();
      const currentTime = new Date().getTime();
      return currentTime >= endTime;
    },
    tableRowClassName({ row }) {
      if (row.errorFlag) {
        return "warning-row";
      }
      return "";
    },
    handleClose() {
      console.log("this.instance", this.instance);
      this.categories = [];
      this.seriesData = [];
      this.dialogDeviceStatus = false;
      this.tableData = [];
      window.clearInterval(this.instance);
    },
    deviceStateDialogShow() {
      this.dialogDeviceStatus = true;
      this.$nextTick(function () {
        this.showCharts();
        this.callService();
      });
    },
    // 展示折线图
    showCharts() {
      // this.categories = (function () {
      //   let now = new Date();
      //   return [now.toLocaleTimeString().replace(/^\D*/, '') + '.' + now.getMilliseconds()];
      // })();
      //
      //  this.seriesData = (function () {
      //   return [500];
      // })();

      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById("newCharts")); //也可以通过$refs.newCharts的方式去获取到dom实例。
      // 绘制图表
      this.myChart.setOption({
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            data: this.categories,
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            boundaryGap: [0.2, 0.2],
            axisPointer: {
              snap: true,
            },
          },
        ],
        series: [
          {
            name: "响应时间(ms)",
            type: "line",
            data: this.seriesData,
            markLine: {
              data: [
                {
                  yAxis: 2000,
                  lineStyle: {
                    color: "red",
                  },
                },
              ],
            },
            itemStyle: {
              normal: {
                color: "#8cd5c2", //改变折线点的颜色
                lineStyle: {
                  color: "#8cd5c2", //改变折线颜色
                },
              },
            },
          },
        ],
      });
    },
    dateFormat() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let milliseconds =
        date.getMilliseconds() < 10
          ? "0" + date.getMilliseconds()
          : date.getMilliseconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        "." +
        milliseconds
      );
    },
    dateFormat2() {
      let date = new Date();
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let milliseconds =
        date.getMilliseconds() < 10
          ? "0" + date.getMilliseconds()
          : date.getMilliseconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    callService() {
      this.getData();
      this.instance && window.clearInterval(this.instance);
      this.instance = setInterval(() => {
        // 发送消息
        this.getData();
      }, 3000);
    },
    getData: function () {
      const params = { ...this.form };
      params.productKey = this.currentRow.productKey;
      params.deviceName = this.currentRow.deviceName;
      this.tableData.push({ date: this.dateFormat2(), title: "发送探测请求" });
      // X轴数据
      // let axisData = new Date().toLocaleTimeString().replace(/^\D*/, '') + '.' + new Date().getMilliseconds()
      let axisData = `${new Date().getMinutes()}:${new Date().getSeconds()}`;
      this.categories.length > 6 && this.categories.shift();
      this.categories.push(axisData);

      this.seriesData.length > 6 && this.seriesData.shift();
      this.callFlag && this.deviceServiceHandler(params);
    },

    chartAndTableRender() {
      this.myChart.setOption({
        xAxis: [
          {
            data: this.categories,
          },
        ],
        series: [
          {
            data: this.seriesData,
          },
        ],
      });
      this.$nextTick(() => {
        const instance = this.$refs["echatsTable"];
        instance.bodyWrapper.scrollTop = instance.bodyWrapper.scrollHeight;
      });
    },
    deviceServiceHandler(params) {
      this.callFlag = false;
      callDeviceService(params)
        .then((data) => {
          // 成功提示
          if (data.code == 200) {
            let diff = this.diffTimeHour(
              this.tableData[this.tableData.length - 1].date,
              this.dateFormat()
            );
            this.tableData.pop();
            this.tableData.push({ date: this.dateFormat2(), diff: diff });
            this.seriesData.push(diff);
          } else {
            this.$message({
              message:
                this.$t("vlink.tsl.callServiceFail") + ":" + data.message,
              type: "error",
            });
            let diff = this.diffTimeHour(
              this.tableData[this.tableData.length - 1].date,
              this.dateFormat()
            );
            this.tableData.pop();
            this.tableData.push({
              date: this.dateFormat2(),
              diff: diff,
              errorFlag: true,
            });
            this.seriesData.push(diff);
          }
          this.chartAndTableRender();
        })
        .catch((error) => {
          console.log(`调用服务失败，原因 => ${error}`);
        })
        .finally(() => {
          this.callFlag = true;
        });
    },
    diffTimeHour(oldTime, newTime) {
      const timeDiff =
        new Date(newTime.replace(/-/g, "/")).getTime() -
        new Date(oldTime.replace(/-/g, "/")).getTime();
      let residue3 = timeDiff;
      return residue3;
    },
    deviceOpeHistory() {
      this.deviceOpeHistoryShow = true;
    },
    sequenceDownload() {
      const json = `ProductKey: ${this.certInfo.productKey}\nDeviceName:${this.certInfo.deviceName}\nDeviceSecret: ${this.certInfo.deviceSecret}`;
      const asemName = "txt";
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(json)
      );
      element.setAttribute("download", asemName);
      element.style.display = "none";
      element.click();
    },
    jumpToGatewayHandler() {
      const { origin, pathname } = window.location;
      const { parentProductKey, parentDeviceName } = this.detailInfo;
      const targetUrl = `${origin}${pathname}#/iot/device/detail?productKey=${parentProductKey}&deviceName=${parentDeviceName}`;
      window.open(targetUrl, "_blank");
    },
    jumpToProductHandler() {
      const { origin, pathname } = window.location;
      const { productKey } = this.detailInfo;
      const targetUrl = `${origin}${pathname}#/iot/product/detail?productKey=${productKey}`;
      window.open(targetUrl, "_blank");
    },
    //删除标签
    handleTagDelete(tag, tagValueList) {
      this.$confirm("此操作将删除设备标签,您确定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var deviceId = this.detailInfo.deviceId;
          var tagName = tag;
          // 删除设备标签
          deleteTagByTagNameAndDeviceId(deviceId, tagName)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("删除成功"),
                type: "success",
              });

              // 删除页面标签
              /*if(this.tagList.findIndex(item => item.value == tag) == -1){
                  this.tagList.push({"value":tag});
                }*/
              tagValueList.splice(tagValueList.indexOf(tag), 1);
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            })
            .finally(() => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //添加标签
    handleTagAdd(tag, tagValueList) {
      // 已有此标签
      if (tagValueList.indexOf(tag) != -1) {
        this.$message({
          message: this.$t("已有此标签"),
          type: "error",
        });
        return;
      }
      var deviceId = this.detailInfo.deviceId;
      addTagByTagNameAndDeviceId(deviceId, tag)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("添加成功"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`添加失败，原因 => ${error}`);
        })
        .finally(() => {
          tagValueList.push(tag);
        });
    },
    openMqtt() {
      if (!this.detailInfo.productKey || !this.detailInfo.deviceName) return;
      findMqttParam({
        productKey: this.detailInfo.productKey,
        deviceName: this.detailInfo.deviceName,
      })
        .then((response) => {
          this.mqttForm = response;
        })
        .catch((error) => {
          console.log(`操作失败，原因 => ${error}`);
        });
    },
    updateEnableStatus() {
      this.loading = true;
      updateEnableStatus(this.detailInfo)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          this.getDetail();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateLocalLogReport() {
      this.loading = true;
      updateLocalLogReport(this.detailInfo)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          this.getDetail();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDesc() {
      const { deviceId, description } = this.detailInfo;
      updateDeviceName({ deviceId, description }).then((res) => {
        this.$message.success(this.$t("message.saveSuccess"));
        this.descriptionEdit = false;
        this.descriptionBak = description;
      });
    },
    updateDeviceCode() {
      const { deviceId, deviceCode } = this.detailInfo;
      updateDeviceCode({ deviceId, deviceCode }).then((res) => {
        this.$message.success(this.$t("message.saveSuccess"));
        this.deviceCodeEdit = false;
        this.deviceCodeBak = deviceCode;
      });
    },
    showDescriptionEdit() {
      this.descriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descInput.focus();
        this.$refs.descInput.select();
      });
    },
    showDeviceCodeEdit() {
      this.deviceCodeEdit = true;
      this.$nextTick(() => {
        this.$refs.codeInput.focus();
        this.$refs.codeInput.select();
      });
    },
    refreshData() {
      this.getDetail(true);
    },
    copyTheSecret() {
      const tempObj = {
        ProductKey: this.certInfo.productKey,
        DeviceName: this.certInfo.deviceName,
        DeviceSecret: this.certInfo.deviceSecret,
      };
      copy2Clip(JSON.stringify(tempObj));
    },
    copyMqtt() {
      const tempObj = {
        clientId: this.mqttForm.clientId,
        userName: this.mqttForm.userName,
        password: this.mqttForm.password,
        hostUrl: this.mqttForm.hostUrl,
        port: this.mqttForm.port,
      };
      copy2Clip(JSON.stringify(tempObj));
    },
    viewCert() {
      if (!this.detailInfo.productKey || !this.detailInfo.deviceName) return;
      viewCert({
        productKey: this.detailInfo.productKey,
        deviceName: this.detailInfo.deviceName,
      })
        .then((response) => {
          this.certInfo = response;
        })
        .catch((error) => {
          console.log(`操作失败，原因 => ${error}`);
        });
    },
    deviceStateRender(type) {
      switch (type) {
        case "SECRETKEY":
          return this.$t("vlink.commons.secretkey");
        case "X509":
          return this.$t("vlink.commons.x509");
        default:
          return "-";
      }
    },
    authTypeRender(type) {
      switch (type) {
        case "SECRETKEY":
          return this.$t("vlink.commons.secretkey");
        case "X509":
          return this.$t("vlink.commons.x509");
        default:
          return "-";
      }
    },
    dataTypeRender(type) {
      switch (type) {
        case "JSON":
          return this.$t("vlink.commons.json");
        case "BINARY":
          return this.$t("vlink.commons.binary");
        default:
          return "-";
      }
    },
    nodeTypeRender(type) {
      switch (type) {
        case "DIRECTDEVICE":
          return this.$t("vlink.commons.directDevice");
        case "GATEWAY":
          return this.$t("vlink.commons.gateway");
        case "SUBDEVICE":
          return this.$t("vlink.commons.subDevice");
        default:
          return "-";
      }
    },
    getDetail(messageInfo) {
      this.loading = true;
      findDeviceDetail({
        productKey: this.currentRow.productKey,
        deviceName: this.currentRow.deviceName,
      })
        .then((response) => {
          if (response.tagList.length == 0) {
            this.tagList = [];
          } else {
            this.tagList = response.tagList;
          }
          //!response.tagList ? this.tagList = [] : this.tagList = response.tagList;
          this.detailInfo = response;
          // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
          this.detailInfo.deviceStatus = this.getDeviceStatus(
            this.detailInfo.deviceStatus,
            this.detailInfo.parentDeviceStatus
          );
          this.$eventBus.$emit("setDetailAlias", response.deviceName);
          // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
          messageInfo && this.$message.success("刷新成功！");
        })
        .catch((error) => {
          console.log(`服务请求失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
    getDeviceStatus(deviceStatus, parentDeviceStatus) {
      // 未激活
      if (deviceStatus == "INACTIVE") {
        return deviceStatus;
      }
      // 如果是子设备，网关设备离线，则子设备也显示离线
      if (parentDeviceStatus && parentDeviceStatus == "OFFLINE") {
        return "OFFLINE";
      } else {
        return deviceStatus;
      }
    },
  },
};
</script>

<style scoped lang="less">
.device-info-comp {
  .copy-container {
    margin: 0 5px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    cursor: pointer;
    color: #0486fe;
    &:hover {
      color: #0f2fd5;
    }
  }
  .el-icon-s-marketing {
    margin-left: 10px;
  }
  .card-container {
    background-color: #f8f8f8;
    padding: 32px 32px 10px;
    margin-bottom: 24px;
    .config-header {
      display: flex;
      justify-content: space-between;
      .edit-span {
        font-weight: 600;
        font-size: 14px;
        color: #2a61ff;
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-icon-edit {
          margin-right: 3px;
        }
      }
    }
    .info-hide-devider {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin: 20px 0;
      background: #f2f4f7;
      border-radius: 10px;
      padding: 20px 10px;
    }
    .header-cont {
      display: flex;
      align-items: center;
      .header-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .header-tips {
        margin-left: 16px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000;
        opacity: 0.7;
        i {
          opacity: inherit;
        }
      }
    }
    .detail-cont {
      flex-wrap: wrap;
      display: flex;
      margin-bottom: 15px;
      & > div {
        width: 50%;
        margin-top: 20px;
      }
      .detail-label {
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 12px;
        font-weight: 300;
        color: #4a4a4a;
        height: 18px;
        line-height: 18px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
      }
      .detail-value {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #000000;
        // height: 20px;
        line-height: 20px;
        .desc-span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .view-tooltips {
          margin-right: 5px;
        }
        .el-icon-view {
          color: #0486fe;
          font-size: 26px;
          cursor: pointer;
        }
        .el-icon-edit {
          margin-left: 10px;
          height: 18;
          width: 18px;
          cursor: pointer;
        }
      }
      .last-line {
        margin-bottom: 22px;
      }
      .detail-header-cont {
        .detail-header-title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
        }
        .detail-header-tips {
          margin-left: 16px;
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #000;
          opacity: 0.7;
          i {
            opacity: inherit;
          }
        }
      }
      .jump-to-product {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0486fe;
        cursor: pointer;
        i {
          margin-left: 5px;
        }
      }
      .config-row {
        width: 100%;
        margin-top: 0;
      }
    }
    .product-info {
      margin: 40px 0;
      .product-detail-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .detail-header-title {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #252d3d;
        }
        .detail-header-tips {
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #000;
          opacity: 0.7;
          margin-left: 5px;
        }
        .look-detail {
          font-weight: 400;
          font-size: 14px;
          color: #2a61ff;
          cursor: pointer;
        }
        .right-part {
          font-weight: 400;
          font-size: 14px;
          color: #2a61ff;
        }
      }
      .detail-cont {
        margin-bottom: 0;
        .last-line {
          margin-bottom: 0;
        }
      }
    }
    .download-cont {
      margin: 10px 0 22px 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0486fe;
      cursor: pointer;
    }
  }
  .special-card {
    background: #fff;
    box-shadow: 0px 0px 4px 0px #00000016;
  }
  .button-container {
    margin: 40px 0;
  }
  /deep/ .el-switch__label.is-active {
    color: #67c23a;
  }
  #container {
    display: flex;
    #newCharts {
      margin: 0 auto;
      width: 500px;
      height: 400px;
    }
    .chart-table {
      width: 350px;
      height: 380px;
    }
  }
  ::v-deep .el-table .warning-row {
    background: #ff0000;
    color: #fff;
  }
}
</style>