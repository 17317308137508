var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shadow-image-preview" },
    [
      _c("viewer", { attrs: { images: [_vm.image] } }, [
        _c("img", {
          staticStyle: { height: "48px", width: "48px", cursor: "pointer" },
          attrs: { title: _vm.image, src: _vm.imageRender(_vm.image), alt: "" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }