<template>
  <div>
    <el-form
      label-position="top"
      ref="modbusForm"
      :rules="modbusRule"
      :model="modbusForm"
    >
      <!-- 上报方式 -->
      <!-- <el-row>
        <el-col>
          <el-form-item :label="$t('vlink.tsl.trigger')" prop="trigger">
            <el-radio-group :disabled="!editable" v-model="modbusForm.trigger">
              <el-radio border :label="1">{{
                $t("vlink.tsl.timingAppear")
              }}</el-radio>
              <el-radio border :label="2">{{
                $t("vlink.tsl.changeAppear")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row :gutter="48">
        <!-- 上报周期 -->
        <!-- <el-col v-if="modbusForm.trigger == 1" :span="12">
          <el-form-item :label="$t('vlink.tsl.reportCycle')" prop="reportCycle">
            <el-input
              :disabled="!editable"
              :placeholder="$t('commons.pleaseInput')"
              v-model="modbusForm.reportCycle"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="modbusForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.device.slaveId')" prop="slaveId">
            <el-input
              v-model="modbusForm.slaveId"
              maxlength="20"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="扩展配置" prop="customize">
            <ace-editor
              key="customizeJson"
              v-model="modbusForm.customize"
              @init="editorInit"
              lang="json"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="320"
            ></ace-editor>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row v-if="editable">
                <el-col :span="3">
                    <el-input v-model="startValue" placeholder="起始值"></el-input>
                </el-col>
                <el-col :span="3" :offset="1">
                    <el-input v-model="count" placeholder="个数"></el-input>
                </el-col>
                <el-col :span="3" :offset="1">   
                    <el-button type="primary" @click="generateScript">生成脚本</el-button>
                </el-col>
            </el-row> -->

      <!-- <el-row v-if="editable" style="margin-top: 10px">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveDevice">{{
            $t("commons.save")
          }}</el-button>
          <el-button type="success" @click="formatJson">{{
            $t("vlink.commons.formatJson")
          }}</el-button>
          <el-button @click="cancelEditDevice(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  components: { AceEditor },
  props: {
    device: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    device: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.initDevice();
        if (val.daqIntvl && !this.modbusForm.daqIntvl) {
          this.modbusForm.daqIntvl = val.daqIntvl;
        }
      },
    },
  },
  data() {
    return {
      modbusForm: {
        channelId: null,
        channelName: null,
        slaveId: 1,
        trigger: 1,
        reportCycle: null,
        customize: null,
        daqIntvl: null,
      },
      modbusRule: {
        slaveId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        trigger: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        daqIntvl: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              // 可以为空
              if (!value) {
                callback();
                return;
              }
              var re = /^[0-9]+$/;
              if (!re.test(value) || value < 500) {
                callback("请输入大于500的正整数");
                return;
              }
              callback();
            },
          },
        ],
      },
      startValue: null,
      count: null,
    };
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      // thingEditor.setReadOnly(true);
    },
    initDevice() {
      if (this.device) {
        this.modbusForm.channelId = this.device.channelId;
        this.modbusForm.channelName = this.device.channelName;
        if (this.device.extendConfig) {
          let customize = JSON.parse(this.device.extendConfig);
          this.modbusForm.slaveId = customize.slaveId;
          this.modbusForm.daqIntvl = customize.daqIntvl;
          // if (customize.trigger) {
          //   this.modbusForm.trigger = customize.trigger;
          //   this.modbusForm.reportCycle = customize.reportCycle;
          // } else {
          //   this.modbusForm.trigger = 1;
          //   this.modbusForm.reportCycle = 5000;
          // }
          this.modbusForm.customize = customize.customize;
        }
      }
    },
    formatJson() {
      if (
        this.modbusForm.customize &&
        this.modbusForm.customize.trim() !== "" &&
        this.checkJsonFormat()
      ) {
        this.modbusForm.customize = JSON.stringify(
          JSON.parse(this.modbusForm.customize.trim()),
          null,
          "\t"
        );
      }
    },
    checkJsonFormat() {
      try {
        if (typeof JSON.parse(this.modbusForm.customize) == "object") {
          return true;
        }
      } catch (e) {
        console.log(`extendConfig格式异常，原因 => ${e}`);
      }
      this.$message({
        message: this.$t("vlink.tsl.tips5"),
        type: "warning",
      });
      return false;
    },
    saveDevice() {
      this.$refs.modbusForm.validate((v) => {
        if (v && this.checkJsonFormat()) {
          let param = this.buildParam();
          // 回写数据
          this.cancelEditDevice(param);
        }
      });
    },
    buildParam() {
      let param = {
        channelId: this.modbusForm.channelId,
        channelName: this.modbusForm.channelName,
        extendConfig: null,
      };
      param.extendConfig = JSON.stringify({
        slaveId: this.modbusForm.slaveId,
        // trigger: this.modbusForm.trigger,
        // reportCycle: this.modbusForm.reportCycle,
        daqIntvl: this.modbusForm.daqIntvl,
        customize: this.modbusForm.customize,
      });
      return param;
    },
    cancelEditDevice(v) {
      this.$emit("cancelEditDevice", v);
    },
    //生成脚本
    generateScript() {
      if (!this.modbusForm.customize && !this.startValue && !this.count) {
        return;
      }
      let obj = JSON.parse(this.modbusForm.customize);
      let count = this.count;
      let startValue = this.startValue;
      for (let moduleKey in obj) {
        let objArr = Object.keys(obj[moduleKey]);
        for (let index = 0; index < objArr.length; index++) {
          if (index >= count) {
            obj[moduleKey][objArr[index]] = -1;
          } else {
            obj[moduleKey][objArr[index]] =
              parseInt(startValue) + parseInt(index);
          }
        }
      }
      this.modbusForm.customize = JSON.stringify(obj);
      this.formatJson();
    },
  },
};
</script>
