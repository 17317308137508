var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { align: "right" } },
      [
        _c(
          "el-button",
          {
            staticStyle: { "margin-right": "10px" },
            attrs: { type: "text", icon: "el-icon-refresh" },
            on: {
              click: function ($event) {
                return _vm.refreshData()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("commons.refresh")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("finalTable", {
          ref: "finalTableRef",
          attrs: { datas: _vm.dataset },
          on: { tableEventHandler: _vm.tableEventHandler },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }