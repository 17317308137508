<template>
  <div class="event-history-component">
    <div class="filter-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 显示消息内容对话框 -->
    <el-dialog :visible.sync="dialogVisible">
      <template slot="title">
        {{ $t("vlink.tsl.responseData") }}
      </template>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item
          v-for="(item, index) in responseContent"
          :key="index"
        >
          <template slot="label">
            {{ findLabelByKey(index) }}
          </template>
          {{ responseContent[index] }}
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer">
        <el-button type="primary" @click="closeResponseContent">{{
          $t("commons.close")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  findEventHistoryPage,
  findEventHistoryPageDoris,
} from "@/api/ruge/vlink/device/device";

import AceEditor from "vue2-ace-editor";
import finalTable from "@/components/FinalTable";
import moment from "moment";
import { findTsl } from "@/api/ruge/vlink/product/product";

export default {
  name: "EventHistory",
  components: {
    AceEditor,
    finalTable,
  },
  data() {
    return {
      tslSource: {},
      editRow: {},
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "requestId",
            label: this.$t("vlink.commons.requestId"),
            width: "",
          },
          {
            prop: "moduleName",
            label: this.$t("vlink.tsl.moduleName"),
            width: "",
          },
          {
            prop: "eventName",
            label: this.$t("vlink.tsl.eventName"),
            width: "180",
          },
          {
            prop: "eventDescription",
            label: this.$t("vlink.tsl.description"),
            width: "180",
          },
          {
            prop: "eventType",
            label: this.$t("vlink.tsl.eventType"),
            width: "220",
          },
          {
            prop: "reportTime",
            label: this.$t("vlink.commons.reportTime"),
            width: "220",
          },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          requestId: {
            type: "input",
            label: "请求ID",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入请求ID",
            prefixIcon: "el-icon-search",
          },
          moduleName: {
            type: "input",
            label: "模块名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入模块名称",
            prefixIcon: "el-icon-search",
          },
          eventName: {
            type: "input",
            label: "事件名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入事件名称",
            prefixIcon: "el-icon-search",
          },
          eventDescription: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          eventType: {
            type: "selector",
            label: "事件类型",
            value: "",
            placeholder: "请选择节点类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "信息",
                value: "info",
              },
              {
                label: "告警",
                value: "alarm",
              },
              {
                label: "故障",
                value: "fault",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          reportTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          requestId: {
            inline: true,
            value: "",
          },
          moduleName: {
            inline: true,
            value: "",
          },
          eventName: {
            inline: true,
            value: "",
          },
          eventDescription: {
            inline: true,
            value: "",
          },
          eventType: {
            inline: true,
            value: "",
          },
          reportTime: {
            type: "dateRange",
            label: "上报时间",
            // value: [new Date(moment().subtract(7, "days")).getTime(), null],
            value: [null, null],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      // 对话框是否可见，默认不可见
      dialogVisible: false,
      // 要显示的响应内容
      responseContent: undefined,
      openDelay: 1000,
      event: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          productKey: null,
          deviceName: null,
          requestId: null,
          moduleName: null,
          eventName: null,
          type: null,
          // startTime: new Date(moment().subtract(7, "days")).getTime(),
          startTime: null,
          eventType: null,
          endTime: null,
        },
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productKey() {
      return this.currentRow.productKey;
    },
  },
  watch: {
    productKey: {
      immediate: true,
      handler: function () {
        this.findEventHistoryPage();
        this.findTsl();
      },
    },
    event: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  methods: {
    findTsl() {
      findTsl({ productKey: this.productKey }).then((res) => {
        console.log("xxx-tsl:", res);
        this.tslSource = res;
      });
    },
    findLabelByKey(key) {
      const { moduleName, eventName } = this.editRow || {};
      if (!moduleName) return;
      let result = key;
      const { modules } = this.tslSource;
      if (modules && modules.length) {
        modules.forEach((item) => {
          if (item.moduleName === moduleName) {
            console.log("xxx-1");
            const { events } = item || {};
            if (events && events.length) {
              events.forEach((eventItem) => {
                if (eventItem.eventName === eventName) {
                  const { outputData } = eventItem || {};
                  if (outputData && outputData.length) {
                    outputData.forEach((output) => {
                      if (output.paramName == key) {
                        result = output.description;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
      return result;
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findEventHistoryPage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.reportTime && datas.params.reportTime.length > 0) {
          this.event.listQuery.startTime = new Date(
            datas.params.reportTime[0]
          ).getTime();
          this.event.listQuery.endTime = new Date(
            datas.params.reportTime[1]
          ).getTime();
          delete datas.params.reportTime;
        }
        this.event.listQuery = { ...this.event.listQuery, ...datas.params };
        this.findEventHistoryPage();
      } else if (datas.type === "paginationChange") {
        this.event.listQuery.current = datas.params.current.page;
        this.event.listQuery.rowCount = datas.params.current.limit;
        this.getEventHistoryPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.showResponseContent(datas.row);
            break;
        }
      }
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    // 查看消息内容
    showResponseContent(row) {
      this.editRow = row;
      this.dialogVisible = true;
      let obj = JSON.parse(row.outputData.trim());

      // 格式化json
      this.responseContent = obj;
    },
    //关闭模态框
    closeResponseContent() {
      this.dialogVisible = false;
    },
    findEventHistoryPage() {
      this.event.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.event.listQuery.productKey = this.currentRow.productKey;
      this.event.listQuery.deviceName = this.currentRow.deviceName;
      this.getEventHistoryPage();
    },
    getEventHistoryPage() {
      let param = this.event.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.event.listLoading = true;
      findEventHistoryPageDoris(param).then((response) => {
        this.dataset.pageVO.total = response.total;
        response.rows.forEach((v) => {
          v.eventType = this.$t("vlink.commons." + v.eventType);
        });
        this.dataset.tableData = response.rows;
        this.event.listLoading = false;
      });
    },
    startPicker(time) {
      this.event.listQuery.startTime = time.getTime();
    },
    endPicker(time) {
      this.event.listQuery.endTime = time.getTime();
    },
  },
};
</script>
<style scoped lang="less">
.event-history-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .detail-container {
    border: 1px solid #ccc;
    border-bottom: none;
    border-right: none;
  }
  .single-item {
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    .label {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #252d3d;
      display: inline-block;
      min-width: 120px;
      background: #f0f4fa;
      text-align: center;
    }
    .value {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #5d687c;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 8px;
    }
  }
}
</style>