<template>
  <div class="protocol-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createChannel">添加通道</el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 添加通道对话框 -->
    <el-dialog
      :visible.sync="channel.addDialogVisible"
      :before-close="cancelChannelSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ channel.title }}
      </template>
      <el-form
        label-position="top"
        :model="channel.form"
        ref="channelForm"
        :rules="channel.formRules"
      >
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item
              :label="$t('iot.protocol.channelName')"
              prop="channelName"
            >
              <el-input
                :readonly="channel.edit"
                v-model="channel.form.channelName"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.name')" prop="protocolName">
              <el-select
                :style="{ width: '100%' }"
                v-model="channel.form.protocolName"
                @change="channelChange"
                placeholder="请选择"
                filterable
              >
                <el-option-group
                  v-for="group in protocolList"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>

              <!-- <el-select
                :style="{ width: '100%' }"
                v-model="channel.form.protocolName"
              >
                <el-option
                  v-for="protocol in protocolList"
                  :key="protocol.value"
                  :label="protocol.name"
                  :value="protocol.value"
                >
                  <span>{{ protocol.name }}</span>
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <channel-info
        ref="channelInfoRef"
        v-if="channel.form.protocolName"
        :editable="true"
        :channel="channel.form"
        @cancelEditChannel="closeChannelConfig"
      ></channel-info>
      <div slot="footer">
        <el-button @click="cancelChannelSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleChannelSaveHandler">{{
          $t("commons.add")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import ChannelInfo from "@/views/ruge/vlink/device/protocol/channelInfo";
import { findApp} from "@/api/ruge/vlink/app/app";
import {
  findChannelPage,
  createChannel,
  updateChannel,
  deleteChannelById,
  findProtocolPage,
} from "@/api/ruge/vlink/device/device";
export default {
  components: {
    finalTable,
    ChannelInfo,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "channelName",
            label: this.$t("vlink.device.channelName"),
            width: "",
          },
          {
            prop: "protocolName",
            label: this.$t("vlink.device.protocol"),
            width: "",
          },
          {
            prop: "lastUpdateDate",
            label: this.$t("commons.lastUpdateDate"),
            width: "",
          },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          channelName: {
            type: "input",
            label: "通道名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入通道名称",
            prefixIcon: "el-icon-search",
          },
          protocolName: {
            type: "selector",
            label: "协议",
            value: "",
            placeholder: "请选择协议",
            actionType: "goSearch",
            filterable: true,
            optionList: [
              // { label: "Modbus", value: "modbus" },
              // { label: "OPCUA", value: "opcua" },
              // { label: "BACnet", value: "bacnet" },
              // { label: "SNMP", value: "snmp" },
              // { label: "HW-NVR800", value: "hwnvr800" },
              // { label: "DH6200-V3", value: "dh6200v3" },
              // { label: "AKE-C03P-15", value: "akec03p15" },
              // { label: "GB28181-SRS", value: "gb28181srs" },
              // { label: "YIMA-SDK", value: "yimasdk" },
              // { label: "WANWU", value: "wanwu" },
              // { label: "TCP-SERVER", value: "tcpserver" },
              // { label: "LoRa-MQTT", value: "loramqtt" },
              // { label: "NBIoT-MQTT", value: "nbiotmqtt" },
              // { label: "ZigbeeMQTT", value: "zigbeemqtt" },
              // { label: "锦绣一期变配电", value: "jx01bpd" },
              // { label: "北大青鸟消防", value: "bdqnxf" },
              // { label: "锦绣三期蒂森电梯", value: "jx03dt" },
              // { label: "泛海三江", value: "fanhai-fire" },
              // { label: "富士停车场", value: "fushi-car" },
              // { label: "FLINK", value: "flink" },
            ],
          },
        },
        // 表格内容配置
        detailConfig: {
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          channelName: {
            inline: true,
            value: "",
          },
          protocolName: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      appVO: {
        appType:"协议驱动",
        current: 1,
        rowCount: 50,
      },
      openDelay: 1000,
      protocolList: [],
      // {
      //   label: "官方",
      //       options: [
      //   { name: "Modbus", value: "modbus" },
      //   { name: "OPCUA", value: "opcua" },
      //   { name: "BACnet", value: "bacnet" },
      //   { name: "SNMP", value: "snmp" },
      // ],
      // },
      // {
      //   label: "私有",
      //       options: [
      //   { name: "HW-NVR800", value: "hwnvr800" },
      //   { name: "DH6200-V3", value: "dh6200v3" },
      //   { name: "AKE-C03P-15", value: "akec03p15" },
      //   { name: "GB28181-SRS", value: "gb28181srs" },
      //   { name: "YIMA-SDK", value: "yimasdk" },
      //   { name: "WANWU", value: "wanwu" },
      //   { name: "TCP-SERVER", value: "tcpserver" },
      //   { name: "LoRaMQTT", value: "loramqtt" },
      //   { name: "NBIoT-MQTT", value: "nbiotmqtt" },
      //   { name: "ZigbeeMQTT", value: "zigbeemqtt" },
      //   { name: "锦绣一期变配电", value: "jx01bpd" },
      //   { name: "北大青鸟消防", value: "bdqnxf" },
      //   { name: "锦绣三期蒂森电梯", value: "jx03dt" },
      //   { name: "泛海三江", value: "fanhai-fire" },
      //   { name: "富士停车场", value: "fushi-car" },
      //   { name: "FLINK", value: "flink"},
      // ],
      // },
      channel: {
        edit: false,
        channelConfigDialogVisible: false,
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          gatewayDeviceId: null,
          protocolName: null,
          channelName: null,
        },
        multipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        form: {},
        formRules: {
          channelName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          protocolName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          extendConfig: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceId() {
      return this.currentRow.deviceId;
    },
  },
  created() {
    this.getProtocolPage();
  },
  watch: {
    deviceId: {
      immediate: true,
      handler: function () {
        this.initChannelForm();
        this.findChannelPage();
      },
    },
    channel: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findChannelPage();
    },
    channelChange() {
      console.log("channel.form", this.channel.form);
      this.channel.form.extendConfig = null;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (
          datas.params.lastUpdateDate &&
          datas.params.lastUpdateDate.length > 0
        ) {
          this.channel.listQuery.startTime = new Date(
            datas.params.lastUpdateDate[0]
          ).getTime();
          this.channel.listQuery.endTime = new Date(
            datas.params.lastUpdateDate[1]
          ).getTime();
          delete datas.params.lastUpdateDate;
        } else {
          this.channel.listQuery.endTime = "";
          this.channel.listQuery.startTime = "";
        }
        this.channel.listQuery = { ...this.channel.listQuery, ...datas.params };
        this.findChannelPage();
      } else if (datas.type === "paginationChange") {
        this.channel.listQuery.current = datas.params.current.page;
        this.channel.listQuery.rowCount = datas.params.current.limit;
        this.getChannelPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editChannel(datas.row);
            break;
          case "delete":
            this.deleteChannel(datas.row.channelId);
            break;
        }
      }
    },
    initChannelForm() {
      this.channel.form = {
        gatewayDeviceId: this.currentRow.deviceId,
        channelId: null,
        channelName: null,
        protocolName: null,
        extendConfig: null,
      };
    },
    findChannelPage() {
      this.channel.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.channel.listQuery.gatewayDeviceId = this.currentRow.deviceId;
      this.getChannelPage();
    },
    getChannelPage() {
      let param = this.channel.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.channel.listLoading = true;
      findChannelPage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows;
        })
        .finally(() => {
          this.channel.listLoading = false;
        });
    },
    createChannel() {
      this.initChannelForm();
      this.channel.title = this.$t("vlink.device.addChannel");
      this.channel.edit = false;
      this.channel.addDialogVisible = true;

      this.getProtocolPage();
    },
    editChannel(channelObj) {
      this.channel.form = Object.assign({}, channelObj);
      this.channel.title = this.$t("vlink.device.editChannel");
      this.channel.edit = true;
      this.channel.addDialogVisible = true;
    },
    // 取消保存
    cancelChannelSave() {
      this.channel.addDialogVisible = false;
    },
    handleChannelSaveHandler() {
      // 调用子组件的formValid

      let protocolName = this.channel.form.protocolName;
      if (
        protocolName !== "modbus" &&
        protocolName !== "opcua" &&
        protocolName !== "bacnet"
      ) {
        protocolName = "customize";
      }
      this.$refs.channelForm.validate((v) => {
        if (v) {
          this.$refs.channelInfoRef.checkValidAndGetConfig(protocolName);
        }
      });
    },
    handleChannelSave() {
      this.$refs.channelForm.validate((v) => {
        if (v) {
          if (this.channel.form.channelId) {
            updateChannel(this.channel.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelChannelSave();
                this.findChannelPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            createChannel(this.channel.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelChannelSave();
                this.findChannelPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    deleteChannel(channelId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteChannelById({ channelId: channelId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findChannelPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    openChannelConfig() {
      this.channel.channelConfigDialogVisible = true;
    },
    closeChannelConfig(v) {
      if (v && typeof v != "function") {
        // this.channel.form.channelName = v.channelName;
        this.channel.form.extendConfig = v.extendConfig;
      }
      this.handleChannelSave();
    },
    getProtocolPage: function () {
      findApp(this.appVO)
        .then((response) => {
          let resultList = response.rows;
          //
          let array1 = new Array();
          let authority1 = {};
          let authority2 = {};
          let options1 = new Array();
          let options2 = new Array();
          authority1.label = "官方";
          authority2.label = "私有";
          resultList.forEach((item) => {
            if (item.owner == "authority") {
              let optCount = {};
              if (item.description != null) {
                optCount.name = item.description;
              } else {
                optCount.name = item.appDriverName.toUpperCase();
              }
              optCount.value = item.appDriverName;
              options1.push(optCount);
            } else {
              let optCount = {};
              if (item.description != null) {
                optCount.name = item.description;
              } else {
                optCount.name = item.appDriverName.toUpperCase();
              }
              optCount.value = item.appDriverName;
              options2.push(optCount);
            }
          });
          authority1.options = options1;
          authority2.options = options2;
          array1.push(authority1);
          array1.push(authority2);
          this.protocolList = array1;

          let authority3 = {};
          let options3 = new Array();
          resultList.forEach((item) => {
            let optCount = {};
            if (item.description != null) {
              optCount.label = item.description;
            } else {
              optCount.label = item.appDriverName.toUpperCase();
            }
            optCount.value = item.appDriverName;
            options3.push(optCount);
          });
          authority3.options = options3;
          this.dataset.searchLineConfig.protocolName.optionList = options3;
        })
        .catch((error) => {
          console.log("获取协议列表失败：" + error);
          this.loadingFlag = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.protocol-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>
<style scoped>
::v-deep .el-select-group__title {
  color: #0486fe;
}
</style>