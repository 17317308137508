<template>
  <div class="device-shadow-component">
    <div class="filter-line" v-if="!tsl.modules || tsl.modules.length === 0">
      <el-button
        size="small"
        @click="init"
        type="primary"
        icon="el-icon-refresh"
        >{{ $t("commons.refresh") }}
      </el-button>
    </div>
    <el-tabs
      v-loading="loading"
      v-model="activeTabName"
      tab-position="top"
      type="card"
      @tab-click="tabsChange"
    >
      <el-tab-pane
        v-for="item in tsl.modules"
        :label="item.moduleNameAlias"
        :key="item.moduleName"
        :name="item.moduleName"
      >
        <div class="description-line">
          <span>
            {{ description }}
          </span>
        </div>
        <div class="operation-line">
          <span class="model-change">
            <span
              :class="['single-tab', item.active && 'single-tab-active']"
              v-for="item in tabList"
              :key="item.key"
              @click="modelChange(item)"
            >
              {{ item.label }}
            </span>
          </span>
          <span>
            <el-select
              style="width: 110px; margin-right: 10px"
              v-model="freshStep"
              placeholder="刷新频率"
            >
              <el-option
                v-for="item in freshOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <r-button @click="init" plain>
              <i class="el-icon-refresh"></i>
              刷新
            </r-button>
          </span>
        </div>
        <div
          class="table-container"
          v-if="tabList.filter((item) => item.active)[0].key === 'list'"
        >
          <finalTable
            ref="finalTableRef"
            :datas="dataset"
            @tableEventHandler="tableEventHandler"
          >
            <template v-slot:desiredSlot="{ datas }">
              <span>
                {{ datas.desired ? datas.desired + datas.unit : "-" }}
              </span>
              <i
                class="el-icon-edit detail-line-icon"
                v-if="datas.accessMode !== 'r'"
                title="设置期望值"
                @click="setDesired(activeTabName, datas.propertyName)"
              ></i>
              <i
                class="el-icon-delete detail-line-icon"
                v-if="datas.desired"
                title="清除期望值"
                @click="clearDesired(activeTabName, datas.propertyName)"
              ></i>
            </template>
            <template v-slot:reportedSlot="{ datas }">
              <div v-if="datas.dataType === 'img' && datas.reported">
                <ShadowImagePreview :image="datas.reported" />
              </div>
              <div v-else>
                <span
                  style="color: #3b3c3f; font-size: 16px; font-weight: bold"
                >
                  {{ datas.reported }}
                </span>
                <span style="font-size: 12px">
                  {{ datas.unit }}
                </span>
              </div>
            </template>
          </finalTable>
        </div>
        <div class="card-container" v-else>
          <el-row :gutter="30">
            <el-col
              :span="8"
              v-for="item in dataset.tableData"
              :key="item.propertyName"
            >
              <div class="single-card">
                <div class="desc-line">
                  <span class="desc-label" :title="item.description">
                    {{ item.description }}
                  </span>
                  <span
                    class="report-history"
                    @click="
                      viewHistoryData(
                        activeTabName,
                        activeModuleDesciption,
                        item.propertyName,
                        item.description,
                        item.dataType
                      )
                    "
                  >
                    上报历史
                  </span>
                </div>
                <div class="bottom-part">
                  <div class="bottom-line">
                    <span class="label-span"> 属性名称: </span>
                    <span class="value-span">
                      {{ item.propertyName }}
                    </span>
                  </div>
                  <div class="bottom-line">
                    <span class="label-span"> 访问权限: </span>
                    <span class="value-span">
                      {{
                        dataset.detailConfig.accessMode.emuList[item.accessMode]
                      }}
                    </span>
                  </div>
                  <div class="bottom-line">
                    <span class="label-span"> 上报时间: </span>
                    <span class="value-span">
                      {{ item.reportTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
                    </span>
                  </div>
                  <div class="bottom-line">
                    <span class="label-span"> 上报值: </span>
                    <span>
                      <div v-if="item.dataType === 'img' && item.reported">
                        <ShadowImagePreview :image="item.reported" />
                      </div>
                      <div v-else>
                        <span class="value-span">
                          {{ item.reported || "-" }}
                        </span>
                        <span style="font-size: 12px">
                          {{ item.unit }}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!--查看历史数据抽屉-->
    <el-dialog width="1100px" top="50px" :visible.sync="historyDrawer">
      <template slot="title">
        <span>{{
          $t("vlink.device.viewHistory") + " - " + currentModuleName
        }}</span>
      </template>
      <property-history
        v-if="historyDrawer"
        :productKey="currentRow.productKey"
        :deviceName="currentRow.deviceName"
        :moduleName="currentModuleName"
        :moduleDescription="currentModuleDescription"
        :propertyName="currentPropertyName"
        :description="currentDescription"
        :currentDataType="currentDataType"
      ></property-history>
    </el-dialog>

    <!--查看设置历史数据抽屉-->
    <el-dialog width="1100px" top="50px" :visible.sync="settingHistoryDrawer">
      <template slot="title">
        <span>{{ "属性设置历史" + " - " + currentPropertyName }}</span>
      </template>
      <settingHistory
        :currentRow="currentRow"
        :propertyName="currentPropertyName"
        :moduleName="currentModuleName"
        v-if="settingHistoryDrawer"
      />
    </el-dialog>

    <!-- 设置期望值对话框 -->
    <el-dialog
      :visible.sync="setDesiredVisible"
      :before-close="closeSetDesired"
      width="30%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ $t("vlink.device.setDesired") + " - " + currentPropertyName }}
      </template>
      <el-form>
        <el-form-item :label="$t('vlink.tsl.desired')">
          <el-input
            v-model="setDesiredVal"
            maxlength="1000"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closeSetDesired">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleSetDesired">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 设置属性值对话框 -->
    <el-dialog
      :visible.sync="setPropertyVisible"
      :before-close="closeSetProperty"
      width="728"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ $t("vlink.device.setProperty") + " - " + currentPropertyName }}
      </template>
      <el-form label-position="top" v-loading="setPropertyLoading">
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.propertyValue')">
              <el-input
                v-model="setPropertyVal"
                maxlength="1000"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.tsl.syncCall')">
              <el-switch v-model="callType" active-color="#13ce66"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="callType">
          <el-form-item :label="$t('vlink.tsl.responseData')">
            <ace-editor
              v-model="responseContent"
              @init="editorInit"
              lang="json"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="300"
            ></ace-editor>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="closeSetProperty">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleSetProperty">{{
          $t("vlink.commons.call")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findTsl } from "@/api/ruge/vlink/product/product";

import { findDeviceShadow } from "@/api/ruge/vlink/device/device";

import {
  clearShadowDesired,
  setShadowDesired,
  setDeviceProperties,
} from "@/api/ruge/vlink/device/control";

// import PropertyHistory from "@/views/ruge/vlink/device/propertyHistory";
import PropertyHistory from "@/views/ruge/vlink/device/propertyHistoryComponent";
import message from "@/store/modules/message";
import finalTable from "@/components/FinalTable";
import settingHistory from "./propertySettingHistory.vue";
import AceEditor from "vue2-ace-editor";
import ShadowImagePreview from "./shadowImagePreview.vue";
export default {
  components: {
    PropertyHistory,
    finalTable,
    settingHistory,
    AceEditor,
    ShadowImagePreview,
  },
  data() {
    return {
      tabList: [
        {
          label: "列表",
          key: "list",
          active: true,
        },
        {
          label: "卡片",
          key: "card",
          active: false,
        },
      ],
      imageMap: {},
      freshInterval: null,
      freshStep: 5,
      freshOptions: [
        {
          value: "",
          label: "手动刷新",
        },
        {
          value: 5,
          label: "5秒",
        },
        {
          value: 10,
          label: "10秒",
        },
        {
          value: 30,
          label: "30秒",
        },
      ],
      dataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: false,
        },
        selection: {
          need: false,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "index",
            label: "序号",
            width: "",
          },
          {
            prop: "propertyName",
            label: this.$t("vlink.tsl.propertyName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "accessMode",
            label: this.$t("vlink.tsl.accessMode"),
            width: "",
          },
          {
            prop: "reported",
            label: this.$t("vlink.tsl.reported"),
            width: "",
          },
          {
            prop: "reportTime",
            label: this.$t("vlink.commons.reportTime"),
            width: "",
          },
          {
            prop: "desired",
            label: this.$t("vlink.tsl.desired"),
            width: "",
          },
          {
            prop: "desiredTime",
            label: this.$t("vlink.commons.desiredTime"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "215",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          propertyName: {
            type: "input",
            label: "属性名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入属性名称",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          accessMode: {
            type: "selector",
            label: "访问权限",
            value: "",
            placeholder: "请选择访问权限",
            actionType: "goSearch",
            optionList: [
              {
                label: "只读",
                value: "r",
              },
              {
                label: "只写",
                value: "w",
              },
              {
                label: "读写",
                value: "rw",
              },
            ],
          },
          reported: {
            type: "input",
            label: "上报值",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入上报值",
            prefixIcon: "el-icon-search",
          },
          desired: {
            type: "input",
            label: "期望值",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入期望值",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          accessMode: {
            type: "enumerationColumn",
            emuList: {
              r: "只读",
              w: "只写",
              rw: "读写",
            },
          },
          reported: {
            // type: "joinList",
            // joinSymbol: "",
            // joinList: ["reported", "unit"],
            type: "slot",
            slotName: "reportedSlot",
          },
          desired: {
            // type: "joinList",
            // joinSymbol: "",
            // joinList: ["desired", "unit"],
            type: "slot",
            slotName: "desiredSlot",
          },
          reportTime: {
            type: "dateFormat",
          },
          desiredTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "上报历史",
              },
              {
                actionType: "iconClick",
                eventName: "settingView",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-tickets",
                tooltips: "属性历史",
                disableProp: "accessMode",
                disableValue: "r",
              },
              {
                actionType: "iconClick",
                eventName: "setValue",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit-outline",
                tooltips: "属性设置",
                disableProp: "accessMode",
                disableValue: "r",
              },
              // {
              //   actionType: "iconClick",
              //   eventName: "edit",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-edit",
              //   tooltips: "设置期望值",
              //   disableProp: "accessMode",
              //   disableValue: "r",
              // },
              // {
              //   actionType: "iconClick",
              //   eventName: "clear",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-refresh",
              //   tooltips: "清除期望值",
              //   disableProp: "desiredTime",
              //   disableValue: null,
              // },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          propertyName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          accessMode: {
            inline: true,
            value: "",
          },
          reported: {
            inline: true,
            value: "",
          },
          desired: {
            inline: true,
            value: "",
          },
          reportTime: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
          desiredTime: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      description: "",
      openDelay: 1000,
      loading: true,
      activeTabName: "",
      activeModuleDesciption: "",
      tsl: {},
      shadow: {},
      historyDrawer: false,
      settingHistoryDrawer: false,
      currentModuleName: null,
      currentPropertyName: null,
      currentDataType: null,
      currentDescription: null,
      setDesiredVisible: false,
      setDesiredVal: null,
      setPropertyVal: null,
      callType: false,
      setPropertyVisible: false,
      responseContent: "",
      setPropertyLoading: false,
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  form: {
    productKey: "",
    deviceName: "",
    parentProductKey: "",
    parentDeviceName: "",
    modules: {
      moduleName: "",
      properties: {},
    },
  },
  watch: {
    currentRow: {
      deep: true,
      immediate: true,
      handler: function () {
        this.init();
      },
    },
    freshStep: {
      handler(step) {
        this.setFreshInterval(step);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.freshInterval && window.clearInterval(this.freshInterval);
  },
  methods: {
    modelChange({ key }) {
      this.tabList.forEach((item) => {
        item.active = item.key === key;
      });
    },
    setFreshInterval(step) {
      if (this.freshInterval) window.clearInterval(this.freshInterval);
      if (step === "") {
        return;
      }
      this.freshInterval = window.setInterval(() => {
        if (this.historyDrawer) return;
        this.init();
      }, step * 1000);
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    tabsChange(tab, event) {
      const curTabName = this.activeTabName;
      this.tsl.modules.forEach((item) => {
        item.moduleNameAlias = `${item.moduleName} (${item.properties.length})`;
        if (item.moduleName === curTabName) {
          this.description = item.description;
          this.activeModuleDesciption = item.description;
          this.dataset.tableData = item.properties || [];
          item.properties.forEach((single, singleIndex) => {
            single.index = singleIndex + 1;
            if (
              single.dataType === "img" &&
              single.reported &&
              !this.imageMap[single.propertyName]
            ) {
              this.imageMap[single.propertyName] = single.reported;
            }
          });
          this.$forceUpdate();
        }
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // this.filterObj = { ...this.filterObj, ...datas.params };
        // this.findProductPage();
      } else if (datas.type === "paginationChange") {
        // this.filterObj.current = datas.params.current.page;
        // this.filterObj.rowCount = datas.params.current.limit;
        // this.getProductPage();
      } else if (datas.type === "updateSelectionList") {
        // this.selectionList = datas.list;
      } else if (datas.type === "switchEvent") {
        // this.updateDynamicRegistration(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.setDesired(this.activeTabName, datas.row.propertyName);
            break;
          case "view":
            this.viewHistoryData(
              this.activeTabName,
              this.activeModuleDesciption,
              datas.row.propertyName,
              datas.row.description,
              datas.row.dataType
            );
            break;
          case "settingView":
            this.viewSettingHistoryData(
              datas.row.propertyName,
              this.activeTabName
            );
            break;
          case "clear":
            this.clearDesired(this.activeTabName, datas.row.propertyName);
            break;
          case "setValue":
            this.setProperty(this.activeTabName, datas.row.propertyName);
            break;
        }
      }
    },
    // 页面初始化
    init() {
      this.loading = true;
      Promise.all([this.findTsl(), this.findDeviceShadow()])
        .then((datas) => {
          this.tsl = datas[0];
          this.shadow = datas[1];
          // 默认选中第一个模块tab
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            if (
              this.shadow &&
              this.shadow.shadow &&
              this.shadow.shadow.length > 0
            ) {
              this.shadow.shadow.forEach((shadowItem) => {
                let moduleName = shadowItem.moduleName;
                this.tsl.modules.forEach((module) => {
                  // 填充匹配模块的属性值
                  if (
                    module.moduleName == moduleName &&
                    module.properties &&
                    module.properties.length > 0
                  ) {
                    module.properties.forEach((prop) => {
                      // 上报值
                      if (shadowItem.reported) {
                        let reportedObj =
                          shadowItem.reported[prop.propertyName];
                        prop.reported = reportedObj ? reportedObj.value : "";
                        prop.reportTime = reportedObj
                          ? reportedObj.updateTime
                          : "";
                      }
                      // 期望值
                      if (shadowItem.desired) {
                        let desiredObj = shadowItem.desired[prop.propertyName];
                        prop.desired = desiredObj ? desiredObj.value : "";
                        prop.desiredTime = desiredObj
                          ? desiredObj.updateTime
                          : "";
                      }
                    });
                  }
                });
              });
            }
            if (this.activeTabName == 0) {
              this.activeTabName = this.tsl.modules[0].moduleName;
              this.activeModuleDesciption = this.tsl.modules[0].description;
              this.$forceUpdate();
            }
            this.tabsChange();
          }
        })
        .catch((error) => {
          console.log(`操作失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findTsl() {
      return findTsl({ productKey: this.currentRow.productKey });
    },
    findDeviceShadow() {
      return findDeviceShadow({
        productKey: this.currentRow.productKey,
        deviceName: this.currentRow.deviceName,
      });
    },
    // 查看属性历史数据
    viewHistoryData(
      moduleName,
      activeModuleDesciption,
      propertyName,
      description,
      dataType
    ) {
      this.currentModuleName = moduleName;
      this.currentModuleDescription = activeModuleDesciption;
      this.currentPropertyName = propertyName;
      this.currentDescription = description;
      this.currentDataType = dataType;
      this.historyDrawer = true;
    },
    viewSettingHistoryData(propertyName, moduleName) {
      this.currentModuleName = moduleName;
      this.currentPropertyName = propertyName;
      this.settingHistoryDrawer = true;
    },
    // 清除期望值
    clearDesired(moduleName, propertyName) {
      this.$confirm(
        this.$t("vlink.device.clearDesiredConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.activeTabName = moduleName;

          let desired = {};
          desired[propertyName] = {};
          let param = {
            productKey: this.currentRow.productKey,
            deviceName: this.currentRow.deviceName,
            shadow: [
              {
                moduleName: moduleName,
                desired: desired,
              },
            ],
          };
          clearShadowDesired(param).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.operationSuccess"),
              type: "success",
            });
            // 刷新界面显示的值
            this.init();
          });
        })
        .catch((error) => {
          console.log(`未清除，原因 => ${error}`);
        });
    },
    setDesired(moduleName, propertyName) {
      this.activeTabName = moduleName;
      this.currentModuleName = moduleName;
      this.currentPropertyName = propertyName;
      this.setDesiredVisible = true;
    },
    closeSetDesired() {
      this.setDesiredVal = null;
      this.setDesiredVisible = false;
    },
    setProperty(moduleName, propertyName) {
      this.currentModuleName = moduleName;
      this.currentPropertyName = propertyName;
      this.setPropertyVisible = true;
    },
    closeSetProperty() {
      this.setPropertyVal = null;
      this.callType = false;
      this.responseContent = "";
      this.setPropertyVisible = false;
    },
    // 设置期望值
    handleSetDesired() {
      this.loading = true;
      let desired = {};
      desired[this.currentPropertyName] = { value: this.setDesiredVal };
      let param = {
        productKey: this.currentRow.productKey,
        deviceName: this.currentRow.deviceName,
        shadow: [
          {
            moduleName: this.currentModuleName,
            desired: desired,
          },
        ],
      };
      setShadowDesired(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.operationSuccess"),
            type: "success",
          });
          this.closeSetDesired();
          // 刷新界面显示的值
          this.init();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 设置属性值
    handleSetProperty() {
      this.setPropertyLoading = true;
      let properties = {};
      properties = { [this.currentPropertyName]: this.setPropertyVal };
      let param = {
        productKey: this.currentRow.productKey,
        deviceName: this.currentRow.deviceName,
        moduleName: this.currentModuleName,
        propertyName: this.currentPropertyName,
        inputData: this.setPropertyVal,
        callType: this.callType ? "sync" : "async",
      };
      setDeviceProperties(param)
        .then((res) => {
          if (res.code == 200) {
            // 成功提示
            this.$message({
              message: this.$t("message.operationSuccess"),
              type: "success",
            });
            if (this.callType) {
              this.responseContent = res.outputData
                ? JSON.stringify(JSON.parse(res.outputData.trim()), null, "\t")
                : "";
            }
          } else {
            // 失败提示
            this.$message({
              message: this.$t("message.operationFailed") + ":" + res.message,
              type: "error",
            });
          }
          // 刷新界面显示的值
          this.init();
        })
        .finally(() => {
          this.setPropertyLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="less">
.device-shadow-component {
  .filter-line {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 10px;
  }
  .table-container {
    padding: 10px;
  }
  .card-container {
    padding: 10px;
    .single-card {
      height: 216px;
      background: #ffffff;
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #fafafa;
      margin-bottom: 20px;
      padding: 14px;
      .desc-line {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .desc-label {
          font-weight: 600;
          font-size: 20px;
          color: #252d3d;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .report-history {
          font-weight: 600;
          font-size: 14px;
          color: #2a61ff;
          cursor: pointer;
          min-width: 70px;
          text-align: right;
        }
      }
      .bottom-part {
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .bottom-line {
          display: flex;
          .label-span {
            color: #5d687c;
            font-weight: 400;
            font-size: 14px;
            margin-right: 3px;
          }
          .value-span {
            color: #252d3d;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }
  .description-line {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
    padding: 10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .operation-line {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .model-change {
      height: 40px;
      border-radius: 10px;
      border: 1px solid #e9eff4;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      .single-tab {
        display: inline-block;
        width: 52px;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        font-size: 14px;
        color: #5d687c;
        cursor: pointer;
        text-align: center;
      }
      .single-tab-active {
        background: #e9eff4;
        border-radius: 8px;
        color: #252d3d;
      }
    }
    .el-icon-refresh {
      margin-right: 5px;
    }
  }
  ::v-deep .el-tabs__item {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  // ::v-deep .el-tabs__header {
  //   padding: 10px;
  // }
  .detail-line-icon {
    font-size: 14px;
    cursor: pointer;
    color: #c0c4cc;
    margin-left: 5px;
  }
}
</style>