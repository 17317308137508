var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "opcuaForm",
          attrs: {
            "label-position": "top",
            rules: _vm.opcuaRule,
            model: _vm.opcuaForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.channelAddress"),
                        prop: "channelAddress",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "100",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.opcuaForm.channelAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "channelAddress", $$v)
                          },
                          expression: "opcuaForm.channelAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.securityPolicy"),
                        prop: "securityPolicy",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          attrs: { disabled: !_vm.editable },
                          on: { change: _vm.changeSecurityPolicy },
                          model: {
                            value: _vm.opcuaForm.securityPolicy,
                            callback: function ($$v) {
                              _vm.$set(_vm.opcuaForm, "securityPolicy", $$v)
                            },
                            expression: "opcuaForm.securityPolicy",
                          },
                        },
                        _vm._l(_vm.securityPolicyList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _vm.opcuaForm.securityPolicy !== "none"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("iot.protocol.securityMode"),
                            prop: "securityMode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { disabled: !_vm.editable },
                              model: {
                                value: _vm.opcuaForm.securityMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.opcuaForm, "securityMode", $$v)
                                },
                                expression: "opcuaForm.securityMode",
                              },
                            },
                            _vm._l(_vm.securityModeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.timeout"),
                        prop: "timeout",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          type: "number",
                          max: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                        },
                        model: {
                          value: _vm.opcuaForm.timeout,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "timeout", $$v)
                          },
                          expression: "opcuaForm.timeout",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.opcuaForm.securityPolicy !== "none"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("iot.protocol.securityCert"),
                              prop: "securityCert",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-box",
                                attrs: {
                                  "on-success": _vm.uploadSecurityCertSuccess,
                                  "on-error": _vm.uploadFail,
                                  "show-file-list": false,
                                  "with-credentials": true,
                                  accept: ".der, .pem",
                                  action: _vm.uploadPath,
                                },
                              },
                              [
                                _vm.editable
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "trigger",
                                          icon: "el-icon-upload",
                                          size: "small",
                                          type: "primary",
                                        },
                                        slot: "trigger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("iot.protocol.selectFile")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.opcuaForm.securityCert
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            icon: "el-icon-download",
                                            size: "small",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadCert(
                                                _vm.opcuaForm.securityCert
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "iot.protocol.downloadFile"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("iot.protocol.signCert"),
                              prop: "signCert",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-box",
                                attrs: {
                                  "on-success": _vm.uploadSignCertSuccess,
                                  "on-error": _vm.uploadFail,
                                  "show-file-list": false,
                                  "with-credentials": true,
                                  accept: ".der, .pem",
                                  action: _vm.uploadPath,
                                },
                              },
                              [
                                _vm.editable
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "trigger",
                                          icon: "el-icon-upload",
                                          size: "small",
                                          type: "primary",
                                        },
                                        slot: "trigger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("iot.protocol.selectFile")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.opcuaForm.signCert
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            icon: "el-icon-download",
                                            size: "small",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadCert(
                                                _vm.opcuaForm.signCert
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "iot.protocol.downloadFile"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.username"),
                        prop: "username",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.opcuaForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "username", $$v)
                          },
                          expression: "opcuaForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.password"),
                        prop: "password",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          type: "password",
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.opcuaForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "password", $$v)
                          },
                          expression: "opcuaForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _vm.opcuaForm.securityPolicy !== "none"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("iot.protocol.pkPassword"),
                            prop: "pkPassword",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              readonly: !_vm.editable,
                              type: "password",
                              maxlength: "60",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.opcuaForm.pkPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.opcuaForm, "pkPassword", $$v)
                              },
                              expression: "opcuaForm.pkPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.daqIntvl"),
                        prop: "daqIntvl",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.opcuaForm.daqIntvl,
                          callback: function ($$v) {
                            _vm.$set(_vm.opcuaForm, "daqIntvl", $$v)
                          },
                          expression: "opcuaForm.daqIntvl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }