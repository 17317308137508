<template>
  <div class="peak-valley-value">
    <el-row :gutter="20">
      <el-col :span="8" v-for="item in dataset" :key="item.key">
        <div class="card-container">
          <div class="label-line">
            {{ item.label }}
          </div>
          <div class="value-line">
            {{ item.value }}
            <span class="unit-span" v-if="item.unit">
              {{ item.unit }}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "peak-valley-value",
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.peak-valley-value {
  .card-container {
    height: 90px;
    width: 100%;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
    .label-line {
      font-weight: 400;
      font-size: 14px;
      color: #747e9c;
    }
    .value-line {
      font-weight: bold;
      font-size: 24px;
      color: #252d3d;
      .unit-span {
        font-size: 0.6em;
      }
    }
  }
}
</style>