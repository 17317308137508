<template>
  <div class="peak-valley-charts">
    <div class="title-line">平均变化趋势</div>
    <div id="peakVallyCharts" v-show="dataset.length"></div>
    <div class="no-data" v-show="!dataset.length">暂无数据...</div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { dateFormat } from "@/filters/index.js";

export default {
  name: "peak-valley-charts",
  props: {
    dataset: {
      default() {
        return [];
      },
    },
    duration: {
      type: Number,
      default() {
        return 2;
      },
    },
    unitStr: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      myChart: null,
      resourceData: [],
    };
  },
  watch: {
    dataset: {
      handler(datas) {
        if (!datas || !datas.length) {
          return;
        }
        const dateList = datas.map((item) => {
          // duration: 1-保留时分，2-保留月日
          const timeFormat = this.duration === 1 ? "HH:mm" : "MM-DD";
          return dateFormat(item.date, timeFormat);
        });
        const countList = datas.map((item) => item.avg);
        this.resourceData = datas;
        this.$nextTick(() => {
          this.initCharts(dateList, countList);
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dateFormat,
    initCharts(dateList, countList) {
      const chartDom = document.getElementById("peakVallyCharts");
      this.myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: "40px",
          right: "40px",
          bottom: "30px",
          top: "30px",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          padding: 0,
          borderWidth: 0,
          axisPointer: {
            type: "line",
            lineStyle: {
              // width: 20,
              type: "dotted",
              color: "#2E8EFF",
            },
          },
          alwaysShowContent: false,
          formatter: (params) => {
            const { dataIndex } = params[0];
            const { date, avg, max, min } = this.resourceData[dataIndex];
            return `<div class='property-history-tips'>
              <div>时间: ${date}</div>  
              <div>最大值: ${max}${this.unitStr || ""}</div>  
              <div>最小值: ${min}${this.unitStr || ""}</div>  
              <div>平均值: ${avg}${this.unitStr || ""}</div>  
            </div>`;
          },
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          // axisLabel: {
          //   color: "#8190AC",
          //   interval: 0,
          // },
          data: dateList || ["9-1", "9-2", "9-3", "9-4", "9-5", "9-6", "9-7"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        series: [
          {
            type: "line",
            smooth: true,
            color: "#0089FF",
            lineStyle: {
              width: 2,
              color: "#0089FF",
            },
            showSymbol: false,
            label: {
              show: false,
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#3673E8",
                },
                {
                  offset: 1,
                  color: "#ffffff00",
                },
              ]),
            },
            data: countList || [50, 140, 232, 101, 264, 90, 340],
            markPoint: {
              symbol: "circle",
              symbolSize: 10,
              label: {
                show: false,
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                  itemStyle: {
                    color: "#3687F3",
                  },
                },
                {
                  type: "min",
                  name: "最小值",
                  itemStyle: {
                    color: "#F13636",
                  },
                },
              ],
            },
            markLine: {
              silent: false,
              symbol: ["none", "none"],
              label: {
                show: true,
              },
              lineStyle: {
                color: "#3687F3",
              },
              data: [{ type: "average", name: "平均值" }],
            },
            // markLine: {
            //   label: "平均值",
            //   data: {
            //     type: "average",
            //     lineStyle: {
            //       color: "#3687F3",
            //     },
            //   },
            // },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.peak-valley-charts {
  padding: 15px;
  background: #f8f8f8;
  border-radius: 10px;
  position: relative;
  .title-line {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 16px;
    color: #252d3d;
  }
  #peakVallyCharts {
    height: 190px;
    width: 100%;
  }
  .no-data {
    height: 190px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .property-history-tips {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 4px 4px;
    line-height: 25px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #152c5b;
  }
}
</style>