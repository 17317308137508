var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gateway-terminal-component" }, [
    _c(
      "div",
      { staticClass: "filter-line" },
      [
        _c(
          "el-form",
          {
            ref: "sshForm",
            attrs: { inline: true, model: _vm.sshForm, rules: _vm.rules },
          },
          [
            _c(
              "span",
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "ip" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入IP地址" },
                      model: {
                        value: _vm.sshForm.ip,
                        callback: function ($$v) {
                          _vm.$set(_vm.sshForm, "ip", $$v)
                        },
                        expression: "sshForm.ip",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "port" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "端口" },
                      model: {
                        value: _vm.sshForm.port,
                        callback: function ($$v) {
                          _vm.$set(_vm.sshForm, "port", $$v)
                        },
                        expression: "sshForm.port",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入用户名" },
                      model: {
                        value: _vm.sshForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.sshForm, "username", $$v)
                        },
                        expression: "sshForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入密码", "show-password": "" },
                      model: {
                        value: _vm.sshForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.sshForm, "password", $$v)
                        },
                        expression: "sshForm.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.connect()
                          },
                        },
                      },
                      [_vm._v("连接")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("sshForm")
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-container" }, [
      _c("div", {
        staticStyle: { "margin-top": "10px" },
        attrs: { id: "terminal" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }