<template>
  <div>
    <template v-if="channel.protocolName == 'modbus' || channel.protocolName == 'wanwu'">
      <channel-modbus
        ref="modbus"
        :editable="editable"
        :channel="channel"
        @cancelEditChannel="cancelEditChannel"
      ></channel-modbus>
    </template>
    <template v-else-if="channel.protocolName == 'opcua'">
      <channel-opcua
        ref="opcua"
        :editable="editable"
        :channel="channel"
        @cancelEditChannel="cancelEditChannel"
      ></channel-opcua>
    </template>
    <template v-else-if="channel.protocolName == 'bacnet'">
      <channel-bacnet
        ref="bacnet"
        :editable="editable"
        :channel="channel"
        @cancelEditChannel="cancelEditChannel"
      ></channel-bacnet>
    </template>
    <template v-else>
      <channel-customize
        ref="customize"
        :editable="editable"
        @cancelEditChannel="cancelEditChannel"
        :channel="channel"
      ></channel-customize>
    </template>
  </div>
</template>

<script>
import ChannelModbus from "@/views/ruge/vlink/device/protocol/channelModbus";
import ChannelOpcua from "@/views/ruge/vlink/device/protocol/channelOpcua";
import ChannelBacnet from "@/views/ruge/vlink/device/protocol/channelBacnet";
import ChannelCustomize from "@/views/ruge/vlink/device/protocol/channelCustomize";

export default {
  components: { ChannelModbus, ChannelOpcua, ChannelBacnet, ChannelCustomize },
  props: {
    channel: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    checkValidAndGetConfig(protocolName) {
      console.log("protocolName", protocolName);
      this.$refs[protocolName].saveChannel();
    },
    cancelEditChannel(v) {
      this.$emit("cancelEditChannel", v);
    },
  },
};
</script>
