var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.channel.protocolName == "modbus" ||
      _vm.channel.protocolName == "wanwu"
        ? [
            _c("channel-modbus", {
              ref: "modbus",
              attrs: { editable: _vm.editable, channel: _vm.channel },
              on: { cancelEditChannel: _vm.cancelEditChannel },
            }),
          ]
        : _vm.channel.protocolName == "opcua"
        ? [
            _c("channel-opcua", {
              ref: "opcua",
              attrs: { editable: _vm.editable, channel: _vm.channel },
              on: { cancelEditChannel: _vm.cancelEditChannel },
            }),
          ]
        : _vm.channel.protocolName == "bacnet"
        ? [
            _c("channel-bacnet", {
              ref: "bacnet",
              attrs: { editable: _vm.editable, channel: _vm.channel },
              on: { cancelEditChannel: _vm.cancelEditChannel },
            }),
          ]
        : [
            _c("channel-customize", {
              ref: "customize",
              attrs: { editable: _vm.editable, channel: _vm.channel },
              on: { cancelEditChannel: _vm.cancelEditChannel },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }