<template>
  <div class="property-setting-history">
    <finalTable
      ref="finalTableRef"
      :datas="dataset"
      @tableEventHandler="tableEventHandler"
    />

    <!-- 显示消息内容对话框 -->
    <el-dialog
      width="1100px"
      :visible.sync="responseDataDialogShow"
      :modal="false"
    >
      <template slot="title">
        {{ $t("vlink.tsl.responseData") }}
      </template>
      <div class="tabs-container">
        <div>
          <el-tabs v-model="inputActiveName">
            <el-tab-pane label="下发数据" name="inputFirst">
              <ace-editor
                :key="currentRow.productKey"
                v-model="reportContent"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="60vh"
              ></ace-editor>
            </el-tab-pane>
            <el-tab-pane label="完整数据" name="inputSecond">
              <ace-editor
                :key="currentRow.productKey"
                v-model="reportContentAll"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="60vh"
              ></ace-editor>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <el-tabs v-model="outputActiveName">
            <el-tab-pane label="响应数据" name="outputFirst">
              <ace-editor
                :key="currentRow.productKey"
                v-model="responseContent"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="60vh"
              ></ace-editor>
            </el-tab-pane>
            <el-tab-pane label="完整数据" name="outputSecond">
              <ace-editor
                :key="currentRow.productKey"
                v-model="responseContentAll"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="60vh"
              ></ace-editor>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div slot="footer">
        <el-button type="primary" @click="responseDataDialogShow = false"
          >返回</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { findPropertyHistory } from "@/api/ruge/vlink/device/device";
import AceEditor from "vue2-ace-editor";
export default {
  name: "propertySettingHistory",
  components: {
    finalTable,
    AceEditor,
  },
  props: ["currentRow", "propertyName", "moduleName"],
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "requestId",
            label: "请求ID",
            width: "",
          },
          // 服务调用类型:async（异步调用）或sync（同步调用）,暂时写死为同步调用(sync)
          {
            prop: "callType",
            label: "调用方式",
            width: "",
          },
          {
            prop: "requestTime",
            label: "下发时间",
            width: "420",
          },
          {
            prop: "timeDifference",
            label: "耗时(S)",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "115",
          },
        ],
        tableData: [],
        searchLineConfig: {
          callType: {
            type: "selector",
            label: "调用方式",
            value: "",
            placeholder: "请选择调用方式",
            actionType: "goSearch",
            optionList: [
              {
                label: "异步",
                value: "async",
              },
              {
                label: "同步",
                value: "sync",
              },
            ],
          },
          requestTime: {
            type: "dateRange",
            actionType: "goSearch",
            value: [null, null],
          },
        },
        detailConfig: {
          callType: {
            type: "enumerationColumn",
            emuList: {
              async: "异步",
              sync: "同步",
            },
          },
          requestTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看响应数据",
              },
            ],
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      listQuery: {
        startTime: null,
        endTime: null,
        current: 1,
        rowCount: 10,
        listLoading: false,
        callType: "",
      },
      // 要显示的响应内容
      responseContent: null,
      responseContentAll: null,
      reportContent: null,
      reportContentAll: null,
      responseDataDialogShow: false,
      inputActiveName: "inputFirst",
      outputActiveName: "outputFirst",
    };
  },
  watch: {
    listQuery: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    getTableList() {
      this.listQuery.listLoading = true;
      const { productKey, deviceName } = this.currentRow;
      const { current, rowCount, startTime, endTime, callType } =
        this.listQuery;
      findPropertyHistory({
        productKey,
        deviceName,
        propertyName: this.propertyName,
        moduleName: this.moduleName,
        current,
        rowCount,
        startTime,
        endTime,
        callType,
      })
        .then((res) => {
          res.rows.forEach((item) => {
            item.timeDifference =
              (new Date(item.responseTime).getTime() -
                new Date(item.requestTime).getTime()) /
              1000;
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.listQuery.listLoading = false;
        });
    },
    contentRender(row) {
      // 格式化下发数据
      const AllInputDatas = row.inputData
        ? JSON.stringify(JSON.parse(row.inputData.trim()), null, "\t")
        : "";
      this.reportContentAll = AllInputDatas;
      if (
        row.inputData &&
        JSON.parse(row.inputData).params &&
        JSON.parse(row.inputData).params.modules
      ) {
        // 新版数据取params.modules
        this.reportContent = JSON.stringify(
          JSON.parse(row.inputData).params.modules,
          null,
          "\t"
        );
      } else {
        // 旧版数据直接取全部
        this.reportContent = AllInputDatas;
      }

      // 格式化响应数据
      const AllOutputDatas = row.outputData
        ? JSON.stringify(JSON.parse(row.outputData.trim()), null, "\t")
        : "";
      this.responseContentAll = AllOutputDatas;
      if (
        row.outputData &&
        JSON.parse(row.outputData).data &&
        JSON.parse(row.outputData).data.params &&
        JSON.parse(row.outputData).data.params.modules
      ) {
        // 新版数据取params.modules
        this.responseContent = JSON.stringify(
          JSON.parse(row.outputData).data.params.modules,
          null,
          "\t"
        );
      } else {
        // 旧版数据直接取全部
        this.responseContent = AllOutputDatas;
      }

      this.responseDataDialogShow = true;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.requestTime && datas.params.requestTime.length > 0) {
          this.listQuery.startTime = datas.params.requestTime[0]
            ? new Date(datas.params.requestTime[0]).getTime()
            : null;
          this.listQuery.endTime = datas.params.requestTime[1]
            ? new Date(datas.params.requestTime[1]).getTime()
            : null;
          delete datas.params.requestTime;
        }
        this.listQuery = {
          ...this.listQuery,
          ...datas.params,
        };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.contentRender(datas.row);
            break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.property-setting-history {
  .tabs-container {
    display: flex;
    & > div {
      flex: 1;
    }
    & > div + div {
      margin-left: 20px;
    }
  }
}
</style>