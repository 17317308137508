<template>
  <div class="device-log-component">
    <div class="filter-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!--查看日志详情抽屉-->
    <el-dialog width="728px" :visible.sync="logDrawer">
      <template slot="title">
        <span>{{ $t("vlink.log.logDetails") }}</span>
      </template>
      <span style="margin-left: 10px; font-size: 18px">topic</span>
      <el-input
        type="textarea"
        :rows="1"
        v-model="this.currentTopic"
        style="margin-top: 10px; margin-bottom: 50px"
      >
      </el-input>
      <span style="margin-top: 10px; margin-left: 10px; font-size: 18px">{{
        $t("vlink.log.content")
      }}</span>
      <el-input
        type="textarea"
        :rows="15"
        v-model="this.currentContent"
        style="margin-top: 10px"
      >
      </el-input>
    </el-dialog>
  </div>
</template>

<script>
import { findThingsLogPage } from "@/api/ruge/vlink/device/device";
import finalTable from "@/components/FinalTable";
import moment from "moment";
export default {
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "requestId",
            label: this.$t("vlink.commons.requestId"),
            width: "",
          },
          {
            prop: "logLevel",
            label: this.$t("vlink.log.level"),
            width: "",
          },
          {
            prop: "logType",
            label: this.$t("vlink.log.type"),
            width: "",
          },
          {
            prop: "deviceName",
            label: this.$t("vlink.device.deviceName"),
            width: "",
          },
          {
            prop: "productKey",
            label: this.$t("vlink.product.productKey"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "reportTime",
            label: this.$t("vlink.commons.reportTime"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "115",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          requestId: {
            type: "input",
            label: "请求ID",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入请求ID",
            prefixIcon: "el-icon-search",
          },
          logLevel: {
            type: "input",
            label: "级别",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入级别",
            prefixIcon: "el-icon-search",
          },
          logType: {
            type: "input",
            label: "类型",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入类型",
            prefixIcon: "el-icon-search",
          },
          deviceName: {
            type: "input",
            label: "设备标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备标识码",
            prefixIcon: "el-icon-search",
          },
          productKey: {
            type: "input",
            label: "产品标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入产品标识码",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          reportTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          requestId: {
            inline: true,
            value: "",
          },
          logLevel: {
            inline: true,
            value: "",
          },
          logType: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          deviceName: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          reportTime: {
            type: "dateRange",
            label: "上报时间",
            // value: [new Date(moment().subtract(7, "days")).getTime(), null],
            value: [null, null],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      openDelay: 1000,
      logDrawer: false,
      currentTopic: null,
      currentContent: null,
      log: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          productKey: null,
          deviceName: null,
          requestId: null,
          // startTime: new Date(moment().subtract(7, "days")).getTime(),
          startTime: null,
          endTime: null,
          logType: null,
          logLevel: null,
        },
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentRow: {
      immediate: true,
      handler: function () {
        this.findThingsLogPage();
      },
    },
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getThingsLogPage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.reportTime && datas.params.reportTime.length > 0) {
          this.log.listQuery.startTime = new Date(
            datas.params.reportTime[0]
          ).getTime();
          this.log.listQuery.endTime = new Date(
            datas.params.reportTime[1]
          ).getTime();
          delete datas.params.reportTime;
        } else {
          this.log.listQuery.endTime = "";
          this.log.listQuery.startTime = "";
        }
        this.log.listQuery = { ...this.log.listQuery, ...datas.params };
        this.findThingsLogPage();
      } else if (datas.type === "paginationChange") {
        this.log.listQuery.current = datas.params.current.page;
        this.log.listQuery.rowCount = datas.params.current.limit;
        this.getThingsLogPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.getLogDetails(datas.row);
            break;
        }
      }
    },
    findThingsLogPage() {
      this.log.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.log.listQuery.productKey = this.currentRow.productKey;
      this.log.listQuery.deviceName = this.currentRow.deviceName;
      this.getThingsLogPage();
    },
    getThingsLogPage() {
      let param = this.log.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.log.listLoading = true;
      console.log("param", param);
      findThingsLogPage(param).then((response) => {
        this.dataset.pageVO.total = response.total;
        this.dataset.tableData = response.rows;
        this.log.listLoading = false;
      });
    },
    startPicker(time) {
      this.log.listQuery.startTime = time.getTime();
    },
    endPicker(time) {
      this.log.listQuery.endTime = time.getTime();
    },
    getLogDetails({ topic, content }) {
      this.currentTopic = topic;
      this.logDrawer = true;
      this.initThing(content);
    },
    // 格式化框JSON
    initThing(content) {
      //格式化json
      if (content != null) {
        this.currentContent = JSON.stringify(JSON.parse(content), null, "\t");
      }
    },
  },
};
</script>

<style scoped lang="less">
.device-log-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>