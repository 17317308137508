<template>
  <div class="gateway-deploy-component" v-loading="loading">
    <div class="state-line">
      <span class="state-label">{{
        this.$t("vlink.device.deployStatus")
      }}</span>
      <el-tag effect="dark" v-if="detailInfo.deployStatus == null" type="info">
        {{ $t("vlink.device.notDeploy") }}
      </el-tag>
      <el-tag
        effect="dark"
        v-if="detailInfo.deployStatus == 'deployed'"
        type="success"
      >
        {{ $t("vlink.device.deployed") }}
      </el-tag>
      <el-tag
        effect="dark"
        v-if="detailInfo.deployStatus == 'underway'"
        type="warning"
      >
        {{ $t("vlink.device.underway") }}
      </el-tag>
      <el-tag
        effect="dark"
        v-if="detailInfo.deployStatus == 'fail'"
        type="danger"
      >
        {{ $t("vlink.device.deployFail") }}
      </el-tag>
      <el-tag
        effect="dark"
        v-if="detailInfo.deployStatus == 'cancel'"
        type="warning"
      >
        {{ $t("vlink.device.cancelDeploy") }}
      </el-tag>

      <i class="el-icon-refresh" @click="refreshData()"></i>
    </div>

    <div class="filter-container">
      <el-button
        size="small"
        icon="el-icon-sold-out"
        type="primary"
        :disabled="
          detailInfo.deployStatus == 'underway' ||
          detailInfo.deviceStatus == 'OFFLINE' ||
          detailInfo.deviceStatus == 'INACTIVE'
        "
        @click="deployConfig()"
      >
        {{ $t("vlink.device.deploy") }}
      </el-button>
      <el-button
        size="small"
        icon="el-icon-refresh-left"
        type="warning"
        :disabled="detailInfo.deployStatus != 'underway'"
        @click="cancelDeploy()"
      >
        {{ $t("vlink.device.cancelDeploy") }}
      </el-button>
    </div>

    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!--查看部署动态抽屉-->
    <el-dialog :visible.sync="deployDynamicDrawer" width="728px">
      <template slot="title">
        <span>{{ $t("vlink.device.deployDynamic") }}</span>
      </template>
      <el-timeline v-loading="dialogLoading">
        <el-timeline-item
          v-for="(item, index) in deployLogList"
          :key="index"
          :timestamp="item.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss')"
        >
          <template
            v-if="
              item.deployDynamic == 'gateway_getconfig_fail' ||
              item.deployDynamic == 'gateway_deploy_fail'
            "
          >
            {{ $t("vlink.device." + item.deployDynamic) }}
          </template>
          <!-- <template v-else-if="item.deployDynamic == 'gateway_getconfig_success'">
            {{ $t("vlink.device." + item.deployDynamic) }}
          </template> -->
          <template
            v-else-if="item.deployDynamic == 'gateway_getconfig_processing'"
          >
            {{ $t("vlink.device." + item.deployDynamic) }}
          </template>
          <template v-else>
            {{ $t("vlink.device." + item.deployDynamic) }}
          </template>
          <el-button
            v-if="
              ['gateway_getconfig_fail', 'gateway_getconfig_success'].includes(
                item.deployDynamic
              )
            "
            type="text"
            @click="viewGetConfig(item.description, item.deployDynamic)"
            icon="el-icon-document"
            >{{
              item.deployDynamic === "gateway_getconfig_success"
                ? $t("vlink.device.configView")
                : $t("vlink.device.exceptionMessage")
            }}</el-button
          >
        </el-timeline-item>
      </el-timeline>
      <div class="config-view-container" v-if="dialogVisible">
        <span class="config-view-title">
          {{ $t("vlink.device.configView") }}
        </span>
        <ace-editor
          :key="currentRow.productKey"
          v-model="getConfigContent"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="320px"
        ></ace-editor>
      </div>
    </el-dialog>
    <!-- 显示消息内容对话框 -->
    <!-- <el-dialog :visible.sync="dialogVisible">
      <template slot="title">
        {{ $t("vlink.device.configView") }}
      </template>
      <ace-editor
        :key="currentRow.productKey"
        v-model="getConfigContent"
        @init="editorInit"
        lang="json"
        theme="chrome"
        style="border: 1px solid #ebecec"
        height="50vh"
      ></ace-editor>
      <div slot="footer">
        <el-button type="primary" @click="closeGetConfigContent">{{
          $t("commons.close")
        }}</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  findDeviceDetail,
  deployConfig,
  cancelDeploy,
  findDeployLogPage,
  findDeployLogList,
} from "@/api/ruge/vlink/device/device";
import AceEditor from "vue2-ace-editor";
import finalTable from "@/components/FinalTable";
export default {
  components: {
    AceEditor,
    finalTable,
  },
  data() {
    return {
      dialogLoading: false,
      dataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: false,
        },
        selection: {
          need: false,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "requestId",
            label: this.$t("vlink.commons.requestId"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("commons.creationTime"),
            width: "",
          },
          {
            prop: "deployStatus",
            label: "状态",
            width: "",
          },
          {
            prop: "costTime",
            label: "耗时(S)",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "115",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          deployStatus: {
            type: "enumerationColumn",
            emuList: {
              deployed: this.$t("vlink.device.deployed"),
              underway: this.$t("vlink.device.underway"),
              fail: this.$t("vlink.device.deployFail"),
              cancel: this.$t("vlink.device.cancelDeploy"),
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看部署动态",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      // 对话框是否可见，默认不可见
      dialogVisible: false,
      // 要显示的响应内容
      getConfigContent: undefined,
      deployDynamicDrawer: false,
      openDelay: 1000,
      loading: true,
      detailInfo: {},
      deployLog: {
        listLoading: false,
        total: 0,
        list: null,
        listQuery: {
          current: 1,
          rowCount: 10,
          productKey: this.currentRow.productKey,
          deviceName: this.currentRow.deviceName,
        },
      },
      deployLogList: [],
      showState: "",
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceId() {
      return this.currentRow.deviceId;
    },
  },
  watch: {
    deviceId: {
      immediate: true,
      handler: function () {
        this.getDetail();
        this.getDeployLogPage();
      },
    },
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // this.filterObj = { ...this.filterObj, ...datas.params };
        // this.findProductPage();
      } else if (datas.type === "paginationChange") {
        // this.filterObj.current = datas.params.current.page;
        // this.filterObj.rowCount = datas.params.current.limit;
        // this.getProductPage();
      } else if (datas.type === "updateSelectionList") {
        // this.selectionList = datas.list;
      } else if (datas.type === "switchEvent") {
        // this.updateDynamicRegistration(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewDeployDynamic(datas.row.requestId);
            break;
        }
      }
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    // 查看配置内容
    viewGetConfig(description, state) {
      this.getConfigContent = "";
      if (this.showState && this.showState === state && this.dialogVisible) {
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }
      this.showState = state;
      if (state === "gateway_getconfig_success") {
        let obj = JSON.parse(description.trim());
        // 格式化json
        this.getConfigContent = JSON.stringify(obj, null, "\t");
      } else {
        this.getConfigContent = description.trim();
      }
    },
    //关闭模态框
    closeGetConfigContent() {
      this.dialogVisible = false;
    },
    // 查看部署动态日志数据
    viewDeployDynamic(requestId) {
      this.dialogVisible = false;
      this.deployDynamicDrawer = true;
      this.dialogLoading = true;
      findDeployLogList({ requestId: requestId })
        .then((res) => {
          this.deployLogList = res;
          this.dialogLoading = false;
        })
        .catch(() => {
          this.dialogLoading = false;
        });
    },
    getDeployLogPage() {
      // 加载日志动态分页
      this.deployLog.listLoading = true;
      findDeployLogPage(this.deployLog.listQuery)
        .then((res) => {
          this.dataset.pageVO.total = res.total;
          this.dataset.tableData = res.rows;
        })
        .catch((error) => {
          console.log(`服务请求失败，原因 => ${error}`);
        })
        .finally(() => {
          this.deployLog.listLoading = false;
        });
    },
    getDetail() {
      this.loading = true;
      const { productKey, deviceName } = this.currentRow;
      findDeviceDetail({ productKey, deviceName })
        .then((response) => {
          this.detailInfo = response;
        })
        .catch((error) => {
          console.log(`服务请求失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshData() {
      this.getDetail();
      this.getDeployLogPage();
    },
    // 设备部署
    deployConfig() {
      this.$confirm(
        "网关现有配置可能会更新，确定部署吗？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          const param = {
            productKey: this.detailInfo.productKey,
            deviceName: this.detailInfo.deviceName,
          };
          deployConfig(param)
            .then((res) => {
              if (res.code == "200") {
                // 成功提示
                this.$message({
                  message: this.$t("message.operationSuccess"),
                  type: "success",
                });
                this.refreshData();
              } else {
                this.$message({
                  message: this.$t("message.operationFailed"),
                  type: "error",
                });
              }
            })
            .catch((error) => {
              console.log(`部署失败，原因 => ${error}`);
            });
        })
        .catch(() => {
          console.log("取消部署");
        });
    },
    // 取消部署
    cancelDeploy() {
      const param = {
        productKey: this.detailInfo.productKey,
        deviceName: this.detailInfo.deviceName,
      };
      cancelDeploy(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.operationSuccess"),
            type: "success",
          });
          this.refreshData();
        })
        .catch((error) => {
          console.log(`取消部署失败，原因 => ${error}`);
        });
    },
  },
};
</script>
<style scoped lang="less">
.gateway-deploy-component {
  .state-line {
    display: flex;
    align-items: center;
    margin: 20px 10px;
    .state-label {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #303133;
      font-weight: 600;
      margin-right: 10px;
    }
    .el-icon-refresh {
      color: #000;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .filter-container {
    margin: 0 10px;
  }
  .table-container {
    margin: 0 10px;
  }
  .config-view-container {
    margin-bottom: 40px;
    .config-view-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #303133;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  ::v-deep .el-timeline {
    padding-left: 0;
  }
}
</style>