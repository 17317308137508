<template>
  <div>
    <el-form
      label-position="top"
      ref="modbusForm"
      :rules="modbusRule"
      :model="modbusForm"
    >
      <!-- <el-row>
                <el-col>
                    <el-form-item :label="$t('iot.protocol.channelName')" prop="channelName">
                        <el-input :readonly="!editable" v-model="modbusForm.channelName" maxlength="60"
                                  :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->
      <el-row>
        <el-col>
          <el-form-item
            :label="$t('iot.protocol.transferModal')"
            prop="transferModal"
          >
            <el-radio-group
              :disabled="!editable"
              v-model="modbusForm.transferModal"
              @change="transferChange"
            >
              <el-radio label="TCP" border>{{
                $t("iot.protocol.transferTcp")
              }}</el-radio>
              <el-radio label="RTU" border>{{
                $t("iot.protocol.transferRtu")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="modbusForm.transferModal == 'TCP'">
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.host')" prop="host">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.host"
                maxlength="20"
                :show-word-limit="true"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.port')" prop="port">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.port"
                type="number"
                min="0"
                :show-word-limit="true"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.daqIntvl"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="响应超时时间(毫秒)" prop="responseTimeout">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.responseTimeout"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <template v-if="modbusForm.transferModal == 'RTU'">
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item
              :label="$t('iot.protocol.serialPort')"
              prop="serialPort"
            >
              <el-input
                :readonly="!editable"
                v-model="modbusForm.serialPort"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.baudRate')" prop="baudRate">
              <el-select
                :disabled="!editable"
                :style="{ width: '100%' }"
                v-model="modbusForm.baudRate"
              >
                <el-option
                  v-for="item in baudRateList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.dataBit')" prop="dataBit">
              <el-select
                :disabled="!editable"
                :style="{ width: '100%' }"
                v-model="modbusForm.dataBit"
              >
                <el-option
                  v-for="item in dataBitList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.checkBit')" prop="checkBit">
              <el-select
                :disabled="!editable"
                :style="{ width: '100%' }"
                v-model="modbusForm.checkBit"
              >
                <el-option
                  v-for="item in checkBitList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.stopBit')" prop="stopBit">
              <el-select
                :disabled="!editable"
                :style="{ width: '100%' }"
                v-model="modbusForm.stopBit"
              >
                <el-option
                  v-for="item in stopBitList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.daqIntvl"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="响应超时时间(毫秒)" prop="responseTimeout">
              <el-input
                :readonly="!editable"
                v-model="modbusForm.responseTimeout"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveChannel">{{
            $t("commons.save")
          }}</el-button>
          <el-button @click="cancelEditChannel(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    channel: {
      immediate: true,
      handler: function () {
        console.log("xxx1xxx");
        this.initChannel();
      },
    },
  },
  data() {
    return {
      baudRateList: [
        300, 600, 1200, 2400, 4800, 9600, 14400, 19200, 38400, 56000, 57600,
        115200, 128000, 230400, 256000,
      ],
      dataBitList: [8, 16],
      checkBitList: [
        { value: 0, name: "无" },
        { value: 1, name: "奇" },
        { value: 2, name: "偶" },
      ],
      stopBitList: [1, 2],
      modbusForm: {
        channelId: null,
        channelName: null,
        transferModal: "TCP",
        host: null,
        port: null,
        serialPort: null,
        baudRate: null,
        dataBit: null,
        checkBit: null,
        stopBit: null,
        daqIntvl: 1000,
        responseTimeout: 1000,
      },
      modbusRule: {
        channelName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        transferModal: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        host: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        port: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        serialPort: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        baudRate: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        dataBit: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        checkBit: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        stopBit: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  methods: {
    initChannel() {
      if (this.channel) {
        this.modbusForm.channelId = this.channel.channelId;
        this.modbusForm.channelName = this.channel.channelName;
        if (this.channel.extendConfig) {
          let customize = JSON.parse(this.channel.extendConfig);
          this.modbusForm.transferModal = customize.transferModal;
          this.modbusForm.serialPort = customize.serialPort;
          this.modbusForm.baudRate = customize.baudRate;
          this.modbusForm.dataBit = customize.dataBit;
          this.modbusForm.checkBit = customize.checkBit;
          this.modbusForm.stopBit = customize.stopBit;
          this.modbusForm.daqIntvl = customize.daqIntvl;
          this.modbusForm.responseTimeout = customize.responseTimeout;
          this.modbusForm.host = customize.host;
          this.modbusForm.port = customize.port;
        }
      }
    },
    transferChange() {
      if (this.modbusForm.transferModal == "RTU") {
        this.modbusForm.baudRate = 9600;
        this.modbusForm.dataBit = 8;
        this.modbusForm.checkBit = 0;
        this.modbusForm.stopBit = 1;
        // this.modbusForm.daqIntvl = 1000;
        this.modbusForm.host = null;
        this.modbusForm.port = null;
      } else {
        this.modbusForm.serialPort = null;
        this.modbusForm.baudRate = null;
        this.modbusForm.dataBit = null;
        this.modbusForm.checkBit = null;
        this.modbusForm.stopBit = null;
        // this.modbusForm.daqIntvl = null;
      }
    },
    saveChannel() {
      this.$refs.modbusForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          // 回写数据
          this.cancelEditChannel(param);
        }
      });
    },
    buildParam() {
      let param = {
        channelId: this.modbusForm.channelId,
        channelName: this.modbusForm.channelName,
        extendConfig: null,
      };
      if (this.modbusForm.transferModal == "RTU") {
        param.extendConfig = JSON.stringify({
          transferModal: this.modbusForm.transferModal,
          serialPort: this.modbusForm.serialPort,
          baudRate: this.modbusForm.baudRate,
          dataBit: this.modbusForm.dataBit,
          checkBit: this.modbusForm.checkBit,
          stopBit: this.modbusForm.stopBit,
          daqIntvl: this.modbusForm.daqIntvl,
          responseTimeout: this.modbusForm.responseTimeout,
        });
      } else {
        param.extendConfig = JSON.stringify({
          transferModal: this.modbusForm.transferModal,
          host: this.modbusForm.host,
          port: this.modbusForm.port,
          daqIntvl: this.modbusForm.daqIntvl,
          responseTimeout: this.modbusForm.responseTimeout,
        });
      }
      return param;
    },
    cancelEditChannel(v) {
      this.$emit("cancelEditChannel", v);
    },
  },
};
</script>
