<template>
  <div>
    <el-form
      label-position="top"
      ref="bacnetForm"
      :rules="bacnetRule"
      :model="bacnetForm"
    >
      <el-row>
        <el-col>
          <el-form-item
            :label="$t('iot.protocol.transferModal')"
            prop="transferModal"
          >
            <el-radio-group
              :disabled="!editable"
              v-model="bacnetForm.transferModal"
              @change="transferChange"
            >
              <el-radio border label="IP">{{
                $t("iot.protocol.transferIP")
              }}</el-radio>
              <el-radio border label="MSTP">{{
                $t("iot.protocol.transferMstp")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="bacnetForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <template v-if="bacnetForm.transferModal == 'IP'">
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.netBroad')" prop="netBroad">
              <el-input
                :readonly="!editable"
                v-model="bacnetForm.netBroad"
                maxlength="20"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-if="bacnetForm.transferModal == 'MSTP'">
          <el-col :span="12">
            <el-form-item
              :label="$t('iot.protocol.serialPort')"
              prop="serialPort"
            >
              <el-input
                :readonly="!editable"
                v-model="bacnetForm.serialPort"
                maxlength="60"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.baudRate')" prop="baudRate">
              <el-select
                :disabled="!editable"
                :style="{ width: '100%' }"
                v-model="bacnetForm.baudRate"
                :placeholder="$t('commons.pleaseSelect')"
              >
                <el-option
                  v-for="item in baudRateList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              :label="$t('iot.protocol.mstpAddress')"
              prop="mstpAddress"
            >
              <el-input
                :readonly="!editable"
                type="number"
                v-model="bacnetForm.mstpAddress"
                maxlength="60"
                min="0"
                max="127"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-row>

      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveChannel">{{
            $t("commons.save")
          }}</el-button>
          <el-button @click="cancelEditChannel(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    channel: {
      immediate: true,
      handler: function () {
        this.initChannel();
      },
    },
  },
  data() {
    return {
      baudRateList: [9600, 19200, 38400, 56000, 57600, 76800, 115200],
      bacnetForm: {
        channelId: null,
        channelName: null,
        transferModal: "IP",
        netBroad: null,
        serialPort: null,
        baudRate: null,
        mstpAddress: null,
        daqIntvl: 1000,
      },
      bacnetRule: {
        channelName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        transferModal: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        netBroad: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        serialPort: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        baudRate: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        mstpAddress: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  methods: {
    initChannel() {
      if (this.channel) {
        this.bacnetForm.channelId = this.channel.channelId;
        this.bacnetForm.channelName = this.channel.channelName;
        if (this.channel.extendConfig) {
          let customize = JSON.parse(this.channel.extendConfig);
          this.bacnetForm.transferModal = customize.transferModal;
          this.bacnetForm.serialPort = customize.serialPort;
          this.bacnetForm.baudRate = customize.baudRate;
          this.bacnetForm.netBroad = customize.netBroad;
          this.bacnetForm.mstpAddress = customize.mstpAddress;
          this.bacnetForm.daqIntvl = customize.daqIntvl;
        }
      }
    },
    transferChange() {
      if (this.bacnetForm.transferModal == "MSTP") {
        this.bacnetForm.baudRate = 9600;
        this.bacnetForm.netBroad = null;
      } else {
        this.bacnetForm.baudRate = null;
        this.bacnetForm.serialPort = null;
        this.bacnetForm.mstpAddress = null;
      }
    },
    saveChannel() {
      this.$refs.bacnetForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          // 回写数据
          this.cancelEditChannel(param);
        }
      });
    },
    buildParam() {
      let param = {
        channelId: this.bacnetForm.channelId,
        channelName: this.bacnetForm.channelName,
        extendConfig: null,
      };
      if (this.bacnetForm.transferModal == "MSTP") {
        param.extendConfig = JSON.stringify({
          transferModal: this.bacnetForm.transferModal,
          serialPort: this.bacnetForm.serialPort,
          baudRate: this.bacnetForm.baudRate,
          mstpAddress: this.bacnetForm.mstpAddress,
          daqIntvl: this.bacnetForm.daqIntvl,
        });
      } else {
        param.extendConfig = JSON.stringify({
          transferModal: this.bacnetForm.transferModal,
          netBroad: this.bacnetForm.netBroad,
          daqIntvl: this.bacnetForm.daqIntvl,
        });
      }
      return param;
    },
    cancelEditChannel(v) {
      this.$emit("cancelEditChannel", v);
    },
  },
};
</script>
