var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "device-info-comp",
    },
    [
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.basicInformation")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.device.basicInfoTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.deviceName")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.detailInfo && _vm.detailInfo.deviceName) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "product-info" }, [
          _c(
            "div",
            {
              staticClass: "product-detail-info",
              on: {
                click: function ($event) {
                  _vm.showAllProductInfo = !_vm.showAllProductInfo
                },
              },
            },
            [
              _c("div", { staticClass: "left-part" }, [
                _c("span", { staticClass: "detail-header-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        `所属产品信息（${_vm.detailInfo.productName || "-"}）`
                      ) +
                      "\n          "
                  ),
                ]),
                _c("span", { staticClass: "detail-header-tips" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.basicInfoTips")) +
                      "，\n          "
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "look-detail",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.jumpToProductHandler.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v("\n            查看详情\n            "),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "right-part" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.showAllProductInfo
                        ? "收起所属产品信息"
                        : "展开所属产品信息"
                    ) +
                    "\n          "
                ),
                _vm.showAllProductInfo
                  ? _c("i", { staticClass: "el-icon-arrow-up" })
                  : _c("i", { staticClass: "el-icon-arrow-down" }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAllProductInfo,
                  expression: "showAllProductInfo",
                },
              ],
              staticClass: "detail-cont",
            },
            [
              _c("div", [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.productName")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        (_vm.detailInfo && _vm.detailInfo.productName) || "-"
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.nodeType")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.nodeTypeRender(
                          _vm.detailInfo && _vm.detailInfo.nodeType
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.dataType")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.dataTypeRender(
                          _vm.detailInfo && _vm.detailInfo.dataType
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.authType")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.authTypeRender(
                          _vm.detailInfo && _vm.detailInfo.authType
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "last-line" }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("vlink.product.protocol")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.detailInfo &&
                          _vm.$t("vlink.commons." + _vm.detailInfo.protocol)
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("vlink.product.productKey") + " ProductKey"
                      ) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.detailInfo && _vm.detailInfo.productKey) +
                      "\n          "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _vm.detailInfo.nodeType === "SUBDEVICE"
          ? _c("div", { staticClass: "product-info" }, [
              _c(
                "div",
                {
                  staticClass: "product-detail-info",
                  on: {
                    click: function ($event) {
                      _vm.showAllGatewayInfo = !_vm.showAllGatewayInfo
                    },
                  },
                },
                [
                  _c("div", { staticClass: "left-part" }, [
                    _c("span", { staticClass: "detail-header-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            `所属网关信息（${
                              _vm.detailInfo &&
                              _vm.detailInfo.parentDeviceDescription
                                ? _vm.detailInfo.parentDeviceDescription
                                : _vm.detailInfo.parentDeviceName || "-"
                            }）`
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("span", { staticClass: "detail-header-tips" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vlink.product.basicInfoTips")) +
                          "，\n          "
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "look-detail",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.jumpToGatewayHandler.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v("\n            查看详情\n            "),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "right-part" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.showAllGatewayInfo
                            ? "收起所属网关信息"
                            : "展开所属网关信息"
                        ) +
                        "\n          "
                    ),
                    _vm.showAllGatewayInfo
                      ? _c("i", { staticClass: "el-icon-arrow-up" })
                      : _c("i", { staticClass: "el-icon-arrow-down" }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAllGatewayInfo,
                      expression: "showAllGatewayInfo",
                    },
                  ],
                  staticClass: "detail-cont",
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("网关产品名称"),
                    ]),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            (_vm.detailInfo &&
                              _vm.detailInfo.parentProductName) ||
                              "-"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("网关产品标识码 ProductKey"),
                    ]),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            (_vm.detailInfo &&
                              _vm.detailInfo.parentProductKey) ||
                              "-"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("网关名称"),
                    ]),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            (_vm.detailInfo &&
                              _vm.detailInfo.parentDeviceDescription) ||
                              "-"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "detail-label" }, [
                      _vm._v("网关设备标识码 DeviceName"),
                    ]),
                    _c("div", { staticClass: "detail-value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            (_vm.detailInfo &&
                              _vm.detailInfo.parentDeviceName) ||
                              "-"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.product.certificateAndTime")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.device.certificateAndTimeTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.product.productKey") + " ProductKey") +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.detailInfo && _vm.detailInfo.productKey) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.createTime")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.detailInfo && _vm.detailInfo.creationDate,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.deviceName") + " DeviceName") +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.detailInfo && _vm.detailInfo.deviceName) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.activationTime")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.detailInfo && _vm.detailInfo.activationTime,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("vlink.device.deviceSecret") + " DeviceSecret"
                  ) +
                  "\n          "
              ),
              _c(
                "span",
                {
                  staticClass: "copy-container",
                  on: { click: _vm.copyTheSecret },
                },
                [
                  _c("i", { staticClass: "el-icon-document-copy" }),
                  _vm._v("\n            复制证书\n          "),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "copy-container",
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = true
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-tickets" }),
                  _vm._v("\n            查看MQTT连接参数\n          "),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item view-tooltips",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("vlink.product.seeProductSecret"),
                      placement: "bottom",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showeSecret = !_vm.showeSecret
                          },
                        },
                      },
                      [
                        _vm.showeSecret
                          ? _c("i", {
                              staticClass:
                                "iconfont icon-yanjing-1 el-icon-view",
                            })
                          : _c("i", {
                              staticClass: "iconfont icon-biyan el-icon-view",
                            }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    style: {
                      position: "relative",
                      top: _vm.showeSecret ? "0" : "2px",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.showeSecret ? _vm.certInfo.deviceSecret : "******"
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.lastOnlineTime")) +
                  "\n          "
              ),
              _c(
                "span",
                {
                  staticClass: "copy-container",
                  on: { click: _vm.deviceOpeHistory },
                },
                [
                  _c("i", { staticClass: "el-icon-tickets" }),
                  _vm._v("\n            设备上下线历史\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.detailInfo && _vm.detailInfo.lastOnlineTime,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "header-cont" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.device.deviceConfig")) +
                "\n      "
            ),
          ]),
          _c("span", { staticClass: "header-tips" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("vlink.device.productConfigTips")) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-cont" }, [
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.deviceStatus")) +
                  "\n          "
              ),
              _c(
                "span",
                {
                  staticClass: "copy-container",
                  on: { click: _vm.refreshData },
                },
                [
                  _c("i", { staticClass: "el-icon-refresh" }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("commons.refresh")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _c(
                  "el-tag",
                  {
                    staticStyle: { "margin-right": "8px" },
                    attrs: {
                      type:
                        _vm.detailInfo.deviceStatus == "INACTIVE"
                          ? "info"
                          : _vm.detailInfo.deviceStatus == "ONLINE"
                          ? "success"
                          : "danger",
                    },
                  },
                  [
                    _vm.detailInfo.deviceStatus == "INACTIVE"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("vlink.commons.inactive"))),
                        ])
                      : _vm._e(),
                    _vm.detailInfo.deviceStatus == "ONLINE"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("vlink.commons.online"))),
                        ])
                      : _vm._e(),
                    _vm.detailInfo.deviceStatus == "OFFLINE"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("vlink.commons.offline"))),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.detailInfo.nodeType === "GATEWAY" &&
                (_vm.detailInfo.deviceStatus == "ONLINE" ||
                  (_vm.detailInfo.deviceStatus == "OFFLINE" &&
                    _vm.detailInfo.deviceName != null))
                  ? _c(
                      "span",
                      {
                        staticClass: "copy-container",
                        on: { click: _vm.deviceStateDialogShow },
                      },
                      [
                        _c("i", { staticClass: "el-icon-s-marketing" }),
                        _vm._v("\n            查看设备状态探测\n          "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.deviceDescription")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _c("div", { staticClass: "detail-value" }, [
                _vm.descriptionEdit
                  ? _c(
                      "span",
                      [
                        _c("el-input", {
                          ref: "descInput",
                          attrs: { maxlength: "50", size: "mini" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateDesc.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.detailInfo.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailInfo, "description", $$v)
                            },
                            expression: "detailInfo.description",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.detailInfo && _vm.detailInfo.description
                              ? _vm.detailInfo.description
                              : "-"
                          ) +
                          "\n            "
                      ),
                    ]),
                !_vm.descriptionEdit
                  ? _c("i", {
                      staticClass: "el-icon-edit",
                      on: { click: _vm.showDescriptionEdit },
                    })
                  : _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "success",
                            size: "mini",
                            icon: "el-icon-check",
                            circle: "",
                          },
                          on: { click: _vm.updateDesc },
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-close",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.descriptionEdit = false
                              _vm.detailInfo.description = _vm.descriptionBak
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.deviceCode")) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "detail-value" }, [
              _c("div", { staticClass: "detail-value" }, [
                _vm.deviceCodeEdit
                  ? _c(
                      "span",
                      [
                        _c("el-input", {
                          ref: "codeInput",
                          attrs: { maxlength: "50", size: "mini" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateDeviceCode.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.detailInfo.deviceCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailInfo,
                                "deviceCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "detailInfo.deviceCode",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.detailInfo && _vm.detailInfo.deviceCode
                              ? _vm.detailInfo.deviceCode
                              : "-"
                          ) +
                          "\n            "
                      ),
                    ]),
                !_vm.deviceCodeEdit
                  ? _c("i", {
                      staticClass: "el-icon-edit",
                      on: { click: _vm.showDeviceCodeEdit },
                    })
                  : _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "success",
                            size: "mini",
                            icon: "el-icon-check",
                            circle: "",
                          },
                          on: { click: _vm.updateDeviceCode },
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-close",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.deviceCodeEdit = false
                              _vm.detailInfo.deviceCode = _vm.deviceCodeBak
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [_vm._v("启用设备")]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-value": "ENABLE",
                    "inactive-value": "DISABLE",
                    "active-color": "#13ce66",
                  },
                  on: { change: _vm.updateEnableStatus },
                  model: {
                    value: _vm.detailInfo.enableStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.detailInfo, "enableStatus", $$v)
                    },
                    expression: "detailInfo.enableStatus",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v("\n          隔离状态\n          "),
              _vm.showISOButton(_vm.detailInfo.isolateEndTime)
                ? _c(
                    "span",
                    {
                      staticClass: "copy-container",
                      on: {
                        click: function ($event) {
                          return _vm.isoHandler(_vm.detailInfo)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-timer" }),
                      _vm._v("\n            隔离\n          "),
                    ]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "copy-container",
                      on: {
                        click: function ($event) {
                          return _vm.cancelIsoHandler(_vm.detailInfo)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-refresh-left" }),
                      _vm._v("\n            取消隔离\n          "),
                    ]
                  ),
            ]),
            _c("div", [
              _c("span", { staticClass: "detail-value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.isoTimeRender(_vm.detailInfo.isolateEndTime)) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.localLogReport")) +
                  "\n        "
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-value" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-value": "ENABLE",
                    "inactive-value": "DISABLE",
                    "active-color": "#13ce66",
                  },
                  on: { change: _vm.updateLocalLogReport },
                  model: {
                    value: _vm.detailInfo.localLogReport,
                    callback: function ($$v) {
                      _vm.$set(_vm.detailInfo, "localLogReport", $$v)
                    },
                    expression: "detailInfo.localLogReport",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "detail-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("vlink.device.label")) +
                  "\n        "
              ),
            ]),
            _c(
              "div",
              [
                _vm.detailInfo && _vm.detailInfo.tagList
                  ? _c("productTagBind", {
                      attrs: {
                        tagValueList: _vm.detailInfo.tagList,
                        tagType: "device",
                      },
                      on: {
                        tagDelete: _vm.handleTagDelete,
                        tagAdd: _vm.handleTagAdd,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.extendInfoList.length
        ? _c("div", { staticClass: "card-container" }, [
            _c("div", { staticClass: "header-cont config-header" }, [
              _vm._m(0),
              _c(
                "span",
                { staticClass: "edit-span", on: { click: _vm.openEditDialog } },
                [
                  _c("i", { staticClass: "el-icon-edit" }),
                  _vm._v("\n        编辑\n      "),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "detail-cont" },
              [
                _c(
                  "el-row",
                  { staticClass: "config-row" },
                  _vm._l(_vm.extendInfoList, function (item) {
                    return _c(
                      "el-col",
                      {
                        key: item.label,
                        staticStyle: { "margin-top": "20px" },
                        attrs: { span: item.layout === "all" ? 24 : 12 },
                      },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _c("span", {
                            staticClass: "desc-span",
                            attrs: { id: item.id, title: item.value },
                            domProps: { innerHTML: _vm._s(item.value) },
                          }),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "900px",
            top: "50px",
            visible: _vm.deviceOpeHistoryShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deviceOpeHistoryShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v("设备上下线历史")]),
          ]),
          _vm.deviceOpeHistoryShow
            ? _c("deviceOpeHistory", { attrs: { currentRow: _vm.currentRow } })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "MQTT 连接参数", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { staticClass: "margin-top", attrs: { column: 1, border: "" } },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 客户机标识 ")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.mqttForm.clientId) + "\n      "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 账号 ")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.mqttForm.userName) + "\n      "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 密码 ")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.mqttForm.password) + "\n      "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 主机地址 ")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.mqttForm.hostUrl) + "\n      "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 端口号 ")]),
                  _vm._v("\n        " + _vm._s(_vm.mqttForm.port) + "\n      "),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.copyMqtt } },
                [_vm._v("复 制")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设备状态探测",
            width: "960px",
            visible: _vm.dialogDeviceStatus,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDeviceStatus = $event
            },
          },
        },
        [
          [
            _c("div", { attrs: { id: "container" } }, [
              _c("div", { attrs: { id: "newCharts" } }),
              _c(
                "div",
                { staticClass: "chart-table" },
                [
                  [
                    _c(
                      "el-table",
                      {
                        ref: "echatsTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "tableEchats",
                          data: _vm.tableData,
                          height: "380",
                          "row-class-name": _vm.tableRowClassName,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "date", label: "日期", width: "160" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "diff",
                            label: "耗时(ms)",
                            width: "150",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ]),
          ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { height: "600px" },
          attrs: {
            title: "隔离时间",
            visible: _vm.timeShow,
            width: "30%",
            "before-close": _vm.cancelTime,
          },
          on: {
            "update:visible": function ($event) {
              _vm.timeShow = $event
            },
          },
        },
        [
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.isolateTimeValue,
                  callback: function ($$v) {
                    _vm.isolateTimeValue = $$v
                  },
                  expression: "isolateTimeValue",
                },
              },
              _vm._l(_vm.isolateTimeSelect, function (item) {
                return _c("el-option", {
                  key: item.isolateTimeValue,
                  attrs: {
                    label: item.isolateTime,
                    value: item.isolateTimeValue,
                  },
                })
              }),
              1
            ),
          ],
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelTime } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.batchIsolateTime },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("ExtendEditDialog", {
        attrs: { dataset: _vm.extendDialog },
        on: { close: _vm.extendDialogClose },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "header-cont" }, [
      _c("span", { staticClass: "header-title" }, [_vm._v(" 扩展信息 ")]),
      _c("span", { staticClass: "header-tips" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("\n          用户自由配置的扩展信息\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }