<template>
  <div class="sub-device-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createDevice">添加子设备</el-button>
        <el-tooltip
          class="item"
          effect="light"
          :disabled="device.multipleSelection.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="primary"
            :class="
              device.multipleSelection.length === 0 && 'not-allowed-button'
            "
            @click="moveDevice"
            plain
            >设备迁移
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="device.multipleSelection.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            :class="
              device.multipleSelection.length === 0 && 'not-allowed-button'
            "
            icon="el-icon-delete"
            @click="batchDelete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip>
        <el-button
          type="primary"
          icon="el-icon-upload"
          size="mini"
          plain
          @click="downDeviceTemplate"
          >{{ $t("commons.importTemplate") }}</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-upload2"
          size="mini"
          plain
          @click="uploadModel = true"
          >{{ $t("commons.import") }}</el-button
        >
        <el-button @click="exportHandler">导出</el-button>
        <el-button>
          <el-dropdown
            @command="reportHandleCommand"
            placement="bottom-start"
            @visible-change="
              (value) => {
                reportDrop = value;
              }
            "
          >
            <span class="el-dropdown-link">
              <i class="el-icon-delete"></i>
              立即上报
              <i v-if="reportDrop" class="el-icon-arrow-up el-icon--right"></i>
              <i v-else class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in reportOptions"
                :key="index"
                :command="item.reportValue"
                >{{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>

    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:channelIdSlot="row">
          <div>
            <span>
              {{
                `${row.datas.protocolName}/${row.datas.channelName || "无通道"}`
              }}
            </span>
          </div>
        </template>
      </finalTable>
    </div>

    <!-- 上报设备 -->
    <el-dialog
      title="上报设备"
      :visible.sync="reportDialogShow"
      width="30%"
      :close-on-click-modal="false"
      style="height: 600px"
    >
      <div style="margin-top: 20px">
        你的操作将对 {{ this.device.multipleSelectionLength }} 条数据生效
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelReport">取 消</el-button>
        <el-button type="primary" @click="batchReport">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加设备对话框 -->
    <el-dialog
      :visible.sync="device.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ device.title }}
      </template>
      <el-form
        label-position="top"
        :model="device.form"
        ref="deviceForm"
        :rules="device.formRules"
      >
        <el-row :gutter="40" v-if="device.title == '设备迁移'">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.commons.gateway')" prop="deviceId">
              <el-select
                :style="{ width: '100%' }"
                filterable
                clearable
                v-model="device.form.parentDeviceId"
                @change="getChannelList"
              >
                <el-option
                  v-for="item in deviceList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.device.product')" prop="productId">
              <el-select
                :style="{ width: '100%' }"
                :disabled="device.form.deviceId != null"
                filterable
                clearable
                v-model="device.form.productId"
              >
                <el-option
                  v-for="item in productList"
                  :label="item.productName"
                  :value="item.productId"
                  :key="item.productId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.deviceName')"
              prop="deviceName"
            >
              <el-input
                v-model="device.form.deviceName"
                :readonly="device.form.deviceId !== null"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-switch
          v-model="channel.channelValue"
          active-text="是否改变当前通道"
          v-if="device.title == '设备迁移'"
          @change="removeChannel"
        >
        </el-switch>
        <div v-if="device.title == '设备迁移'">
          <div v-if="channel.channelValue">
            <el-row :gutter="48">
              <el-col :span="12">
                <el-form-item
                  :label="$t('vlink.device.protocol')"
                  prop="protocol"
                >
                  <el-select
                    :style="{ width: '100%' }"
                    v-model="device.form.protocol"
                    @change="channelChange"
                    placeholder="请选择"
                  >
                    <el-option-group
                      v-for="group in protocolList"
                      :key="group.value"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      >
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('vlink.device.protocolChannel')"
                  prop="channelId"
                  :rules="[
                    {
                      required: ['modbus', 'opcua', 'bacnet', 'snmp'].includes(
                        device.form.protocol
                      ),
                      message: $t('validate.required'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    :style="{ width: '100%' }"
                    @change="selectProtocolChange()"
                    filterable
                    clearable
                    v-model="device.form.channelId"
                  >
                    <el-option
                      v-for="item in protocolChannelListFilted"
                      :label="
                        buildProtocolChannelDisplay(
                          item.protocolName,
                          item.channelName
                        )
                      "
                      :value="item.channelId"
                      :key="item.channelId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else>
          <el-row :gutter="48">
            <el-col :span="12">
              <el-form-item
                :label="$t('vlink.device.protocol')"
                prop="protocol"
              >
                <el-select
                  :style="{ width: '100%' }"
                  v-model="device.form.protocol"
                  @change="channelChange"
                  placeholder="请选择"
                >
                  <el-option-group
                    v-for="group in protocolList"
                    :key="group.value"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('vlink.device.protocolChannel')"
                prop="channelId"
                :rules="[
                  {
                    required: ['modbus', 'opcua', 'bacnet', 'snmp'].includes(
                      device.form.protocol
                    ),
                    message: $t('validate.required'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  :style="{ width: '100%' }"
                  @change="selectProtocolChange()"
                  filterable
                  clearable
                  v-model="device.form.channelId"
                >
                  <el-option
                    v-for="item in protocolChannelListFilted"
                    :label="
                      buildProtocolChannelDisplay(
                        item.protocolName,
                        item.channelName
                      )
                    "
                    :value="item.channelId"
                    :key="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('vlink.device.deviceDescription')"
                prop="description"
              >
                <el-input
                  v-model="device.form.description"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('vlink.device.deviceCode')"
                prop="deviceCode"
              >
                <el-input
                  v-model="device.form.deviceCode"
                  maxlength="32"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col>
              <el-form-item :label="$t('base.project.location')">
                <el-input
                  v-model="device.form.location"
                  :readonly="false"
                  :show-word-limit="true"
                  clearable
                  class="input-with-select"
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-button
                    slot="append"
                    @click="openMapSelect"
                    icon="el-icon-location"
                  ></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <device-info
          ref="deviceInfoRef"
          v-if="device.addDialogVisible"
          :editable="true"
          :device="device.form"
          @cancelEditDevice="closeDeviceConfig"
        ></device-info>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleDeviceSaveHander">
          <div v-if="device.title == '设备迁移'">
            {{ $t("vlink.device.move") }}
          </div>
          <div v-else>
            {{ $t("commons.save") }}
          </div>
        </el-button>
      </div>
    </el-dialog>

    <!-- 批量迁移设备对话框 -->
    <el-dialog title="设备迁移" :visible.sync="device.batchMoveDialogVisible">
      <el-form
        label-position="top"
        ref="moveForm"
        :rules="device.moveRules"
        :model="device.moveForm"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.commons.gateway')"
              prop="parentDeviceId"
            >
              <el-select
                :style="{ width: '100%' }"
                filterable
                clearable
                v-model="device.moveForm.parentDeviceId"
                @change="getChannelList"
              >
                <el-option
                  v-for="item in deviceList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.device.protocol')" prop="protocol">
              <el-select
                :style="{ width: '100%' }"
                v-model="device.moveForm.protocol"
                @change="moveChannelChange"
                placeholder="请选择"
              >
                <el-option-group
                  v-for="group in protocolList"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.protocolChannel')"
              prop="channelId"
              :rules="[
                {
                  required: ['modbus', 'opcua', 'bacnet', 'snmp'].includes(
                    device.moveForm.protocol
                  ),
                  trigger: 'blur',
                  message: $t('validate.required'),
                },
              ]"
            >
              <el-select
                :style="{ width: '100%' }"
                @change="selectMoveProtocolChange()"
                filterable
                clearable
                v-model="device.moveForm.channelId"
              >
                <el-option
                  v-for="item in protocolChannelListFilted"
                  :label="
                    buildProtocolChannelDisplay(
                      item.protocolName,
                      item.channelName
                    )
                  "
                  :value="item.channelId"
                  :key="item.channelId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="device.batchMoveDialogVisible = false">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="batchMoveDevice">确定迁移</el-button>
      </div>
    </el-dialog>

    <!--导入文件-->
    <el-dialog :visible.sync="uploadModel" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :taskType="taskType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>

    <map-select
      :position="position"
      cityName="深圳"
      :actions="isAction"
      :mapSelectDialog="mapSelectDialog"
    />
  </div>
</template>

<script>
import {
  findDevicePage,
  createDevice,
  deleteDeviceById,
  batchDeleteDevice,
  updateEnableStatus,
  findChannelList,
  updateDevice,
  moveDevice,
  batchMoveDevice,
  downDeviceTemplate,
  exportDevice,
  findProtocolPage,
  batchReportDevice,
} from "@/api/ruge/vlink/device/device";
import { envInfo } from "@/constants/envInfo";
import { getProductList } from "@/api/ruge/vlink/product/product";
import Upload from "@/components/Upload";
import { findApp } from "@/api/ruge/vlink/app/app";
// import Pagination from "@/components/Pagination";
import DeviceInfo from "@/views/ruge/vlink/device/subDeviceConfig/deviceInfo";
import finalTable from "@/components/FinalTable";
import { findDeviceList } from "@/api/ruge/vlink/device/device";
import MapSelect from "@/components/MapSelect";
import { cloneDeep } from "lodash";
export default {
  name: "DeviceManager",
  components: {
    // Pagination,
    DeviceInfo,
    finalTable,
    Upload,
    MapSelect,
  },
  created() {
    this.getProtocolPage();
  },
  data() {
    return {
      reportDialogShow: false,
      reportDrop: false,
      multipleSelection: [],
      findMultipleSelection: [],
      reportType: "",
      reportOptions: [
        {
          reportValue: "reportSelected",
          label: "上报设备(本页已选)",
        },
        {
          reportValue: "reportAll",
          label: "上报设备(全部)",
        },
      ],
      appVO: {
        appType: "协议驱动",
        current: 1,
        rowCount: 50,
      },
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.subDevice",
      taskType: "子设备",
      rootPath: envInfo.bgApp.thingsPath,
      daqIntvl: null,
      protocolList: [],
      // {
      //   label: "官方",
      //       options: [
      //   { name: "Modbus", value: "modbus" },
      //   { name: "OPCUA", value: "opcua" },
      //   { name: "BACnet", value: "bacnet" },
      //   { name: "SNMP", value: "snmp" },
      // ],
      // },
      // {
      //   label: "私有",
      //       options: [
      //   { name: "HW-NVR800", value: "hwnvr800" },
      //   { name: "DH6200-V3", value: "dh6200v3" },
      //   { name: "AKE-C03P-15", value: "akec03p15" },
      //   { name: "GB28181-SRS", value: "gb28181srs" },
      //   { name: "YIMA-SDK", value: "yimasdk" },
      //   { name: "WANWU", value: "wanwu" },
      //   { name: "TCP-SERVER", value: "tcpserver" },
      //   { name: "LoRaMQTT", value: "loramqtt" },
      //   { name: "NBIoT-MQTT", value: "nbiotmqtt" },
      //   { name: "ZigbeeMQTT", value: "zigbeemqtt" },
      //   { name: "锦绣一期变配电", value: "jx01bpd" },
      //   { name: "北大青鸟消防", value: "bdqnxf" },
      //   { name: "锦绣三期蒂森电梯", value: "jx03dt" },
      //   { name: "泛海三江", value: "fanhai-fire" },
      //   { name: "富士停车场", value: "fushi-car" },
      //   { name: "FLINK", value: "flink" },
      // ],
      // },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "deviceName",
            label: this.$t("vlink.device.deviceName"),
            width: "",
          },
          {
            prop: "productKey",
            label: this.$t("vlink.device.product"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.device.deviceDescription"),
            width: "",
          },
          {
            prop: "channelId",
            label: this.$t("vlink.device.protocolChannel"),
            width: "",
          },
          {
            prop: "deviceStatus",
            label: this.$t("vlink.device.deviceStatus"),
            width: "",
          },
          {
            prop: "enableStatus",
            label: this.$t("vlink.device.enableStatus"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("vlink.commons.registTime"),
            width: "",
          },
          {
            prop: "activationTime",
            label: this.$t("vlink.device.activationTime"),
            width: "",
          },
          {
            prop: "lastOnlineTime",
            label: this.$t("vlink.device.lastOnlineTime"),
            width: "",
          },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          deviceName: {
            type: "input",
            label: "设备标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备标识码",
            prefixIcon: "el-icon-search",
          },
          productKey: {
            type: "selector",
            filterable: true,
            label: "所属产品",
            value: "",
            placeholder: "请选择所属产品",
            actionType: "goSearch",
            optionList: [],
          },
          description: {
            type: "input",
            label: "设备名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备名称",
            prefixIcon: "el-icon-search",
          },
          channelId: {
            type: "selector",
            label: "协议通道",
            value: "",
            placeholder: "请选择协议通道",
            actionType: "goSearch",
            optionList: [],
          },
          deviceStatus: {
            type: "selector",
            label: "设备状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设备状态",
            optionList: [
              {
                label: "未激活",
                value: "INACTIVE",
              },
              {
                label: "离线",
                value: "OFFLINE",
              },
              {
                label: "在线",
                value: "ONLINE",
              },
            ],
          },
          enableStatus: {
            type: "selector",
            label: "启用状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择启用状态",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          productKey: {
            type: "otherParam",
            paramName: "productName",
          },
          channelId: {
            type: "slot",
            slotName: "channelIdSlot",
          },
          // channelId: {
          //   type: "joinList",
          //   joinSymbol: "/",
          //   joinList: ["protocolName", "channelName"],
          // },
          deviceStatus: {
            type: "tags",
            INACTIVE: {
              type: "info",
              label: "未激活",
            },
            OFFLINE: {
              type: "danger",
              label: "离线",
            },
            OFFLINELONG: {
              type: "danger",
              label: "离线超7天",
            },
            ONLINE: {
              type: "success",
              label: "在线",
            },
          },
          enableStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          creationDate: {
            type: "dateFormat",
          },
          activationTime: {
            type: "dateFormat",
          },
          lastOnlineTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "move",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-folder-remove",
                tooltips: "设备迁移",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          channelId: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      openDelay: 1000,
      device: {
        deviceConfigDialogVisible: false,
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          parentDeviceId: null,
          deviceName: null,
          deviceStatus: null,
          productKey: null,
          registerStartTime: null,
          registerEndTime: null,
          activeStartTime: null,
          activeEndTime: null,
          lastStartTime: null,
          lastEndTime: null,
        },
        multipleSelection: [],
        multipleSelectionLength: 0,
        addDialogVisible: false,
        batchMoveDialogVisible: false,
        moveDialogVisible: false,
        createLoading: false,
        moveForm: {
          deviceId: null,
          deviceName: null,
          productId: null,
          parentDeviceId: null,
          channelId: null,
          productKey: null,
          protocolName: null,
          protocol: null,
          parentProductName: null,
        },
        form: {
          deviceId: null,
          deviceName: null,
          productId: null,
          parentDeviceId: null,
          channelId: null,
          productKey: null,
          protocolName: null,
          protocol: null,
          description: null,
          deviceCode: null,
          location: null,
          tagList: [],
        },
        moveRules: {
          parentDeviceId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          protocol: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
        formRules: {
          productId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          deviceName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 4,
              message: this.$t("vlink.device.deviceNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-@#()]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
          description: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          protocol: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
      productList: [],
      deviceList: [],
      channelList: [],
      protocolChannelList: [],
      protocolChannelListFilted: [],
      channel: {
        channelValue: false,
        copyChannelId: null,
        copyProtocolName: null,
        copyProtocol: null,
      },
      position: null,
      isAction: true,
      mapSelectDialog: false,
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceId() {
      return this.currentRow.deviceId;
    },
  },
  watch: {
    deviceId: {
      immediate: true,
      handler: function () {
        this.device.form.parentDeviceId = this.currentRow.deviceId;
        this.findDevicePage();
        this.getProductList();
        this.findChannelList();
        this.getGatewayList();
      },
    },
    device: {
      deep: true,
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
    },
  },
  methods: {
    // 批量上报数据
    batchReport() {
      if (
        this.reportType !== "ALL" &&
        this.device.findMultipleSelection.length < 1
      )
        return;
      this.$confirm(
        this.$t("message.reportConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchReportDevice({
            gatewayPK: this.currentRow.productKey,
            gatewayDN: this.currentRow.deviceName,
            reportType: this.reportType,
            subDeviceVOS: this.device.findMultipleSelection,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.reportSuccess"),
              type: "success",
            });
          });
        })
        .catch((error) => {
          this.cancelReport();
          console.log(`未上报，原因 => ${error}`);
        });
      this.cancelReport();
    },

    // 取消上报数据
    cancelReport() {
      this.reportDialogShow = false;
      this.reportDrop = null;
      this.device.form.reportDeviceIdList = [];
      this.device.form.tagList = [];
      this.device.multipleSelectionLength = 0;
    },

    selectMapPosition(position) {
      this.device.form.location = position.lng + "," + position.lat;
      this.mapSelectDialog = false;
    },

    openMapSelect() {
      //设置为可操作
      this.isAction = true;
      if (this.device.form.location != null) {
        this.position = {
          lng: parseFloat(this.device.form.location.split(",")[0]),
          lat: parseFloat(this.device.form.location.split(",")[1]),
        };
      } else {
        this.position = null;
      }
      this.mapSelectDialog = true;
    },
    openMapLocation(row) {
      if (row.location) {
        //设置为不可操作
        this.isAction = false;
        this.position = {
          lng: parseFloat(row.location.split(",")[0]),
          lat: parseFloat(row.location.split(",")[1]),
        };
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("base.project.locationNotExist"),
          type: "info",
        });
      }
    },

    getProtocolPage: function () {
      findApp(this.appVO)
        .then((response) => {
          let resultList = response.rows;
          //
          let array1 = new Array();
          let authority1 = {};
          let authority2 = {};
          let options1 = new Array();
          let options2 = new Array();
          authority1.label = "官方";
          authority2.label = "私有";
          resultList.forEach((item) => {
            if (item.owner == "authority") {
              let optCount = {};
              if (item.description != null) {
                optCount.name = item.description;
              } else {
                optCount.name = item.appDriverName.toUpperCase();
              }
              optCount.value = item.appDriverName;
              options1.push(optCount);
            } else {
              let optCount = {};
              if (item.description != null) {
                optCount.name = item.description;
              } else {
                optCount.name = item.appDriverName.toUpperCase();
              }
              optCount.value = item.appDriverName;
              options2.push(optCount);
            }
          });
          authority1.options = options1;
          authority2.options = options2;
          array1.push(authority1);
          array1.push(authority2);
          this.protocolList = array1;
        })
        .catch((error) => {
          console.log("获取协议列表失败：" + error);
          this.loadingFlag = false;
        });
    },

    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getDevicePage();
    },
    exportHandler() {
      let params = cloneDeep(this.device.listQuery);
      delete params.activationTime;
      delete params.creationDate;
      delete params.lastOnlineTime;
      exportDevice(params).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "设备列表",
          taskStatus: 0,
          rootPath: "thingsPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    uploadSuccess() {
      this.uploadModel = false;
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    downDeviceTemplate() {
      downDeviceTemplate();
    },
    channelChange(protocol, notClear) {
      this.protocolChannelListFilted = this.protocolChannelList.filter(
        (item) => item.protocolName === protocol
      );
      !notClear && (this.device.form.channelId = null);
      this.selectProtocolChange();
    },
    removeChannel() {
      if (this.channel.channelValue) {
        this.getChannelList(this.device.form.parentDeviceId);

        this.channel.copyChannelId = this.device.form.channelId;
        this.channel.copyProtocolName = this.device.form.protocolName;
        this.channel.copyProtocol = this.device.form.protocol;
        this.device.form.channelId = null;
        this.device.form.protocolName = null;
        this.device.form.protocol = null;
      } else {
        this.device.form.channelId = this.channel.copyChannelId;
        this.device.form.protocolName = this.channel.copyProtocolName;
        this.device.form.protocol = this.channel.copyProtocol;
        this.channel.copyChannelId = null;
        this.channel.copyProtocolName = null;
        this.channel.copyProtocol = null;
      }
    },
    moveChannelChange(protocol, notClear) {
      this.protocolChannelListFilted = this.channelList.filter(
        (item) => item.protocolName === protocol
      );
      !notClear && (this.device.moveForm.channelId = null);
      this.selectMoveProtocolChange();
    },
    tableEventHandler(datas) {
      debugger;
      console.log("dataas", datas);
      if (datas.type === "goSearch") {
        // 所属产品绑定的值是productName，但是参数需要且只能传productKey，特殊处理下
        // datas.params.productKey = datas.params.productName;
        // delete datas.params.productName;
        // datas.params.channelId = datas.params.protocolName;
        // delete datas.params.protocolName;
        this.dealTime(datas);
        this.device.listQuery = { ...this.device.listQuery, ...datas.params };
        this.findDevicePage();
      } else if (datas.type === "paginationChange") {
        this.device.listQuery.current = datas.params.current.page;
        this.device.listQuery.rowCount = datas.params.current.limit;
        this.getDevicePage();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list;
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editDevice(datas.row);
            break;
          case "view":
            this.viewDeviceDetail(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.deviceId);
            break;
          case "move":
            this.moveEditDevice(datas.row);
            break;
        }
      }
    },
    dealTime(datas) {
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.device.listQuery.registerStartTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : "";
        this.device.listQuery.registerEndTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : "";
        delete datas.params.creationDate;
      } else {
        this.device.listQuery.registerStartTime = "";
        this.device.listQuery.registerEndTime = "";
      }
      if (
        datas.params.activationTime &&
        datas.params.activationTime.length > 0
      ) {
        this.device.listQuery.activeStartTime = datas.params.activationTime[0]
          ? new Date(datas.params.activationTime[0]).getTime()
          : "";
        this.device.listQuery.activeEndTime = datas.params.activationTime[1]
          ? new Date(datas.params.activationTime[1]).getTime()
          : "";
        delete datas.params.activationTime;
      } else {
        this.device.listQuery.activeStartTime = "";
        this.device.listQuery.activeEndTime = "";
      }
      if (
        datas.params.lastOnlineTime &&
        datas.params.lastOnlineTime.length > 0
      ) {
        this.device.listQuery.lastStartTime = datas.params.lastOnlineTime[0]
          ? new Date(datas.params.lastOnlineTime[0]).getTime()
          : "";
        this.device.listQuery.lastEndTime = datas.params.lastOnlineTime[1]
          ? new Date(datas.params.lastOnlineTime[1]).getTime()
          : "";
        delete datas.params.lastOnlineTime;
      } else {
        this.device.listQuery.lastStartTime = "";
        this.device.listQuery.lastEndTime = "";
      }
      return datas;
    },
    findChannelList() {
      findChannelList({
        gatewayDeviceId: this.device.form.parentDeviceId,
      }).then((res) => {
        this.protocolChannelList = res;
        this.dataset.searchLineConfig.channelId.optionList = res.map((item) => {
          return {
            label: item.channelName,
            value: item.channelId,
          };
        });
      });
    },
    findDevicePage() {
      this.device.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.device.listQuery.parentDeviceId = this.currentRow.deviceId;
      this.getDevicePage();
    },
    getDevicePage() {
      let param = this.device.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.device.listLoading = true;
      findDevicePage(param).then((response) => {
        this.dataset.pageVO.total = response.total;
        this.dataset.tableData = response.rows;
        // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
        this.dataset.tableData.forEach((item) => {
          item.deviceStatus = this.getDeviceStatus(
            item.deviceStatus,
            item.parentDeviceStatus
          );
          if (
            item.deviceStatus === "OFFLINE" &&
            new Date().getTime() - new Date(item.lastOnlineTime).getTime() >
              1000 * 60 * 60 * 24 * 7
          ) {
            item.deviceStatus = "OFFLINELONG";
          }
        });
        // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
        this.device.listLoading = false;
      });
    },
    // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
    getDeviceStatus(deviceStatus, parentDeviceStatus) {
      // 未激活
      if (deviceStatus == "INACTIVE") {
        return deviceStatus;
      }
      // 如果是子设备，网关设备离线，则子设备也显示离线
      if (parentDeviceStatus && parentDeviceStatus == "OFFLINE") {
        return "OFFLINE";
      } else {
        return deviceStatus;
      }
    },
    // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
    selectedDeviceChange(data) {
      let deviceIds = [];
      data.forEach(function (obj) {
        deviceIds.push(obj.deviceId);
      });
      this.device.multipleSelection = deviceIds;
    },
    //查询子设备产品列表
    getProductList() {
      getProductList({ nodeType: "SUBDEVICE" }).then((res) => {
        this.productList = res;
        this.dataset.searchLineConfig.productKey.optionList = res.map(
          (item) => {
            return {
              label: item.productName,
              value: item.productKey,
            };
          }
        );
      });
    },
    createDevice() {
      console.log("this.device.form", this.device.form);
      this.currentState = "add";
      this.device.title = "添加子设备";
      this.device.form.deviceId = null;
      this.device.form.deviceName = null;
      this.device.form.productId = null;
      this.device.form.channelId = null;
      this.device.form.extendConfig = null;
      this.device.form.description = null;
      this.device.form.protocol = null;
      this.device.form.protocolName = null;
      this.device.form.location = null;
      this.device.form.deviceCode = null;
      this.device.addDialogVisible = true;
    },
    editDevice(row) {
      this.currentState = "edit";
      this.device.title = this.$t("vlink.device.edit");
      this.device.form.deviceId = row.deviceId;
      this.device.form.deviceName = row.deviceName;
      this.device.form.productId = row.productId;
      this.device.form.channelId = row.channelId;
      this.device.form.description = row.description;
      this.device.form.protocol = row.protocolName;
      this.device.form.extendConfig = row.extendConfig;
      this.device.form.location = row.location;
      this.device.form.deviceCode = row.deviceCode;
      this.channelChange(row.protocolName, true);
      this.selectProtocolChange();
      this.device.addDialogVisible = true;
    },
    moveEditDevice(row) {
      this.currentState = "move";
      this.device.title = this.$t("vlink.device.moveDevice");
      this.device.form.deviceId = row.deviceId;
      this.device.form.deviceName = row.deviceName;
      this.device.form.productId = row.productId;
      this.device.form.channelId = row.channelId;
      this.device.form.protocol = row.protocolName;
      this.device.form.extendConfig = row.extendConfig;
      this.device.form.location = row.location;
      this.device.form.deviceCode = row.deviceCode;
      this.channelChange(row.protocolName, true);
      this.selectProtocolChange();
      this.device.addDialogVisible = true;
    },
    // 取消保存
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
      this.device.form.parentDeviceId = this.currentRow.deviceId;
      this.findChannelList();
      this.channel.channelValue = false;
    },
    handleDeviceSaveHander() {
      console.log(
        "this.device.form.protocolName",
        this.device.form.protocolName
      );
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          this.$refs.deviceInfoRef.checkValidAndGetConfig(
            this.device.form.protocolName
          );
        }
      });
    },
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.device.form.deviceId) {
            updateDevice(this.device.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelDeviceSave();
                this.findDevicePage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            createDevice(this.device.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelDeviceSave();
                this.findDevicePage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    moveDevice() {
      this.device.title = this.$t("vlink.device.moveDevice");
      this.device.moveForm = {
        deviceId: null,
        channelId: null,
      };
      this.device.batchMoveDialogVisible = true;
    },
    // 初始化网关设备列表
    getGatewayList() {
      findDeviceList({ nodeType: "GATEWAY" }).then((deviceList) => {
        this.deviceList = deviceList.map((item) => ({
          label: item.deviceName,
          value: item.deviceId,
        }));
      });
    },
    //  查询网关下的协议通道列表
    getChannelList(value) {
      if (value != "" && value != null) {
        findChannelList({ gatewayDeviceId: value }).then((channelList) => {
          this.channelList = channelList;
          this.protocolChannelListFilted = channelList;
          this.protocolChannelList = channelList;
        });
      }
    },
    batchMoveDevice() {
      if (this.device.multipleSelection.length < 1) {
        return;
      } else {
        this.$refs.moveForm.validate((v) => {
          if (v) {
            this.$confirm(
              this.$t("message.moveConfirm"),
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                batchMoveDevice({
                  deviceIdList: this.device.multipleSelection.map(
                    (item) => item.deviceId
                  ),
                  parentDeviceId: this.device.moveForm.parentDeviceId,
                  channelId: this.device.moveForm.channelId,
                }).then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.moveSuccess"),
                    type: "success",
                  });
                  this.device.batchMoveDialogVisible = false;
                  this.findDevicePage();
                });
              })
              .catch((error) => {
                console.log(`未迁移成功，原因 => ${error}`);
              });
          }
        });
      }
    },
    moveDeviceHander() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          moveDevice(this.device.form)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.moveSuccess"),
                type: "success",
              });
              this.cancelDeviceSave();
              this.findDevicePage();
            })
            .catch((error) => {
              console.log(`未迁移成功，原因 => ${error}`);
            });
        }
      });
    },
    deleteDevice(deviceId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteDeviceById({ deviceId: deviceId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    batchDelete() {
      if (this.device.multipleSelection.length < 1) {
        return;
      } else {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            batchDeleteDevice({
              deviceIdList: this.device.multipleSelection.map(
                (item) => item.deviceId
              ),
            }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.findDevicePage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    updateEnableStatus(row) {
      this.loading = true;
      updateEnableStatus(row)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findDevicePage();
        })
        .catch((error) => {
          this.findDevicePage();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewDeviceDetail(row) {
      const { origin, pathname } = window.location;
      const targetUrl = `${origin}${pathname}#/iot/device/detail?productKey=${row.productKey}&deviceId=${row.deviceId}&nodeType=${row.nodeType}&deviceName=${row.deviceName}&detailAlias=${row.deviceName}&activeTabName=shadow`;
      window.open(targetUrl, "_blank");
      // this.currentRow.deviceId = row.deviceId;
      // this.currentRow.productKey = row.productKey;
      // this.currentRow.deviceName = row.deviceName;
      // this.currentRow.nodeType = row.nodeType;
      // this.$emit("freshTabIndex");
      // this.$router.push({
      //   path: "/iot/device/detail",
      //   query: {
      //     productKey: row.productKey,
      //     deviceId: row.deviceId,
      //     nodeType: row.nodeType,
      //     deviceName: row.deviceName,
      //     detailAlias: row.productKey,
      //   },
      // });
      // window.reload();
    },
    buildProtocolChannelDisplay(protocolName, channelName) {
      if (protocolName) {
        console.log(channelName);
        return protocolName + (channelName ? "/" + channelName : "");
      } else {
        return "";
      }
    },
    selectProtocolChange() {
      const channelId = this.device.form.channelId;
      this.$forceUpdate();
      console.log("protocolChannelList", this.protocolChannelList);
      if (channelId) {
        let protocolObj = this.protocolChannelList.find(
          (v) => v.channelId == channelId
        );
        this.device.form.protocolName = protocolObj
          ? protocolObj.protocolName
          : null;
      } else {
        this.device.form.protocolName = null;
      }
      const { extendConfig } = this.protocolChannelList || {};
      if (extendConfig && JSON.parse(extendConfig).daqIntvl) {
        this.device.form.daqIntvl = JSON.parse(extendConfig).daqIntvl;
      }
    },
    selectMoveProtocolChange() {
      const channelId = this.device.moveForm.channelId;
      this.$forceUpdate();
      if (channelId) {
        let protocolObj = this.channelList.find(
          (v) => v.channelId == channelId
        );
        this.device.moveForm.protocolName = protocolObj.protocolName;
      } else {
        this.device.moveForm.protocolName = null;
      }
    },
    openDeviceConfig() {
      if (this.device.form.productId) {
        this.device.deviceConfigDialogVisible = true;
      } else {
        this.$message({
          message: this.$t("vlink.tsl.tips11"),
          type: "warning",
        });
      }
    },
    closeDeviceConfig(v) {
      if (v && typeof v != "function") {
        this.device.form.channelName = v.channelName;
        this.device.form.extendConfig = v.extendConfig;
      }
      // this.device.deviceConfigDialogVisible = false;
      if (this.currentState === "move") {
        this.moveDeviceHander();
      } else {
        this.handleDeviceSave();
      }
    },
    closeMoveDeviceConfig(v) {
      console.log("vvvvvvvvv", v);
      if (v && typeof v != "function") {
        this.device.moveForm.channelName = v.channelName;
        this.device.moveForm.extendConfig = v.extendConfig;
      }
      // this.device.deviceConfigDialogVisible = false;
      this.moveDeviceHander();
    },

    reportHandleCommand(command) {
      if ("reportSelected" === command) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.multipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.reportValue = null;
          return;
        }
        this.reportType = "PART";
        this.device.form.deviceIdList = this.device.findMultipleSelection;
        this.device.multipleSelectionLength =
          this.device.findMultipleSelection.length;
      } else if ("reportAll" === command) {
        this.device.multipleSelectionLength = this.dataset.pageVO.total;
        this.reportType = "ALL";
      }
      this.reportDialogShow = true;
    },
  },
};
</script>
<style scoped lang="less">
.sub-device-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>