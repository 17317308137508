var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "peak-valley-value" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.dataset, function (item) {
          return _c("el-col", { key: item.key, attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-container" }, [
              _c("div", { staticClass: "label-line" }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        "),
              ]),
              _c("div", { staticClass: "value-line" }, [
                _vm._v("\n          " + _vm._s(item.value) + "\n          "),
                item.unit
                  ? _c("span", { staticClass: "unit-span" }, [
                      _vm._v(
                        "\n            " + _vm._s(item.unit) + "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }