<template>
  <div class="shadow-image-preview">
    <viewer :images="[image]">
      <img
        :title="image"
        style="height: 48px; width: 48px; cursor: pointer"
        :src="imageRender(image)"
        alt=""
      />
    </viewer>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
export default {
  data() {
    return {
      list: [],
    };
  },
  props: {
    image: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    imageList: {
      handler(data) {
        console.log("data", data);
        if (data === this.image) return;
        this.image = data;
      },
    },
  },
  methods: {
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style>
</style>