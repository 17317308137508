var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container sub-device-component" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.findAppPage },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detail.detailDialogVisible,
            "before-close": _vm.cancelAppBind,
            width: "1024px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.detail, "detailDialogVisible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("finalTable", {
                ref: "finalTableRef",
                attrs: { datas: _vm.detail.dataset },
                on: { tableEventHandler: _vm.detailEventHandler },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelAppBind } }, [
                _vm._v(_vm._s(_vm.$t("commons.close")) + "\n            "),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }