var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container device-detail-tabs" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "first-level-tab",
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.device.summary"), name: "info" } },
            [
              _vm.activeMap.info
                ? _c("deviceInfo", {
                    attrs: { currentRow: _vm.currentRow },
                    on: {
                      fresh: function ($event) {
                        return _vm.init("info")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.device.shadow"), name: "shadow" } },
            [
              _vm.activeMap.shadow
                ? _c("device-shadow", { attrs: { currentRow: _vm.currentRow } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.tsl.event"), name: "event" } },
            [
              _vm.activeMap.event
                ? _c("event-history", { attrs: { currentRow: _vm.currentRow } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.tsl.service"), name: "service" } },
            [
              _vm.activeMap.service
                ? _c("service-history", {
                    attrs: { currentRow: _vm.currentRow },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.currentRow.nodeType == "GATEWAY"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.device.protocolChannel"),
                    name: "protocolChannel",
                  },
                },
                [
                  _vm.activeMap.protocolChannel
                    ? _c("protocol-channel", {
                        attrs: { currentRow: _vm.currentRow },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.currentRow.nodeType == "GATEWAY"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.device.subDevice"),
                    name: "sub",
                  },
                },
                [
                  _vm.activeMap.sub
                    ? _c("sub-device-list", {
                        attrs: { currentRow: _vm.currentRow },
                        on: { freshTabIndex: _vm.init },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.currentRow.nodeType == "GATEWAY"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.device.deploy"),
                    name: "gatewayDeploy",
                  },
                },
                [
                  _vm.activeMap.gatewayDeploy
                    ? _c("gateway-deploy", {
                        attrs: { currentRow: _vm.currentRow },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.currentRow.nodeType == "GATEWAY" &&
          this.currentApps.indexOf("rules") > -1
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.device.rule"),
                    name: "gatewayRule",
                  },
                },
                [
                  _vm.activeMap.gatewayRule
                    ? _c("gateway-rule", {
                        attrs: { currentRow: _vm.currentRow },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.currentRow.nodeType == "GATEWAY" &&
          this.currentApps.indexOf("webssh") > -1
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.device.terminal"),
                    name: "gatewayTerminal",
                  },
                },
                [
                  _vm.activeMap.gatewayTerminal
                    ? _c("gateway-terminal", {
                        attrs: { currentRow: _vm.currentRow },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("vlink.product.OTA"), name: "OTA" } },
            [
              _vm.activeMap.OTA
                ? _c("OTA", { attrs: { currentRow: _vm.currentRow } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: { label: _vm.$t("vlink.product.deviceLog"), name: "log" },
            },
            [
              _vm.activeMap.log
                ? _c("deviceLog", { attrs: { currentRow: _vm.currentRow } })
                : _vm._e(),
            ],
            1
          ),
          _vm.currentRow.nodeType == "GATEWAY"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("vlink.app.appManagement"),
                    name: "driver",
                  },
                },
                [_c("appBind", { attrs: { currentRow: _vm.currentRow } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }