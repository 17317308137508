<template>
  <div>
    <el-form
      label-position="top"
      ref="customizeForm"
      :rules="customizeRule"
      :model="customizeForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="customizeForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="扩展配置" prop="extendConfig">
            <ace-editor
              key="extendConfig"
              v-model="customizeForm.extendConfig"
              @init="editorInit"
              lang="json"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="320"
            ></ace-editor>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveDevice">{{
            $t("commons.save")
          }}</el-button>
          <el-button type="success" @click="formatJson">{{
            $t("vlink.commons.formatJson")
          }}</el-button>
          <el-button @click="cancelEditDevice(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  components: { AceEditor },
  props: {
    device: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    device: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.initDevice();
        if (val.daqIntvl && !this.customizeForm.daqIntvl) {
          this.customizeForm.daqIntvl = val.daqIntvl;
        }
      },
    },
  },
  data() {
    return {
      customizeForm: {
        channelId: null,
        channelName: null,
        extendConfig: null,
        daqIntvl: null,
      },
      customizeRule: {
        // extendConfig: [
        //   {
        //     required: true,
        //     trigger: ["blur", "change"],
        //     message: this.$t("validate.required"),
        //   },
        // ],
        daqIntvl: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              // 可以为空
              if (!value) {
                callback();
                return;
              }
              var re = /^[0-9]+$/;
              if (!re.test(value) || value < 500) {
                callback("请输入大于500的正整数");
                return;
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(!this.editable);
    },
    initDevice() {
      if (this.device) {
        this.customizeForm.channelId = this.device.channelId;
        this.customizeForm.channelName = this.device.channelName;
        this.customizeForm.extendConfig = this.device.extendConfig;
        if (this.device.extendConfig) {
          let customize = JSON.parse(this.device.extendConfig);
          this.customizeForm.daqIntvl = customize.daqIntvl;
        }
      }
    },
    cancelEditDevice(v) {
      this.$emit("cancelEditDevice", v);
    },
    saveDevice() {
      this.$refs.customizeForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          if (!param) return;
          // 回写数据
          this.cancelEditDevice(param);
        }
      });
    },
    buildParam() {
      if (!this.customizeForm.extendConfig) {
        this.customizeForm.extendConfig = "{}";
      }
      if (this.checkJsonFormat()) {
        if (this.customizeForm.daqIntvl) {
          let temp = JSON.parse(this.customizeForm.extendConfig);
          temp.daqIntvl = this.customizeForm.daqIntvl;
          this.customizeForm.extendConfig = JSON.stringify(temp);
        }
        let param = {
          channelId: this.customizeForm.channelId,
          channelName: this.customizeForm.channelName,
          extendConfig: this.customizeForm.extendConfig,
        };
        return param;
      }
      return null;
    },
    checkJsonFormat() {
      try {
        if (typeof JSON.parse(this.customizeForm.extendConfig) == "object") {
          return true;
        }
      } catch (e) {
        console.log(`extendConfig格式异常，原因 => ${e}`);
      }
      this.$message({
        message: this.$t("iot.protocol.tips2"),
        type: "warning",
      });
      return false;
    },
    formatJson() {
      if (
        this.customizeForm.extendConfig &&
        this.customizeForm.extendConfig.trim() !== "" &&
        this.checkJsonFormat()
      ) {
        this.customizeForm.extendConfig = JSON.stringify(
          JSON.parse(this.customizeForm.extendConfig.trim()),
          null,
          "\t"
        );
      }
    },
  },
};
</script>
