<template>
  <div class="property-history-component">
    <div
      class="operation-line"
      v-if="['int', 'decimal'].includes(currentDataType)"
    >
      <span class="model-change">
        <span
          :class="['single-tab', item.active && 'single-tab-active']"
          v-for="item in tabList"
          :key="item.key"
          @click="modelChange(item)"
        >
          {{ item.label }}
        </span>
      </span>
      <span
        class="duration-selector"
        v-if="tabList.filter((item) => item.active)[0].key === 'card'"
      >
        <span> 统计周期： </span>
        <el-select v-model="duration" @change="initChartDatas">
          <el-option
            v-for="item in durationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </span>
    </div>
    <div class="title-line">
      <div class="title-detail-line">
        <div class="detail-title">模块</div>
        <div class="detail-content">{{ this.moduleName }}</div>
      </div>
      <div class="title-detail-line">
        <div class="detail-title">模块描述</div>
        <div class="detail-content">{{ this.moduleDescription || "-" }}</div>
      </div>
      <div class="title-detail-line">
        <div class="detail-title">属性</div>
        <div class="detail-content">{{ this.propertyName }}</div>
      </div>
      <div class="title-detail-line">
        <div class="detail-title">属性描述</div>
        <div class="detail-content">{{ this.description || "-" }}</div>
      </div>
    </div>
    <div
      class="table-container"
      v-if="tabList.filter((item) => item.active)[0].key === 'list'"
    >
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <!-- valueSlot -->
        <template v-slot:valueSlot>
          <el-select
            placeholder="关系运算符"
            style="display: inline-block; width: 30%"
            v-model="property.listQuery.operator"
            clearable
            @change="operatorChange"
          >
            <el-option
              v-for="item in operatorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="上报值"
            v-model="property.listQuery.value"
            :disabled="valueDisable"
            style="display: inline-block; width: 30%; margin-left: 10px"
            @input="eventHandlerDebounce"
          >
          </el-input>
          <el-button
            type="text"
            icon="el-icon-refresh"
            style="width: 30%"
            @click="refreshData()"
            >{{ $t("commons.refresh") }}</el-button
          >
        </template>
        <template v-slot:reportedSlot="{ datas }">
          <div v-if="currentDataType === 'img' && datas.value">
            <viewer :images="imageListRender(datas.value)">
              <template v-for="(item, index) in imageListRender(datas.value)">
                <img
                  :key="index"
                  :style="{
                    height: '48px',
                    width: '48px',
                    cursor: 'pointer',
                    display: item === datas.value ? 'inline-block' : 'none',
                  }"
                  :src="imageRender(item)"
                  alt=""
                />
              </template>
            </viewer>
          </div>
          <div v-else>
            <span style="color: #3b3c3f; font-size: 16px; font-weight: bold">
              {{ datas.value }}
            </span>
            <span style="font-size: 12px">
              {{ datas.unit }}
            </span>
          </div>
        </template>
      </finalTable>
      <div
        class="value-cards"
        v-if="['int', 'decimal'].includes(currentDataType)"
      >
        <PeakValleyValue :dataset="peakValleyDatas" />
      </div>
    </div>
    <div class="charts-container" v-else v-loading="cardLaoding">
      <div class="value-cards">
        <PeakValleyValue :dataset="peakValleyDatas" />
      </div>
      <div class="chart-dom">
        <PeakValleyCharts
          :dataset="peakValleyChartDatas"
          :unitStr="unitStr"
          :duration="duration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { findTsl } from "@/api/ruge/vlink/product/product";
import {
  findPropertyHistoryPageDoris,
  findPropertyHistoryAvg,
  findPropertyHistoryTrend,
} from "@/api/ruge/vlink/device/device";
import ShadowImagePreview from "./shadowImagePreview.vue";
import finalTable from "@/components/FinalTable";
import moment from "moment";
import { debounce } from "lodash";
import { envInfo } from "@/constants/envInfo";
import PeakValleyValue from "./components/peakValleyValue.vue";
import PeakValleyCharts from "./components/peakValleyCharts.vue";

export default {
  name: "PropertyHistory",
  components: {
    finalTable,
    ShadowImagePreview,
    PeakValleyValue,
    PeakValleyCharts,
  },
  props: {
    productKey: {
      required: true,
      type: String,
    },
    deviceName: {
      required: true,
      type: String,
    },
    moduleName: {
      type: String,
    },
    propertyName: {
      type: String,
    },
    description: {
      type: String,
    },
    currentDataType: {
      type: String,
    },
    moduleDescription: {
      type: String,
    },
  },
  watch: {
    productKey: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.productKey = value;
        this.findTsl();
      },
    },
    deviceName: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.deviceName = value;
        // this.findPropertyHistoryPage();
      },
    },
    moduleName: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.moduleName = value;
        this.changeModule();
      },
    },
    propertyName: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.propertyName = value;
        // this.findPropertyHistoryPage();
      },
    },
    property: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.finalTableRef.loadingToggle(val.listLoading);
        });
      },
      deep: true,
    },
    currentDataType: {
      handler(val) {
        this.property.listQuery.dataType = val;
        this.findPropertyHistoryPage();
        if (["int", "decimal"].includes(val)) {
          this.operatorList = this.operatorList.concat([
            { label: "大于", value: "gt" },
            { label: "小于", value: "lt" },
            { label: "大于等于", value: "gtOrEquals" },
            { label: "小于等于", value: "ltOrEquals" },
          ]);
        }
      },
      immediate: true,
    },
    valueDisable: {
      handler() {
        if (
          ["isNull", "isNotNull"].includes(this.property.listQuery.operator)
        ) {
          this.property.listQuery.value = "";
        }
      },
    },
  },
  computed: {
    valueDisable() {
      return ["isNull", "isNotNull"].includes(this.property.listQuery.operator);
    },
  },
  data() {
    return {
      unitStr: "",
      cardLaoding: false,
      peakValleyChartDatas: [],
      duration: 2,
      durationOptions: [
        {
          label: "近60分钟",
          value: 1,
        },
        {
          label: "近7天",
          value: 2,
        },
        {
          label: "近15天",
          value: 3,
        },
        {
          label: "近30天",
          value: 4,
        },
      ],
      tabList: [
        {
          label: "列表",
          key: "list",
          active: true,
        },
        {
          label: "卡片",
          key: "card",
          active: false,
        },
      ],
      peakValleyDatas: [
        {
          label: "最大值",
          value: "-",
          key: "max",
        },
        {
          label: "最小值",
          value: "-",
          key: "min",
        },
        {
          label: "平均值",
          value: "-",
          key: "avg",
        },
      ],
      /*
      * 运算符 
        为空:isNull 
        不为空:isNotNull 
        大于:gt 
        小于:lt 
        大于等于:gtOrEquals 
        小于等于:ltOrEquals 
        等于:equals
      * */
      operatorList: [
        { label: "为空", value: "isNull" },
        { label: "不为空", value: "isNotNull" },
        { label: "等于", value: "equals" },
      ],
      eventHandlerDebounce: debounce(() => {
        if (!this.property.listQuery.operator) return;
        this.findPropertyHistoryPage();
      }, 500),
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "timestamp",
            label: this.$t("vlink.commons.reportTime"),
            width: "",
          },
          {
            prop: "value",
            label: this.$t("vlink.tsl.reported"),
            width: "",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          // value: {
          //   type: "input",
          //   label: "上报值",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入上报值",
          //   prefixIcon: "el-icon-search",
          // },
          value: {
            type: "slot",
            slotName: "valueSlot",
          },
          timestamp: {
            type: "dateRange",
            actionType: "goSearch",
            value: [new Date(moment().subtract(7, "days")).getTime(), null],
          },
        },
        // 表格内容配置
        detailConfig: {
          value: {
            // type: "joinList",
            // joinSymbol: "",
            // joinList: ["value", "unit"],
            type: "slot",
            slotName: "reportedSlot",
          },
          timestamp: {
            type: "dateFormat",
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          // reportTime: {
          //   type: "dateRange",
          //   actionType: "goSearch",
          //   value: [new Date(moment().subtract(7, "days")).getTime(), null],
          // },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      tsl: {},
      moduleList: [],
      propertyList: [],
      property: {
        listLoading: false,
        list: [],
        total: 0,
        listQuery: {
          startTime: new Date(moment().subtract(7, "days")).getTime(),
          endTime: null,
          productKey: null,
          deviceName: null,
          moduleName: null,
          propertyName: null,
          dataType: null,
          operator: null,
          value: null,
          current: 1,
          rowCount: 10,
        },
      },
    };
  },
  methods: {
    modelChange({ key }) {
      this.tabList.forEach((item) => {
        item.active = item.key === key;
      });
      console.log("key", key);
      if (key === "list") {
        this.findPropertyHistoryPage();
        this.setDomMargin();
      } else {
        this.initChartDatas();
      }
    },
    initChartDatas() {
      this.cardLaoding = true;
      const durationMap = {
        1: {
          type: "minute",
          quantity: 60,
        },
        2: {
          type: "day",
          quantity: 7,
        },
        3: {
          type: "day",
          quantity: 15,
        },
        4: {
          type: "day",
          quantity: 30,
        },
      };
      const { productKey, deviceName, moduleName, propertyName } =
        this.property.listQuery;
      const params = {
        ...durationMap[this.duration],
        ...{ productKey, deviceName, moduleName, propertyName },
      };
      findPropertyHistoryTrend(params)
        .then((res) => {
          this.dealAvgValues(res);
          this.peakValleyChartDatas = res || [];
          console.log("this.peakValleyChartDatas", this.peakValleyChartDatas);
        })
        .finally(() => {
          this.cardLaoding = false;
        });
    },
    dealAvgValues(list) {
      if (!list || !list.length) {
        this.peakValleyChartDatas = [];
        this.setAvgValue("max", "-", this.unitStr);
        this.setAvgValue("min", "-", this.unitStr);
        this.setAvgValue("avg", "-", this.unitStr);
        return;
      }
      let maxResult = -9999999999;
      let minResult = 9999999999;
      let avgResult = 0;
      list.forEach((item) => {
        const { max, min, avg } = item;
        maxResult = Math.max(max, maxResult);
        minResult = Math.min(min, minResult);
        avgResult += avg;
      });
      this.setAvgValue("max", maxResult, this.unitStr);
      this.setAvgValue("min", minResult, this.unitStr);
      this.setAvgValue(
        "avg",
        (avgResult / list.length).toFixed(2),
        this.unitStr
      );
    },
    setDomMargin() {
      if (!["int", "decimal"].includes(this.currentDataType)) return;
      this.$nextTick(() => {
        const rowList = $(".property-history-component").find(
          ".el-table__row"
        )[1];
        const targetDom = $(rowList).find(".cell");
        targetDom.each((index, item) => {
          item.style.marginBottom = "120px";
        });
      });
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
    refreshData() {
      this.findPropertyHistoryPage();
    },
    operatorChange(operator) {
      // 清空作符 | 为空 | 不为空 => 清掉value => 查询
      if (!operator || operator === "isNull" || operator === "isNotNull") {
        this.property.listQuery.value = "";
        this.findPropertyHistoryPage();
        return;
      }
      const { value } = this.property.listQuery;
      // 如果值不存在，并且不是为空/不为空
      if (!value && operator !== "isNull" && operator !== "isNotNull") return;
      this.findPropertyHistoryPage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.timestamp && datas.params.timestamp.length > 0) {
          this.property.listQuery.startTime = datas.params.timestamp[0]
            ? new Date(datas.params.timestamp[0]).getTime()
            : null;
          this.property.listQuery.endTime = datas.params.timestamp[1]
            ? new Date(datas.params.timestamp[1]).getTime()
            : null;
          delete datas.params.timestamp;
        }
        this.property.listQuery = {
          ...this.property.listQuery,
          ...datas.params,
        };
        this.findPropertyHistoryPage();
      } else if (datas.type === "paginationChange") {
        this.property.listQuery.current = datas.params.current.page;
        this.property.listQuery.rowCount = datas.params.current.limit;
        this.getPropertyHistoryPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            break;
          case "view":
            break;
          case "clear":
            break;
          case "setValue":
            break;
        }
      }
    },
    findTsl() {
      findTsl({ productKey: this.property.listQuery.productKey }).then(
        (response) => {
          this.property.listQuery.current = 1;
          this.moduleList = [];
          this.propertyList = [];
          this.property.total = 0;
          this.property.list = [];

          this.tsl = response;
          if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
            this.tsl.modules.forEach((module) => {
              this.moduleList.push(module);
              if (
                module.moduleName == this.property.listQuery.moduleName &&
                module.properties &&
                module.properties.length > 0
              ) {
                // fill propertyList
                this.propertyList = module.properties;
              }
            });
          }
        }
      );
    },
    changeModule() {
      this.property.listQuery.propertyName = null;
      // 显示模块下的属性列表
      if (
        this.property.listQuery.moduleName &&
        this.moduleList &&
        this.moduleList.length > 0
      ) {
        this.moduleList.forEach((module) => {
          if (
            module.moduleName == this.property.listQuery.moduleName &&
            module.properties &&
            module.properties.length > 0
          ) {
            // fill propertyList
            this.propertyList = module.properties;
          }
        });
      } else {
        this.propertyList = [];
      }
    },
    findPropertyHistoryPage() {
      this.property.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getPropertyHistoryPage();
    },
    startPicker(time) {
      this.property.listQuery.startTime = time.getTime();
    },
    endPicker(time) {
      this.property.listQuery.endTime = time.getTime();
    },
    imageListRender(current) {
      return this.dataset.tableData.map((item) => item.value);
    },
    async getPropertyHistoryPage() {
      this.property.listLoading = true;
      const listResult = await findPropertyHistoryPageDoris(
        this.property.listQuery
      );
      this.dataset.pageVO.total = listResult.total;
      this.dataset.tableData = listResult.rows;
      this.unitStr =
        listResult.rows && listResult.rows.length
          ? listResult.rows[0].unit
          : "";
      const avgResult = await findPropertyHistoryAvg(this.property.listQuery);
      for (let key in avgResult) {
        this.setAvgValue(key, avgResult[key], this.unitStr);
      }
      this.setDomMargin();
      this.property.listLoading = false;
    },
    setAvgValue(key, value, unit) {
      this.peakValleyDatas.forEach((item) => {
        if (item.key === key) {
          item.value = value;
          item.unit = unit;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.property-history-component {
  .operation-line {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .model-change {
      height: 40px;
      border-radius: 10px;
      border: 1px solid #e9eff4;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      .single-tab {
        display: inline-block;
        width: 52px;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        font-size: 14px;
        color: #5d687c;
        cursor: pointer;
        text-align: center;
      }
      .single-tab-active {
        background: #e9eff4;
        border-radius: 8px;
        color: #252d3d;
      }
    }
    .el-icon-refresh {
      margin-right: 5px;
    }
  }
  .title-line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .title-detail-line {
      margin-right: 80px;
      .detail-title {
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000000;
      }
      .detail-content {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      & > div {
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .table-container {
    position: relative;
    .value-cards {
      position: absolute;
      top: 110px;
      left: 0;
      height: 90px;
      width: 100%;
    }
  }
  .charts-container {
    .value-cards {
      margin-top: 20px;
    }
    .chart-dom {
      margin: 20px 0;
      height: 250px;
      width: 100%;
    }
  }
}
</style>
