<template>
  <div>
    <template v-if="device.protocolName == 'modbus'">
      <device-modbus
        ref="modbus"
        :editable="editable"
        :device="device"
        @cancelEditDevice="cancelEditDevice"
      ></device-modbus>
    </template>
    <template v-else-if="device.protocolName == 'opcua'">
      <device-opcua
        ref="opcua"
        :editable="editable"
        :device="device"
        @cancelEditDevice="cancelEditDevice"
      ></device-opcua>
    </template>
    <template v-else-if="device.protocolName == 'bacnet'">
      <device-bacnet
        ref="bacnet"
        :editable="editable"
        :device="device"
        @cancelEditDevice="cancelEditDevice"
      ></device-bacnet>
    </template>
    <template v-else>
      <device-customize
        ref="customize"
        :editable="editable"
        @cancelEditDevice="cancelEditDevice"
        :device="device"
      ></device-customize>
    </template>
  </div>
</template>

<script>
import DeviceModbus from "@/views/ruge/vlink/device/subDeviceConfig/deviceModbus";
import DeviceOpcua from "@/views/ruge/vlink/device/subDeviceConfig/deviceOpcua";
import DeviceBacnet from "@/views/ruge/vlink/device/subDeviceConfig/deviceBacnet";
import DeviceCustomize from "@/views/ruge/vlink/device/subDeviceConfig/deviceCustomize";

export default {
  components: { DeviceModbus, DeviceOpcua, DeviceBacnet, DeviceCustomize },
  props: {
    device: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    checkValidAndGetConfig(protocolName) {
      console.log("protocolName", protocolName);
      if (this.$refs[protocolName]) {
        this.$refs[protocolName].saveDevice();
      } else {
        this.$refs.customize.saveDevice();
      }
    },
    cancelEditDevice(v) {
      this.$emit("cancelEditDevice", v);
    },
  },
};
</script>
