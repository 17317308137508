<template>
  <div class="extend-edit-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      title="扩展信息"
      width="860px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <DynamicForm :dataset="dataset.extendJson" ref="dynamicForm" />
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button plain @click="saveHandler" :loading="submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DynamicForm from "@/components/DynamicForm";
import { updateDeviceExtendInfo } from "@/api/ruge/vlink/device/device";
export default {
  name: "extend-edit-dialog",
  components: {
    DynamicForm,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      submitLoading: false,
    };
  },
  methods: {
    dialogCloseHandler(freshFlag) {
      this.$emit("close", freshFlag);
    },
    async saveHandler() {
      const extendInfo = await this.$refs.dynamicForm.checkFormValidate();
      this.submitLoading = true;
      const { deviceId } = this.dataset;
      const params = {
        extendInfo: JSON.stringify(extendInfo),
        deviceId,
      };
      updateDeviceExtendInfo(params)
        .then((res) => {
          this.$message.success("保存成功！");
          this.dialogCloseHandler(true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.extend-edit-dialog {
  .button-line {
    margin: 20px 0;
    text-align: right;
    .rlink-button-component {
      margin-left: 20px;
    }
  }
}
</style>