<template>
  <div>
    <el-form
      label-position="top"
      ref="opcuaForm"
      :rules="opcuaRule"
      :model="opcuaForm"
    >
      <!-- <el-row>
        <el-col>
          <el-form-item :label="$t('vlink.tsl.trigger')" prop="trigger">
            <el-radio-group :disabled="!editable" v-model="opcuaForm.trigger">
              <el-radio border :label="1">{{
                $t("vlink.tsl.timingAppear")
              }}</el-radio>
              <el-radio border :label="2">{{
                $t("vlink.tsl.changeAppear")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row :gutter="48">
        <!-- <el-col v-if="opcuaForm.trigger == 1" :span="12">
          <el-form-item :label="$t('vlink.tsl.reportCycle')" prop="reportCycle">
            <el-input
              :disabled="!editable"
              :placeholder="$t('commons.pleaseInput')"
              v-model="opcuaForm.reportCycle"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.device.nodeName')" prop="nodePath">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.nodePath"
              maxlength="50"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveDevice">{{
            $t("commons.save")
          }}</el-button>
          <el-button @click="cancelEditDevice(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    device: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.initDevice();
        if (val.daqIntvl && !this.opcuaForm.daqIntvl) {
          this.opcuaForm.daqIntvl = val.daqIntvl;
        }
      },
    },
  },
  data() {
    return {
      opcuaForm: {
        channelId: null,
        channelName: null,
        nodePath: null,
        trigger: 1,
        reportCycle: 5000,
        daqIntvl: null,
      },
      opcuaRule: {
        nodePath: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        trigger: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        daqIntvl: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              // 可以为空
              if (!value) {
                callback();
                return;
              }
              var re = /^[0-9]+$/;
              if (!re.test(value) || value < 500) {
                callback("请输入大于500的正整数");
                return;
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    initDevice() {
      if (this.device) {
        this.opcuaForm.channelId = this.device.channelId;
        this.opcuaForm.channelName = this.device.channelName;
        if (this.device.extendConfig) {
          let customize = JSON.parse(this.device.extendConfig);
          this.opcuaForm.nodePath = customize.nodePath;
          this.opcuaForm.daqIntvl = customize.daqIntvl;
          // if (customize.trigger) {
          //   this.opcuaForm.trigger = customize.trigger;
          //   this.opcuaForm.reportCycle = customize.reportCycle;
          // } else {
          //   this.opcuaForm.trigger = 1;
          //   this.opcuaForm.reportCycle = 5000;
          // }
        }
      }
    },
    saveDevice() {
      this.$refs.opcuaForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          // 回写数据
          this.cancelEditDevice(param);
        }
      });
    },
    buildParam() {
      let param = {
        channelId: this.opcuaForm.channelId,
        channelName: this.opcuaForm.channelName,
        extendConfig: null,
      };
      param.extendConfig = JSON.stringify({
        nodePath: this.opcuaForm.nodePath,
        daqIntvl: this.opcuaForm.daqIntvl,
        // trigger: this.opcuaForm.trigger,
        // reportCycle: this.opcuaForm.reportCycle,
      });
      return param;
    },
    cancelEditDevice(v) {
      this.$emit("cancelEditDevice", v);
    },
  },
};
</script>
