var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "modbusForm",
          attrs: {
            "label-position": "top",
            rules: _vm.modbusRule,
            model: _vm.modbusForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.daqIntvl"),
                        prop: "daqIntvl",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.modbusForm.daqIntvl,
                          callback: function ($$v) {
                            _vm.$set(_vm.modbusForm, "daqIntvl", $$v)
                          },
                          expression: "modbusForm.daqIntvl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.device.slaveId"),
                        prop: "slaveId",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.modbusForm.slaveId,
                          callback: function ($$v) {
                            _vm.$set(_vm.modbusForm, "slaveId", $$v)
                          },
                          expression: "modbusForm.slaveId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "扩展配置", prop: "customize" } },
                    [
                      _c("ace-editor", {
                        key: "customizeJson",
                        staticStyle: { border: "1px solid #ebecec" },
                        attrs: { lang: "json", theme: "chrome", height: "320" },
                        on: { init: _vm.editorInit },
                        model: {
                          value: _vm.modbusForm.customize,
                          callback: function ($$v) {
                            _vm.$set(_vm.modbusForm, "customize", $$v)
                          },
                          expression: "modbusForm.customize",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }