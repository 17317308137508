<template>
  <div class="app-container device-detail-tabs">
    <el-tabs v-model="activeTabName" class="first-level-tab">
      <el-tab-pane :label="$t('vlink.device.summary')" name="info">
        <!-- <info v-bind:currentRow="currentRow"></info> -->
        <deviceInfo
          @fresh="init('info')"
          :currentRow="currentRow"
          v-if="activeMap.info"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.device.shadow')" name="shadow">
        <device-shadow
          v-bind:currentRow="currentRow"
          v-if="activeMap.shadow"
        ></device-shadow>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.tsl.event')" name="event">
        <event-history
          v-bind:currentRow="currentRow"
          v-if="activeMap.event"
        ></event-history>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.tsl.service')" name="service">
        <service-history
          v-bind:currentRow="currentRow"
          v-if="activeMap.service"
        ></service-history>
      </el-tab-pane>
      <el-tab-pane
        v-if="currentRow.nodeType == 'GATEWAY'"
        :label="$t('vlink.device.protocolChannel')"
        name="protocolChannel"
      >
        <protocol-channel
          v-bind:currentRow="currentRow"
          v-if="activeMap.protocolChannel"
        ></protocol-channel>
      </el-tab-pane>
      <el-tab-pane
        v-if="currentRow.nodeType == 'GATEWAY'"
        :label="$t('vlink.device.subDevice')"
        name="sub"
      >
        <sub-device-list
          v-if="activeMap.sub"
          v-bind:currentRow="currentRow"
          @freshTabIndex="init"
        ></sub-device-list>
      </el-tab-pane>
      <el-tab-pane
        v-if="currentRow.nodeType == 'GATEWAY'"
        :label="$t('vlink.device.deploy')"
        name="gatewayDeploy"
      >
        <gateway-deploy
          v-bind:currentRow="currentRow"
          v-if="activeMap.gatewayDeploy"
        ></gateway-deploy>
      </el-tab-pane>
      <el-tab-pane
        v-if="
          currentRow.nodeType == 'GATEWAY' &&
          this.currentApps.indexOf('rules') > -1
        "
        :label="$t('vlink.device.rule')"
        name="gatewayRule"
      >
        <gateway-rule
          v-bind:currentRow="currentRow"
          v-if="activeMap.gatewayRule"
        ></gateway-rule>
      </el-tab-pane>
      <el-tab-pane
        v-if="
          currentRow.nodeType == 'GATEWAY' &&
          this.currentApps.indexOf('webssh') > -1
        "
        :label="$t('vlink.device.terminal')"
        name="gatewayTerminal"
      >
        <gateway-terminal
          v-bind:currentRow="currentRow"
          v-if="activeMap.gatewayTerminal"
        ></gateway-terminal>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.product.OTA')" name="OTA">
        <OTA v-bind:currentRow="currentRow" v-if="activeMap.OTA"></OTA>
      </el-tab-pane>
      <el-tab-pane :label="$t('vlink.product.deviceLog')" name="log">
        <deviceLog
          v-bind:currentRow="currentRow"
          v-if="activeMap.log"
        ></deviceLog>
      </el-tab-pane>
      <el-tab-pane
        v-if="currentRow.nodeType == 'GATEWAY'"
        :label="$t('vlink.app.appManagement')"
        name="driver"
      >
        <appBind v-bind:currentRow="currentRow"></appBind>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import deviceInfo from "@/views/ruge/vlink/device/deviceInfo";
import DeviceShadow from "@/views/ruge/vlink/device/shadowComponent";
import EventHistory from "@/views/ruge/vlink/device/eventHistoryComponent";
import ServiceHistory from "@/views/ruge/vlink/device/serviceHistoryComponent";
import SubDeviceList from "@/views/ruge/vlink/device/subDeviceListComponent";
import ProtocolChannel from "@/views/ruge/vlink/device/protocolComponent";
import GatewayDeploy from "@/views/ruge/vlink/device/gatewayDeployComponent";
import OTA from "@/views/ruge/vlink/device/OTAComponent";
import deviceLog from "@/views/ruge/vlink/device/deviceLogComponent";
import app from "@/views/ruge/vlink/device/appBind.vue";
import gatewayRule from "@/views/ruge/vlink/device/gatewayRuleComponent";
import gatewayTerminal from "@/views/ruge/vlink/device/gatewayTerminalComponent";
import appBind from "@/views/ruge/vlink/device/appBind.vue";

import {
  findDeviceDetail,
  findDeviceApps,
} from "@/api/ruge/vlink/device/device";
export default {
  components: {
    deviceInfo,
    DeviceShadow,
    EventHistory,
    ServiceHistory,
    SubDeviceList,
    ProtocolChannel,
    GatewayDeploy,
    OTA,
    deviceLog,
    gatewayRule,
    gatewayTerminal,
    appBind,
  },
  data() {
    return {
      loading: true,
      currentRow: {
        nodeType: null,
      },
      currentApps: [],
      activeTabName: "info",
      activeMap: {
        info: false,
        shadow: false,
        event: false,
        service: false,
        protocolChannel: false,
        sub: false,
        gatewayDeploy: false,
        gatewayRule: false,
        gatewayTerminal: false,
        OTA: false,
        log: false,
      },
    };
  },
  // props: {
  //     currentRow: {
  //         type: Object,
  //         required: true
  //     }
  // },
  // computed: {
  //     deviceId() {
  //         return this.currentRow.deviceId;
  //     }
  // },
  // watch: {
  //     deviceId: {
  //         immediate: true,
  //         handler: function () {
  //             this.init()
  //         }
  //     }
  // },
  watch: {
    activeTabName: {
      handler(tabName) {
        console.log("tabName", tabName);
        if (!this.activeMap[tabName]) {
          this.activeMap[tabName] = true;
        }
        console.log("activeMap", this.activeMap);
      },
      immediate: true,
    },
  },
  created() {
    const { productKey, deviceName, activeTabName } = this.$route.query;
    this.currentRow = { productKey, deviceName };
    this.init(activeTabName);
  },
  methods: {
    // 页面初始化
    init(activeTabName) {
      this.activeTabName = activeTabName || "info";
      this.getDetail();
      this.getApps();
    },
    getDetail() {
      console.log("this.currentRow.productKey", this.currentRow.productKey);
      const { productKey, deviceName } = this.currentRow || {};
      if (
        !productKey ||
        productKey === "null" ||
        !deviceName ||
        deviceName === "null"
      ) {
        this.$message.warning("设备、产品不能为空！");
        setTimeout(() => {
          this.$router.push({
            path: "/iot/device/list",
          });
        }, 3000);
        return;
      }
      findDeviceDetail({ productKey, deviceName })
        .then((response) => {
          // this.currentRow.deviceId = response.deviceId;
          // this.currentRow.nodeType = response.nodeType;
          this.currentRow = { ...response };
          this.$forceUpdate();
          this.$eventBus.$emit("setDetailAlias", response.deviceName);
        })
        .catch((error) => {
          // 如果查不到该设备详情，跳回设备列表
          if (JSON.stringify(error).includes("status code 518")) {
            setTimeout(() => {
              this.$router.push({
                path: "/iot/device/list",
              });
            }, 3000);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getApps() {
      const { productKey, deviceName } = this.currentRow || {};
      findDeviceApps({ productKey, deviceName })
        .then((response) => {
          console.log(response);
          this.currentApps = response;
        })
        .catch((error) => {
          // 如果查不到该设备详情，跳回设备列表
          if (JSON.stringify(error).includes("status code 518")) {
            setTimeout(() => {
              this.$router.push({
                path: "/iot/device/list",
              });
            }, 3000);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.device-detail-tabs {
  padding: 0;
}
/* ::v-deep .el-tabs {
  border: none;
  box-shadow: none;
}
::v-deep .el-tabs__nav-scroll {
  border: 1px solid #dcdfe6;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
} */
</style>