<template>
  <div class="gateway-terminal-component">
    <div class="filter-line">
      <el-form :inline="true" :model="sshForm" :rules="rules" ref="sshForm">
        <span>
          <el-form-item prop="ip">
            <el-input
              v-model="sshForm.ip"
              placeholder="请输入IP地址"
            ></el-input>
          </el-form-item>
          <el-form-item prop="port">
            <el-input v-model="sshForm.port" placeholder="端口"></el-input>
          </el-form-item>
          <el-form-item prop="username">
            <el-input
              v-model="sshForm.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="sshForm.password"
              placeholder="请输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              style="margin-left: 10px"
              type="primary"
              @click="connect()"
              >连接</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm('sshForm')">重置</el-button>
          </el-form-item>
        </span>
      </el-form>
    </div>
    <div class="table-container">
      <div id="terminal" style="margin-top: 10px"></div>
    </div>
  </div>
</template>

<script>
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import "xterm/lib/xterm.js";
import { callDeviceService } from "@/api/ruge/vlink/device/device";
export default {
  data() {
    return {
      sshForm: {
        ip: null,
        port: null,
        username: null,
        password: null,
      },
      rules: {
        ip: [{ required: true, message: "请输入IP地址", trigger: "blur" }],
        port: [{ required: true, message: "请输入端口号", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  methods: {
    connect() {
      this.$refs["sshForm"].validate((valid) => {
        if (valid) {
          let callServiceParam = {
            productKey: this.currentRow.productKey,
            deviceName: this.currentRow.deviceName,
            moduleName: "systemInfo",
            serviceName: "webssh",
            inputData: {},
            callType: "sync",
          };
          callDeviceService(callServiceParam).then((data) => {
            if (data.code == 200) {
              let response = data.outputData.data;
              const terminal = new Terminal({
                cols: 97,
                rows: 37,
                cursorBlink: true, // 光标闪烁
                cursorStyle: "block", // 光标样式  null | 'block' | 'underline' | 'bar'
                scrollback: 800, //回滚
                tabStopWidth: 8, //制表宽度
                screenKeys: true,
              });
              const fitAddon = new FitAddon();
              terminal.loadAddon(fitAddon);
              fitAddon.fit();
              let terminalContainer = document.getElementById("terminal");
              let wsUrl = response;
              console.log(this.wsUrl);
              const webSocket = new WebSocket(wsUrl);

              webSocket.onmessage = (event) => {
                terminal.write(event.data);
              };

              webSocket.onopen = () => {
                terminal.open(terminalContainer);
                fitAddon.fit();
                terminal.write("welcome to WebSSH ☺\r\n");
                terminal.focus();
                let options = {
                  operate: "connect",
                  host: this.sshForm.ip, //IP
                  port: this.sshForm.port, //端口号
                  username: this.sshForm.username, //用户名
                  password: this.sshForm.password, //密码
                };
                webSocket.send(JSON.stringify(options));
              };

              webSocket.onclose = () => {
                terminal.write("\r\nWebSSH quit!");
              };

              webSocket.onerror = (event) => {
                console.error(event);
                webSocket.close();
              };

              terminal.onKey((event) => {
                console.log(event.key);
                webSocket.send(
                  JSON.stringify({ operate: "command", command: event.key })
                );
              });
            } else {
              //成功提示
              this.$message({
                message: data.message,
                type: "warning",
              });
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="less">
.gateway-terminal-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>