<template>
  <div class="ota-component">
    <div class="filter-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 查看升级记录对话框 -->
    <el-dialog
      :visible.sync="ota.outerVisible"
      :before-close="cancelOuterOta"
      width="70%"
      height="100%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ $t("vlink.device.otaTask") }}
      </template>
      <el-table
        :data="ota.task.list"
        element-loading-text="loading"
        fit
        highlight-current-row
        stripe
        @expand-change="expandChange"
        @selection-change="selectedOtaChange"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.logRow"
              element-loading-text="loading"
              fit
              highlight-current-row
              stripe
              @selection-change="selectedOtaChange"
            >
              <el-table-column
                :label="$t('vlink.device.otaTaskId')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.otaTaskId }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('vlink.device.otaTaskProgress')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.progress }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('vlink.device.otaTaskContent')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.content }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('vlink.device.otaTaskReportTime')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.reportTime }}</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('vlink.device.otaTaskId')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.taskId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('vlink.product.productKey')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.productKey }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('vlink.device.deviceName')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.deviceName }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('vlink.product.programmingLanguage')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.programmingLanguage }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('vlink.product.firmwareVersion')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.version }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('vlink.product.OTADescription')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('vlink.device.otaTaskDate')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.taskDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="ota.task.total > 0"
        :total="ota.task.total"
        :page.sync="ota.task.listQuery.current"
        :limit.sync="ota.task.listQuery.rowCount"
        @pagination="findOtaTaskPage"
      />
      <div slot="footer">
        <el-button @click="cancelOuterOta">{{
          $t("commons.cancel")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";

import { findOtaPage } from "@/api/ruge/vlink/product/product";
import Pagination from "@/components/Pagination";
import {
  findOtaTaskPage,
  findOtaTaskLogList,
} from "@/api/ruge/vlink/device/device";
// import Upload from "@/components/Upload";
import { envInfo } from "@/constants/envInfo";
export default {
  components: {
    // Upload,
    Pagination,
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "programmingLanguage",
            label: this.$t("vlink.product.programmingLanguage"),
            width: "",
          },
          {
            prop: "version",
            label: this.$t("vlink.product.firmwareVersion"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.product.OTADescription"),
            width: "",
          },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          programmingLanguage: {
            type: "input",
            label: "编程语言",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入编程语言",
            prefixIcon: "el-icon-search",
          },
          version: {
            type: "input",
            label: "固件版本",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入固件版本",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "toTop",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "iconfont icon-zhiding",
                tooltips: "升级",
              },
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          programmingLanguage: {
            inline: true,
            value: "",
          },
          version: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      // 上传
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload",
      uploadModel: false,
      rootPath: envInfo.bgApp.archivePath,

      show: false,

      openDelay: 1000,
      ota: {
        title: null,
        total: 0,
        list: null,
        task: {
          total: 0,
          currentProductKey: null,
          currentVersion: null,
          currentProgrammingLanguage: null,
          listQuery: {
            current: 1,
            rowCount: 10,
            taskId: this.currentRow.taskId,
          },
          list: {
            productKey: null,
            deviceName: null,
            programmingLanguage: null,
            version: null,
            description: null,
            taskDate: null,
          },
          log: {
            list: {
              taskId: null,
              progress: null,
              content: null,
              reportTime: null,
            },
            total: 0,
          },
        },
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          otaId: null,
          productKey: this.currentRow.productKey,
          version: null,
          programmingLanguage: null,
          fileId: null,
          status: "ENABLE",
          description: null,
          otaIndex: null,
        },
        multipleSelection: [],
        outerVisible: false,
        innerVisible: false,
        createLoading: false,
        form: {
          otaId: null,
          productKey: this.currentRow.productKey,
          version: null,
          programmingLanguage: null,
          fileId: null,
          status: null,
          description: null,
          otaIndex: null,
        },
        formRules: {},
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.findOtaPage();
  },
  watch: {
    ota: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  computed: {
    otaId() {
      return this.currentRow.otaId;
    },
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findOtaPage();
    },
    expandChange(row, flag) {
      console.log("row", row);
      console.log("flag", flag);
      this.findOtaTaskLogList(row);
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.ota.listQuery = { ...this.ota.listQuery, ...datas.params };
        this.dataset.pageVO.current = 1;
        this.findOtaPage();
      } else if (datas.type === "paginationChange") {
        this.ota.listQuery.current = datas.params.current.page;
        this.ota.listQuery.rowCount = datas.params.current.limit;
        this.findOtaPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewTaskDialog(datas.row);
            break;
          case "toTop":
            this.levelUp(datas.row);
            break;
        }
      }
    },
    uploadSuccess(response) {
      //this.uploadModel = false
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
      this.ota.form.fileId = response[0].fileId;
      this.findOtaPage();
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    findOtaPage() {
      let param = this.ota.listQuery;
      this.ota.task.listLoading = true;
      findOtaPage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows;
          this.ota.listLoading = false;
        })
        .catch((err) => {
          this.ota.listLoading = false;
        });
    },
    viewTaskDialog(row) {
      this.ota.task.currentProductKey = row.productKey;
      this.ota.task.programmingLanguage = row.programmingLanguage;
      this.ota.task.version = row.version;
      this.findOtaTaskPage().then(() => {
        this.ota.outerVisible = true;
      });
    },
    findOtaTaskPage() {
      this.ota.task.listQuery.productKey = this.ota.task.currentProductKey;
      this.ota.task.listQuery.programmingLanguage =
        this.ota.task.programmingLanguage;
      this.ota.task.listQuery.version = this.ota.task.version;
      let param = this.ota.task.listQuery;
      return findOtaTaskPage(param).then((response) => {
        this.ota.task.total = response.total;
        this.ota.task.list = response.rows;
        this.ota.task.list.forEach((item) => {
          item.logRow = [];
        });
      });
    },
    findOtaTaskLogList(row) {
      findOtaTaskLogList(row.taskId).then((response) => {
        // this.ota.task.log.list = response;
        this.ota.task.list.forEach((item) => {
          if (item.taskId === row.taskId) {
            item.logRow = response;
          }
        });
        // this.ota.innerVisible = true;
      });
    },
    selectedOtaChange(data) {
      let otaIds = [];
      data.forEach(function (obj) {
        otaIds.push(obj.otaId);
      });
      this.ota.multipleSelection = otaIds;
    },

    editOta(row) {
      this.ota.title = this.$t("vlink.product.OTADetail");
      this.ota.form.otaId = row.otaId;
      this.ota.form.productKey = row.productKey;
      this.ota.form.version = row.version;
      this.ota.form.programmingLanguage = row.programmingLanguage;
      this.ota.form.fileId = row.fileId;
      this.ota.form.status = row.status;
      this.ota.form.description = row.description;
      this.ota.form.otaIndex = row.otaIndex;
      this.ota.outerVisible = true;
    },
    otaTaskDetail() {
      this.innerVisible = true;
    },
    //升级
    levelUp(row) {
      this.$message.info("功能建设中...");
      // row.deviceId = this.currentRow.deviceId;
      // row.deviceName = this.currentRow.deviceName;
      // row.nodeType = this.currentRow.nodeType;
      // deviceLevelUp(row)
      //   .then(() => {
      //     // 成功提示
      //     this.$message({
      //       type: "success",
      //       message: this.$t("message.saveSuccess"),
      //     });
      //     // 刷新列表
      //     this.getOtaPage();
      //   })
      //   .catch((error) => {
      //     // 更新失败还原顺序
      //     this.ota.listLoading = false;
      //     console.log(`更改顺序失败，原因 => ${error}`);
      //   });
    },
    // 取消外层保存
    cancelOuterOta() {
      this.ota.outerVisible = false;
    },
    // 取消内层保存
    cancelInnerOta() {
      this.ota.innerVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.ota-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>