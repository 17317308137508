var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "bacnetForm",
          attrs: {
            "label-position": "top",
            rules: _vm.bacnetRule,
            model: _vm.bacnetForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.daqIntvl"),
                        prop: "daqIntvl",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.bacnetForm.daqIntvl,
                          callback: function ($$v) {
                            _vm.$set(_vm.bacnetForm, "daqIntvl", $$v)
                          },
                          expression: "bacnetForm.daqIntvl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.device.deviceId"),
                        prop: "deviceId",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "20",
                          "show-word-limit": true,
                          clearable: "",
                        },
                        model: {
                          value: _vm.bacnetForm.deviceId,
                          callback: function ($$v) {
                            _vm.$set(_vm.bacnetForm, "deviceId", $$v)
                          },
                          expression: "bacnetForm.deviceId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.device.communicationMode"),
                        prop: "mode",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.editable },
                          model: {
                            value: _vm.bacnetForm.mode,
                            callback: function ($$v) {
                              _vm.$set(_vm.bacnetForm, "mode", $$v)
                            },
                            expression: "bacnetForm.mode",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "1" } },
                            [_vm._v(_vm._s(_vm.$t("vlink.device.unOrient")))]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "2" } },
                            [_vm._v(_vm._s(_vm.$t("vlink.device.orient")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.bacnetForm.mode == 2
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("iot.protocol.host"),
                              prop: "ip",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: !_vm.editable,
                                maxlength: "20",
                                "show-word-limit": true,
                                clearable: "",
                              },
                              model: {
                                value: _vm.bacnetForm.ip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bacnetForm, "ip", $$v)
                                },
                                expression: "bacnetForm.ip",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("iot.protocol.port"),
                              prop: "port",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: !_vm.editable,
                                type: "number",
                                min: "0",
                                "show-word-limit": true,
                                clearable: "",
                              },
                              model: {
                                value: _vm.bacnetForm.port,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bacnetForm, "port", $$v)
                                },
                                expression: "bacnetForm.port",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("vlink.device.maxApdu"),
                              prop: "maxApdu",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: !_vm.editable,
                                type: "number",
                                min: "0",
                                "show-word-limit": true,
                                clearable: "",
                              },
                              model: {
                                value: _vm.bacnetForm.maxApdu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bacnetForm, "maxApdu", $$v)
                                },
                                expression: "bacnetForm.maxApdu",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.customize"),
                        prop: "customize",
                      },
                    },
                    [
                      _c("ace-editor", {
                        key: "customizeJson",
                        staticStyle: { border: "1px solid #ebecec" },
                        attrs: { lang: "json", theme: "chrome", height: "320" },
                        on: { init: _vm.editorInit },
                        model: {
                          value: _vm.bacnetForm.customize,
                          callback: function ($$v) {
                            _vm.$set(_vm.bacnetForm, "customize", $$v)
                          },
                          expression: "bacnetForm.customize",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }