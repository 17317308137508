<template>
    <div class="app-container sub-device-component">
        <div class="filter-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="findAppPage"
        >刷新</el-button
        >
      </span>
        </div>
        <div>
            <finalTable
                    ref="finalTableRef"
                    :datas="dataset"
                    @tableEventHandler="tableEventHandler"
            />
        </div>

        <!-- 绑定应用对话框 -->
        <el-dialog
                :visible.sync="detail.detailDialogVisible"
                :before-close="cancelAppBind"
                width="1024px"
                :close-on-click-modal="false"
        >
            <div>
                <finalTable
                        ref="finalTableRef"
                        :datas="detail.dataset"
                        @tableEventHandler="detailEventHandler"
                >

                </finalTable>
            </div>
            <div slot="footer">
                <el-button @click="cancelAppBind">{{
                    $t("commons.close")
                    }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
    findApp,
    deleteApp,
    createApp,
    editApp, publishAppImage, findAppImage, bindApp2Edge
} from "@/api/ruge/vlink/app/app";

export default {
    name: "appBind",
    components: {
        finalTable,
    },
    data() {
        return {
            currentState: null,
            options: [
                {
                    typeValue: "private",
                    label: "私有",
                },
                {
                    typeValue: "authority",
                    label: "官方",
                },
            ],
            appTypeOptions: [
                {
                    Value: "协议驱动",
                    label: "协议驱动",
                },
                {
                    Value: "边缘应用",
                    label: "边缘应用",
                },
            ],
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: false,
                },
                header: [
                    {
                        prop: "appDriverName",
                        label: this.$t("vlink.app.appName"),
                        width: "",
                    },
                    {
                        prop: "description",
                        label: this.$t("vlink.tsl.description"),
                        width: "",
                    },
                    {
                        prop: "appLanguage",
                        label: this.$t("vlink.app.language"),
                        width: "",
                    },
                    {
                        prop: "owner",
                        label: this.$t("vlink.device.protocolType"),
                        width: "",
                    },
                    {
                        prop: "version",
                        label: this.$t("vlink.log.version"),
                        width: "",
                    },
                    {
                        prop: "appType",
                        label: this.$t("vlink.app.appType"),
                        width: "",
                    },
                    {
                        prop: "operation",
                        label: this.$t("commons.actions"),
                        width: "140",
                    },
                ],
                tableData: [],
                searchLineConfig: {},
                // 表格内容配置
                detailConfig: {
                    owner: {
                        type: "tags",
                        private: {
                            type: "private",
                            label: "私有",
                        },
                        authority: {
                            type: "authority",
                            label: "官方",
                        },
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "detail",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "详情",
                            },
                            // {
                            //     actionType: "iconClick",
                            //     eventName: "edit",
                            //     fontSize: "14px",
                            //     color: "#1A4CEC",
                            //     cursorPointer: "pointer",
                            //     iconName: "el-icon-edit",
                            //     tooltips: "编辑",
                            // },
                            // {
                            //     actionType: "iconClick",
                            //     eventName: "delete",
                            //     fontSize: "14px",
                            //     color: "#E47470",
                            //     cursorPointer: "pointer",
                            //     iconName: "el-icon-delete",
                            //     tooltips: "删除",
                            // },
                        ],
                    },
                },
                advanceFilterConfig: {
                    appDriverName: {
                        inline: true,
                        value: "",
                    },
                    description: {
                        inline: true,
                        value: "",
                    },
                    app: {
                        inline: true,
                        value: "",
                    },
                    version: {
                        inline: true,
                        value: "",
                    },
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            driver: {
                title: null,
                total: 0,
                list: null,
                listLoading: false,
                addDialogVisible: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    tenantId: null,
                },
                form: {
                    appId: null,
                    owner: null,
                    appType: null,
                    appDriverName: null,
                    appLanguage: null,
                    version: null,
                    description: null,
                },
                formRules: {
                    appDriverName: [
                        {
                            required: true,
                            trigger: ["blur", "change"],
                            message: this.$t("validate.required"),
                        },
                    ],
                    owner: [
                        {
                            required: true,
                            trigger: ["blur", "change"],
                            message: this.$t("validate.required"),
                        },
                    ],
                    appType: [
                        {
                            required: true,
                            trigger: ["blur", "change"],
                            message: this.$t("validate.required"),
                        },
                    ],
                },
            },
            detail: {
                loadingFlag: false,
                detailDialogVisible: false,
                dataset: {
                    paginationConfig: {
                        need: true,
                    },
                    filterLine: {
                        show: false,
                    },
                    header: [
                        {
                            prop: "imageName",
                            label: this.$t("vlink.app.imageName"),
                            width: "",
                        },
                        {
                            prop: "description",
                            label: this.$t("vlink.tsl.description"),
                            width: "",
                        },
                        {
                            prop: "tagName",
                            label: this.$t("vlink.app.tagName"),
                            width: "",
                        },
                        {
                            prop: "extendConfig",
                            label: this.$t("vlink.app.extendConfig"),
                            width: "",
                        },
                        {
                            prop: "jarName",
                            label: this.$t("vlink.app.jarName"),
                            width: "",
                        },
                        {
                            prop: "publishStatus",
                            label: this.$t("vlink.app.publishStatus"),
                            width: "",
                        },
                        {
                            prop: "bindStatus",
                            label: this.$t("vlink.app.bindStatus"),
                            width: "",
                        },
                        {
                            prop: "operation",
                            label: this.$t("commons.actions"),
                            width: "",
                        },
                    ],
                    tableData: [],
                    searchLineConfig: {},
                    // 表格内容配置
                    detailConfig: {
                        operation: {
                            type: "icon",
                            iconList: [
                                {
                                    actionType: "iconClick",
                                    eventName: "bindEdge",
                                    fontSize: "14px",
                                    color: "#1A4CEC",
                                    cursorPointer: "pointer",
                                    iconName: "el-icon-edit",
                                    tooltips: "绑定",
                                    hideProp: "publishStatus",
                                    hideValue: "N",
                                },
                            ],
                        },
                        publishStatus: {
                            type: "enumerationColumn",
                            emuList: {
                                Y: "已发布",
                                N: "未发布",
                            },
                        },
                        bindStatus: {
                            type: "enumerationColumn",
                            emuList: {
                                Y: "已绑定",
                                N: "未绑定",
                            },
                        },
                    },
                    advanceFilterConfig: {},
                    pageVO: {
                        current: 1,
                        rowCount: 10,
                        total: 0,
                    },
                },
                driver: {
                    title: null,
                    total: 0,
                    list: null,
                    listLoading: false,
                    addDialogVisible: false,
                    listQuery: {
                        current: 1,
                        rowCount: 10,
                        tenantId: null,
                        appId: null,
                        appDriverName: null,
                    },
                    form: {
                        appImageId: null,
                        appId: null,
                        fileId: null,
                        fileSize: null,
                        jarName: null,
                        imageName: null,
                        tagName: null,
                        extendConfig: null,
                        description: null,
                    },
                    formRules: {
                        imageName: [
                            {
                                required: true,
                                trigger: ["blur", "change"],
                                message: this.$t("validate.required"),
                            },
                        ],
                        tagName: [
                            {
                                required: true,
                                trigger: ["blur", "change"],
                                message: this.$t("validate.required"),
                            },
                        ],
                        jarName: [
                            {
                                required: true,
                                trigger: ["blur", "change"],
                                message: this.$t("validate.required"),
                            },
                        ],
                    },
                },
            },
        };
    },
    props: {
        currentRow: {
            type: Object,
            required: true
        }
    },
    created() {
        this.findAppPage();
    },
    methods: {
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.findAppPage();
            } else if (datas.type === "paginationChange") {
                this.driver.listQuery.current = datas.params.current.page;
                this.driver.listQuery.rowCount = datas.params.current.limit;
                this.findApp();
            } else if (datas.type === "updateSelectionList") {
                this.device.multipleSelection = datas.list;
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "edit":
                        this.editApp(datas.row);
                        break;
                    case "delete":
                        this.$confirm(
                            this.$t("message.deleteConfirm"),
                            this.$t("commons.warning"),
                            {
                                confirmButtonText: this.$t("commons.confirm"),
                                cancelButtonText: this.$t("commons.cancel"),
                                type: "warning",
                            }
                        )
                            .then(() => {
                                this.deleteApp(datas.row.appId);
                            })
                            .catch((error) => {
                                console.log(`未删除，原因 => ${error}`);
                            });
                        break;
                    case "detail":
                        this.goDetailHandler(datas.row);
                        break;
                }
            }
        },
        detailEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.findAppImagePage();
            } else if (datas.type === "paginationChange") {
                this.detail.driver.listQuery.current = datas.params.current.page;
                this.detail.driver.listQuery.rowCount = datas.params.current.limit;
                this.findAppImage();
            } else if (datas.type === "updateSelectionList") {
                this.detail.device.multipleSelection = datas.list;
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "bindEdge":
                        console.log();
                        this.loadingFlag = true;
                        const extraParams = {
                            productKey: this.currentRow.productKey,
                            deviceName: this.currentRow.deviceName,
                        };
                        // 合并网关的productKey和deviceName
                        const mergedParams = { ...datas.row, ...extraParams };
                        bindApp2Edge(mergedParams)
                            .then(() => {
                                // 成功提示
                                this.$message({
                                    message: "绑定成功",
                                    type: "success",
                                });
                                this.findAppImage();
                            })
                            .catch((error) => {
                                console.log(`绑定失败，原因 => ${error}`);
                            })
                            .finally(() => {
                                this.loadingFlag = false;
                            });
                        break;
                }
            }
        },
        goDetailHandler(row) {
            let appId = row.appId;
            let appDriverName = row.appDriverName;
            this.detail.driver.listQuery.appId = appId;
            this.detail.driver.listQuery.appDriverName = appDriverName;
            this.detail.detailDialogVisible = true;
            this.findAppImagePage();
        },
        // 取消保存
        cancelDeviceSave() {
            this.driver.addDialogVisible = false;
        },
        // 关闭镜像详情页
        cancelAppBind() {
            this.detail.detailDialogVisible = false;
        },
        createApp() {
            this.currentState = "add";
            this.driver.title = this.$t("vlink.app.addApp");
            this.driver.form = {
                appDriverName: null,
                driverLanguage: null,
                version: null,
                description: null,
                owner: null,
                appType: null,
            };
            this.driver.addDialogVisible = true;
        },

        handleDeviceSave() {
            this.$refs.deviceForm.validate((v) => {
                if (v) {
                    if (this.driver.form.appId) {
                        editApp(this.driver.form)
                            .then(() => {
                                // 成功提示
                                this.$message({
                                    message: this.$t("message.saveSuccess"),
                                    type: "success",
                                });
                                this.cancelDeviceSave();
                                this.findAppPage();
                            })
                            .catch((error) => {
                                console.log(`保存失败，原因 => ${error}`);
                            });
                    } else {
                        createApp(this.driver.form)
                            .then(() => {
                                // 成功提示
                                this.$message({
                                    message: this.$t("message.saveSuccess"),
                                    type: "success",
                                });
                                this.cancelDeviceSave();
                                this.findAppPage();
                            })
                            .catch((error) => {
                                console.log(`保存失败，原因 => ${error}`);
                            });
                    }
                }
            });
        },
        findAppPage() {
            this.driver.listQuery.current = 1;
            this.dataset.pageVO.current = 1;
            this.findApp();
        },
        findApp() {
            let param = this.driver.listQuery;
            findApp(param)
                .then((res) => {
                    this.dataset.tableData = res.rows;
                    this.dataset.pageVO.total = res.total;
                })
                .catch((err) => {
                    console.log("错误信息：", err);
                });
        },
        // 删除
        deleteApp(appId) {
            deleteApp({appId: appId})
                .then(() => {
                    // 成功提示
                    this.$message({
                        message: this.$t("message.deleteSuccess"),
                        type: "success",
                    });
                    this.findAppPage();
                })
                .catch((error) => {
                    console.log(`未删除，原因 => ${error}`);
                });
        },
        // 修改
        editApp(row) {
            this.currentState = "edit";
            this.driver.title = this.$t("vlink.app.editApp");
            this.driver.form.owner = row.owner;
            this.driver.form.appDriverName = row.appDriverName;
            this.driver.form.appType = row.appType;
            this.driver.form.description = row.description;
            this.driver.form.appLanguage = row.appLanguage;
            this.driver.form.version = row.version;
            this.driver.form.appId = row.appId;
            this.driver.addDialogVisible = true;
        },
        findAppImagePage() {
            this.detail.driver.listQuery.current = 1;
            this.detail.dataset.pageVO.current = 1;
            this.findAppImage();
        },
        findAppImage() {
            this.detail.loadingFlag = true;
            let param = this.detail.driver.listQuery;
            findAppImage(param)
                .then((res) => {
                    this.detail.dataset.tableData = res.rows;
                    this.detail.dataset.pageVO.total = res.total;
                })
                .catch((err) => {
                    console.log("错误信息：", err);
                })
                .finally(() => {
                    this.detail.loadingFlag = false;
                });
        },
    },
};
</script>
<style scoped lang="less">
.sub-device-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
    }

    .icon-loudoutu {
      font-size: 12px;
    }

    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }

    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }

    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>