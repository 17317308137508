<template>
  <div class="gateway-rule-component">
    <div class="filter-line">
      <span>
        <el-select
          v-model="bindRuleKey"
          clearable
          filterable
          placeholder="请选择规则"
        >
          <el-option
            v-for="item in ruleList"
            :key="item.ruleKey"
            :label="item.ruleName"
            :value="item.ruleKey"
          >
            <span style="float: left">{{ item.ruleName }}</span>
            <span
              v-if="item.ruleType == 'linkage'"
              style="float: right; color: #8492a6; font-size: 13px"
              >联动规则</span
            >
            <span
              v-if="item.ruleType == 'alarm'"
              style="float: right; color: #8492a6; font-size: 13px"
              >告警规则</span
            >
            <span
              v-if="item.ruleType == 'timer'"
              style="float: right; color: #8492a6; font-size: 13px"
              >时间表</span
            >
            <span
              v-if="item.ruleType == 'deviceCall'"
              style="float: right; color: #8492a6; font-size: 13px"
              >设备通信</span
            >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 10px"
          @click="bindEdgeRule()"
          >绑定规则</el-button
        >
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
  </div>
</template>

<script>
import {
  findEdgeRulePage,
  updateEdgeRuleStatus,
  findEdgeRuleList,
  bindEdgeRule,
  unbindEdgeRule,
} from "@/api/ruge/vlink/rule/rule";
import finalTable from "@/components/FinalTable";
import moment from "moment";
export default {
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "ruleKey",
            label: this.$t("vlink.rule.ruleKey"),
            width: "",
          },
          {
            prop: "ruleName",
            label: this.$t("vlink.rule.ruleName"),
            width: "",
          },
          {
            prop: "ruleScope",
            label: this.$t("vlink.rule.ruleScope"),
            width: "",
          },
          {
            prop: "ruleType",
            label: this.$t("vlink.rule.ruleType"),
            width: "",
          },
          {
            prop: "enableStatus",
            label: this.$t("vlink.rule.ruleStatus"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("commons.creationDate"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "115",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          ruleKey: {
            type: "input",
            label: "规则标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备标识码",
            prefixIcon: "el-icon-search",
          },
          ruleName: {
            type: "input",
            label: "规则名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则名称",
            prefixIcon: "el-icon-search",
          },
          ruleType: {
            type: "selector",
            label: "规则类型",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则类型",
            optionList: [
              {
                label: "联动规则",
                value: "linkage",
              },
              {
                label: "告警规则",
                value: "alarm",
              },
              {
                label: "时间表",
                value: "schedule",
              },
              {
                label: "设备通信",
                value: "deviceCall",
              },
            ],
          },
          enableStatus: {
            type: "selector",
            label: "规则状态",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则状态",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          ruleScope: {
            type: "enumerationColumn",
            emuList: {
              CLOUD: "云端",
              EDGE: "边缘",
            },
          },
          ruleType: {
            type: "enumerationColumn",
            emuList: {
              linkage: "联动规则",
              alarm: "告警规则",
              schedule: "时间表",
              deviceCall: "设备通信",
            },
          },
          enableStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "规则解绑",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          ruleKey: {
            inline: true,
            value: "",
          },
          ruleName: {
            inline: true,
            value: "",
          },
          ruleType: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      // 绑定的规则标识码
      bindRuleKey: null,
      // 边缘规则列表
      ruleList: [],
      openDelay: 1000,
      ruleDrawer: false,
      currentTopic: null,
      currentContent: null,
      rule: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          productKey: null,
          deviceName: null,
          ruleKey: null,
          ruleName: null,
          ruleType: null,
          enableStatus: null,
        },
      },
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentRow: {
      immediate: true,
      handler: function () {
        this.findEdgeRulePage();
        this.findEdgeRuleList();
      },
    },
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getEdgeRulePage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.rule.listQuery = { ...this.rule.listQuery, ...datas.params };
        this.findEdgeRulePage();
      } else if (datas.type === "paginationChange") {
        this.rule.listQuery.current = datas.params.current.page;
        this.rule.listQuery.rowCount = datas.params.current.limit;
        this.getEdgeRulePage();
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "delete":
            this.unbindEdgeRule(datas.row);
            break;
        }
      }
    },
    findEdgeRulePage() {
      this.rule.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.rule.listQuery.productKey = this.currentRow.productKey;
      this.rule.listQuery.deviceName = this.currentRow.deviceName;
      this.getEdgeRulePage();
    },
    getEdgeRulePage() {
      let param = this.rule.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.rule.listLoading = true;
      findEdgeRulePage(param).then((response) => {
        this.dataset.pageVO.total = response.total;
        this.dataset.tableData = response.rows;
        this.rule.listLoading = false;
      });
    },
    findEdgeRuleList() {
      findEdgeRuleList({ ruleScope: "EDGE" }).then((res) => {
        this.ruleList = res;
      });
    },
    updateEnableStatus(row) {
      let param = {
        productKey: this.currentRow.productKey,
        deviceName: this.currentRow.deviceName,
        ruleKey: row.ruleKey,
        enableStatus: row.enableStatus,
      };
      updateEdgeRuleStatus(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    // 规则绑定
    bindEdgeRule() {
      if (!this.bindRuleKey) {
        this.$message({
          message: "请选择规则",
          type: "info",
        });
      } else {
        let params = {
          ruleKey: this.bindRuleKey,
          productKey: this.currentRow.productKey,
          deviceName: this.currentRow.deviceName,
        };
        bindEdgeRule(params).then(() => {
          // 成功提示
          this.$message({
            message: "绑定成功",
            type: "success",
          });
          this.findEdgeRulePage();
        });
      }
    },
    // 规则解绑
    unbindEdgeRule(row) {
      this.$confirm("确定解绑该规则吗？", this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          let params = {
            ruleKey: row.ruleKey,
            productKey: this.currentRow.productKey,
            deviceName: this.currentRow.deviceName,
          };
          unbindEdgeRule(params).then(() => {
            // 成功提示
            this.$message({
              message: "解绑成功",
              type: "success",
            });
            this.findEdgeRulePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style scoped lang="less">
.gateway-rule-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }

    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>