var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "peak-valley-charts" }, [
    _c("div", { staticClass: "title-line" }, [_vm._v("平均变化趋势")]),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dataset.length,
          expression: "dataset.length",
        },
      ],
      attrs: { id: "peakVallyCharts" },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.dataset.length,
            expression: "!dataset.length",
          },
        ],
        staticClass: "no-data",
      },
      [_vm._v("暂无数据...")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }