var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ota-component" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.dataset.filterLine.show
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.dataset.filterLine.show
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.ota.outerVisible,
            "before-close": _vm.cancelOuterOta,
            width: "70%",
            height: "100%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ota, "outerVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vlink.device.otaTask")) + "\n    "
            ),
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.ota.task.list,
                "element-loading-text": "loading",
                fit: "",
                "highlight-current-row": "",
                stripe: "",
              },
              on: {
                "expand-change": _vm.expandChange,
                "selection-change": _vm.selectedOtaChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: props.logRow,
                              "element-loading-text": "loading",
                              fit: "",
                              "highlight-current-row": "",
                              stripe: "",
                            },
                            on: { "selection-change": _vm.selectedOtaChange },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("vlink.device.otaTaskId"),
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.otaTaskId)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("vlink.device.otaTaskProgress"),
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.progress)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("vlink.device.otaTaskContent"),
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.content)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("vlink.device.otaTaskReportTime"),
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.reportTime)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.device.otaTaskId"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.taskId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.product.productKey"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.productKey))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.device.deviceName"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.deviceName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.product.programmingLanguage"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.programmingLanguage)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.product.firmwareVersion"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.version))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.product.OTADescription"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.description))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("vlink.device.otaTaskDate"),
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.taskDate))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ota.task.total > 0,
                expression: "ota.task.total > 0",
              },
            ],
            attrs: {
              total: _vm.ota.task.total,
              page: _vm.ota.task.listQuery.current,
              limit: _vm.ota.task.listQuery.rowCount,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.ota.task.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.ota.task.listQuery, "rowCount", $event)
              },
              pagination: _vm.findOtaTaskPage,
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelOuterOta } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }