<template>
  <div class="service-history-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="serviceCalldialogVisible = true">
          {{ $t("vlink.tsl.serviceCall") }}</el-button
        >
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 显示消息内容对话框 -->
    <el-dialog :visible.sync="dialogVisible">
      <template slot="title"> 详情 </template>
      <div class="tabs-container">
        <div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="下发数据" name="first">
              <ace-editor
                :key="currentRow.productKey"
                v-model="reportContent"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="50vh"
              ></ace-editor>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="响应数据" name="first">
              <ace-editor
                :key="currentRow.productKey"
                v-model="responseContent"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="50vh"
              ></ace-editor>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div slot="footer">
        <el-button type="primary" @click="closeResponseContent">{{
          $t("commons.close")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 调用服务弹窗 -->
    <el-dialog :visible.sync="serviceCalldialogVisible" width="728 ">
      <template slot="title">
        {{ $t("vlink.tsl.serviceCall") }}
      </template>
      <div class="call-dialog-container">
        <el-form
          :model="form"
          label-position="top"
          v-loading="callServiceLoading"
        >
          <el-row :gutter="48">
            <el-col :span="12">
              <el-form-item label-width="80px" :label="$t('vlink.tsl.module')">
                <el-select v-model="form.moduleName" style="width: 100%">
                  <el-option
                    v-for="(item, index) in moduleList"
                    :key="index"
                    :value="item.moduleName"
                    :label="item.moduleName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" :label="$t('vlink.tsl.service')">
                <el-select v-model="form.serviceName" style="width: 100%">
                  <el-option
                    v-for="(d, i) of moduleServices"
                    :key="i"
                    :label="d.serviceName"
                    :value="d.serviceName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="48">
            <el-col
              :span="12"
              v-for="(k, i) of params"
              :key="i"
              :value="k.paramName"
            >
              <el-form-item label-width="80px" :label="k.paramName">
                <el-input
                  style="width: 100%"
                  v-model="form.inputData[k.paramName]"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label-width="80px"
                :label="$t('vlink.tsl.syncCall')"
              >
                <el-switch v-model="form.callType" active-color="#13ce66">
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="form.callType">
            <el-form-item :label="$t('vlink.tsl.responseData')">
              <ace-editor
                v-model="serviceResponseContent"
                @init="editorInit"
                lang="json"
                theme="chrome"
                style="border: 1px solid #ebecec"
                height="300"
              ></ace-editor>
            </el-form-item>
          </el-row>
        </el-form>
        <div class="dialog-buttons-container">
          <el-button @click="cancelForm">{{
            $t("vlink.scripting.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="callService()"
            :loading="callServiceLoading"
            >{{
              loading
                ? $t("vlink.tsl.executionServiceCall")
                : $t("vlink.tsl.serviceCall")
            }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  findServiceHistoryPage,
  findTsl,
  callDeviceService,
} from "@/api/ruge/vlink/device/device";

import AceEditor from "vue2-ace-editor";
import { createScript } from "@/api/ruge/vlink/product/product";

import finalTable from "@/components/FinalTable";
import moment from "moment";
export default {
  name: "ServiceHistory",
  components: {
    AceEditor,
    finalTable,
  },
  data() {
    return {
      activeName: "first",
      serviceCalldialogVisible: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          {
            prop: "requestId",
            label: this.$t("vlink.commons.requestId"),
            width: "",
          },
          {
            prop: "moduleName",
            label: this.$t("vlink.tsl.moduleName"),
            width: "",
          },
          {
            prop: "serviceName",
            label: this.$t("vlink.tsl.serviceName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "callType",
            label: this.$t("vlink.tsl.callType"),
            width: "",
          },
          {
            prop: "requestTime",
            label: this.$t("vlink.tsl.requestTime"),
            width: "",
          },
          {
            prop: "responseTime",
            label: this.$t("vlink.tsl.responseTime"),
            width: "",
          },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          requestId: {
            type: "input",
            label: "请求ID",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入请求ID",
            prefixIcon: "el-icon-search",
          },
          moduleName: {
            type: "input",
            label: "模块名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入模块名称",
            prefixIcon: "el-icon-search",
          },
          serviceName: {
            type: "input",
            label: "服务名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入服务名称",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "描述",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入描述",
            prefixIcon: "el-icon-search",
          },
          callType: {
            type: "selector",
            label: "调用方式",
            value: "",
            placeholder: "请选择调用方式",
            actionType: "goSearch",
            optionList: [
              {
                label: "异步",
                value: "async",
              },
              {
                label: "同步",
                value: "sync",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          requestTime: {
            type: "dateFormat",
          },
          callType: {
            type: "enumerationColumn",
            emuList: {
              sync: "同步",
              async: "异步",
            },
          },
          responseTime: {
            type: "dateFormat",
            addSpan: true,
            minus: "responseTime",
            minuend: "requestTime",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          requestId: {
            inline: true,
            value: "",
          },
          moduleName: {
            inline: true,
            value: "",
          },
          eventName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          type: {
            inline: true,
            value: "",
          },
          reportTime: {
            type: "dateRange",
            label: "下发时间",
            // value: [new Date(moment().subtract(7, "days")).getTime(), null],
            value: [null, null],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      // 对话框是否可见，默认不可见
      dialogVisible: false,
      // 要显示的响应内容
      responseContent: undefined,
      reportContent: undefined,
      serviceResponseContent: "",
      openDelay: 1000,
      service: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          productKey: null,
          deviceName: null,
          requestId: null,
          moduleName: null,
          serviceName: null,
          callType: null,
          // startTime: new Date(moment().subtract(7, "days")).getTime(),
          startTime: null,
          endTime: null,
        },
      },
      loading: false,
      tsl: {},
      moduleList: [],
      form: {
        productKey: "",
        deviceName: "",
        moduleName: "",
        serviceName: "",
        inputData: {},
        callType: false,
      },
      callServiceLoading: false,
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productKey() {
      return this.currentRow.productKey;
    },
    moduleServices() {
      const moduleName = this.form.moduleName;
      if (!moduleName) {
        return [];
      }
      const module = this.moduleList.find((i) => i.moduleName === moduleName);
      return module ? module.services : [];
    },
    params() {
      const service = this.form.serviceName;
      if (!service) {
        return [];
      }
      let module = this.moduleList.find(
        (i) => i.moduleName === this.form.moduleName
      );
      let arr = [];
      const _self = this;
      // 先循环module.services，判断里面的serviceName是否等于service
      module.services.forEach((i) => {
        if (i.serviceName === service) {
          arr = i.inputData;
          arr.forEach((item) => {
            _self.$set(_self.form.inputData, item.paramName, "");
          });
        }
      });
      return arr;
    },
  },
  watch: {
    service: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
    productKey: {
      immediate: true,
      handler: function () {
        this.findServiceHistoryPage();
      },
    },
    serviceCalldialogVisible(val) {
      if (val) {
        this.findTsl();
      }
    },
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findServiceHistoryPage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.reportTime && datas.params.reportTime.length > 0) {
          this.service.listQuery.startTime = new Date(
            datas.params.reportTime[0]
          ).getTime();
          this.service.listQuery.endTime = new Date(
            datas.params.reportTime[1]
          ).getTime();
          delete datas.params.reportTime;
        } else {
          this.service.listQuery.endTime = "";
          this.service.listQuery.startTime = "";
        }
        this.service.listQuery = { ...this.service.listQuery, ...datas.params };
        this.findServiceHistoryPage();
      } else if (datas.type === "paginationChange") {
        this.service.listQuery.current = datas.params.current.page;
        this.service.listQuery.rowCount = datas.params.current.limit;
        this.getServiceHistoryPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.showResponseContent(datas.row);
            break;
        }
      }
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    // 查看消息内容
    showResponseContent(row) {
      this.dialogVisible = true;
      this.reportContent = row.inputData
        ? JSON.stringify(JSON.parse(row.inputData.trim()), null, "\t")
        : "";
      this.responseContent = row.outputData
        ? JSON.stringify(JSON.parse(row.outputData.trim()), null, "\t")
        : "";
    },
    //关闭模态框
    closeResponseContent() {
      this.dialogVisible = false;
    },
    findServiceHistoryPage() {
      this.service.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.service.listQuery.productKey = this.currentRow.productKey;
      this.service.listQuery.deviceName = this.currentRow.deviceName;
      this.getServiceHistoryPage();
    },
    getServiceHistoryPage() {
      let param = this.service.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.service.listLoading = true;
      findServiceHistoryPage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows;
          this.service.listLoading = false;
        })
        .catch((err) => {
          console.log("获取服务列表失败：", err);
          this.service.listLoading = false;
        });
    },
    startPicker(time) {
      this.service.listQuery.startTime = time.getTime();
    },
    endPicker(time) {
      this.service.listQuery.endTime = time.getTime();
    },
    drawerClosed() {
      (this.form.moduleName = ""),
        (this.form.serviceName = ""),
        (this.form.inputData = {});
    },
    callService() {
      // if (this.loading) {
      //   return;
      // }
      const params = { ...this.form };
      params.callType = params.callType ? "sync" : "async";
      this.callServiceLoading = true;
      callDeviceService(params)
        .then((data) => {
          console.log("data", data);
          // 成功提示
          if (data.code == 200) {
            this.$message({
              message: this.$t("vlink.tsl.callServiceSuccess"),
              type: "success",
            });
            if (this.form.callType) {
              this.serviceResponseContent = data.outputData
                ? JSON.stringify(data.outputData, null, "\t")
                : "";
            }
          } else {
            this.$message({
              message:
                this.$t("vlink.tsl.callServiceFail") + ":" + data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(`调用服务失败，原因 => ${error}`);
        })
        .finally(() => {
          this.callServiceLoading = false;
        });
    },
    cancelForm() {
      this.loading = false;
      this.serviceCalldialogVisible = false;
    },
    async findTsl() {
      findTsl({ productKey: this.currentRow.productKey }).then((response) => {
        this.moduleList = [];
        this.tsl = response;
        this.form.productKey = this.currentRow.productKey;
        this.form.deviceName = this.currentRow.deviceName;
        if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
          this.tsl.modules.forEach((module) => {
            this.moduleList.push(module);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.service-history-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .tabs-container {
    display: flex;
    & > div {
      flex: 1;
    }
    & > div + div {
      margin-left: 20px;
    }
  }
  .call-dialog-container {
    .dialog-buttons-container {
      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>