<template>
  <div>
    <el-form
      label-position="top"
      ref="customizeForm"
      :rules="customizeRule"
      :model="customizeForm"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="customizeForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="配置扩展" prop="extendConfig">
            <ace-editor
              key="extendConfig"
              v-model="customizeForm.extendConfig"
              @init="editorInit"
              lang="json"
              theme="chrome"
              style="border: 1px solid #ebecec"
              height="320"
            ></ace-editor>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveChannel">{{
            $t("commons.save")
          }}</el-button>
          <el-button type="success" @click="formatJson">{{
            $t("vlink.commons.formatJson")
          }}</el-button>
          <el-button @click="cancelEditChannel(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  components: { AceEditor },
  props: {
    channel: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    channel: {
      immediate: true,
      deep: true,
      handler: function () {
        this.initChannel();
      },
    },
  },
  data() {
    return {
      customizeForm: {
        channelId: null,
        channelName: null,
        extendConfig: null,
        daqIntvl: 1000,
      },
      customizeRule: {
        channelName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        extendConfig: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  methods: {
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(!this.editable);
    },
    initChannel() {
      if (this.channel) {
        this.customizeForm.channelId = this.channel.channelId;
        this.customizeForm.channelName = this.channel.channelName;
        this.customizeForm.extendConfig = this.channel.extendConfig || "";
        if (this.channel.extendConfig) {
          let customize = JSON.parse(this.channel.extendConfig);
          this.customizeForm.daqIntvl = customize.daqIntvl;
        }
      }
    },
    cancelEditChannel(v) {
      this.$emit("cancelEditChannel", v);
    },
    saveChannel() {
      this.$refs.customizeForm.validate((v) => {
        if (v) {
          let param = this.buildParam();
          if (param) {
            // 回写数据
            this.cancelEditChannel(param);
          }
        }
      });
    },
    buildParam() {
      if (this.checkJsonFormat()) {
        let param = {
          channelId: this.customizeForm.channelId,
          channelName: this.customizeForm.channelName,
          extendConfig: this.customizeForm.extendConfig,
        };
        param.extendConfig = JSON.stringify({
          ...JSON.parse(this.customizeForm.extendConfig),
          ...{
            daqIntvl: this.customizeForm.daqIntvl,
          },
        });
        return param;
      } else {
        this.$message({
          message: this.$t("iot.protocol.tips2"),
          type: "error",
        });
        return null;
      }
    },
    checkJsonFormat() {
      try {
        if (typeof JSON.parse(this.customizeForm.extendConfig) == "object") {
          return true;
        }
      } catch (e) {
        console.log(`extendConfig格式异常，原因 => ${e}`);
      }
      this.$message({
        message: this.$t("iot.protocol.tips2"),
        type: "warning",
      });
      return false;
    },
    formatJson() {
      if (
        this.customizeForm.extendConfig &&
        this.customizeForm.extendConfig.trim() !== "" &&
        this.checkJsonFormat()
      ) {
        this.customizeForm.extendConfig = JSON.stringify(
          JSON.parse(this.customizeForm.extendConfig.trim()),
          null,
          "\t"
        );
      }
    },
  },
};
</script>
