<template>
  <div>
    <el-form
      label-position="top"
      ref="opcuaForm"
      :rules="opcuaRule"
      :model="opcuaForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item
            :label="$t('iot.protocol.channelAddress')"
            prop="channelAddress"
          >
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.channelAddress"
              maxlength="100"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('iot.protocol.securityPolicy')"
            prop="securityPolicy"
          >
            <el-select
              :disabled="!editable"
              :style="{ width: '100%' }"
              @change="changeSecurityPolicy"
              v-model="opcuaForm.securityPolicy"
            >
              <el-option
                v-for="item in securityPolicyList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="48">
        <el-col :span="12" v-if="opcuaForm.securityPolicy !== 'none'">
          <el-form-item
            :label="$t('iot.protocol.securityMode')"
            prop="securityMode"
          >
            <el-select
              :disabled="!editable"
              :style="{ width: '100%' }"
              v-model="opcuaForm.securityMode"
            >
              <el-option
                v-for="item in securityModeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.timeout')" prop="timeout">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.timeout"
              type="number"
              max="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="opcuaForm.securityPolicy !== 'none'">
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item
              :label="$t('iot.protocol.securityCert')"
              prop="securityCert"
            >
              <el-upload
                class="upload-box"
                :on-success="uploadSecurityCertSuccess"
                :on-error="uploadFail"
                :show-file-list="false"
                :with-credentials="true"
                :accept="'.der, .pem'"
                :action="uploadPath"
              >
                <el-button
                  v-if="editable"
                  slot="trigger"
                  icon="el-icon-upload"
                  size="small"
                  type="primary"
                  >{{ $t("iot.protocol.selectFile") }}</el-button
                >
                <template v-if="opcuaForm.securityCert">
                  <el-button
                    style="margin-left: 10px"
                    icon="el-icon-download"
                    size="small"
                    type="success"
                    @click="downloadCert(opcuaForm.securityCert)"
                    >{{ $t("iot.protocol.downloadFile") }}</el-button
                  >
                </template>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.protocol.signCert')" prop="signCert">
              <el-upload
                class="upload-box"
                :on-success="uploadSignCertSuccess"
                :on-error="uploadFail"
                :show-file-list="false"
                :with-credentials="true"
                :accept="'.der, .pem'"
                :action="uploadPath"
              >
                <el-button
                  v-if="editable"
                  slot="trigger"
                  icon="el-icon-upload"
                  size="small"
                  type="primary"
                  >{{ $t("iot.protocol.selectFile") }}</el-button
                >
                <template v-if="opcuaForm.signCert">
                  <el-button
                    style="margin-left: 10px"
                    icon="el-icon-download"
                    size="small"
                    type="success"
                    @click="downloadCert(opcuaForm.signCert)"
                    >{{ $t("iot.protocol.downloadFile") }}</el-button
                  >
                </template>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.username')" prop="username">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.username"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.password')" prop="password">
            <el-input
              :readonly="!editable"
              type="password"
              v-model="opcuaForm.password"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="48">
        <el-col :span="12" v-if="opcuaForm.securityPolicy !== 'none'">
          <el-form-item
            :label="$t('iot.protocol.pkPassword')"
            prop="pkPassword"
          >
            <el-input
              :readonly="!editable"
              type="password"
              v-model="opcuaForm.pkPassword"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('iot.protocol.daqIntvl')" prop="daqIntvl">
            <el-input
              :readonly="!editable"
              v-model="opcuaForm.daqIntvl"
              maxlength="60"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row v-if="editable">
        <el-col :span="24" align="left">
          <el-button type="primary" @click="saveChannel">{{
            $t("commons.save")
          }}</el-button>
          <el-button @click="cancelEditChannel(null)">{{
            $t("commons.cancel")
          }}</el-button>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { downFile } from "@/utils/download";

export default {
  props: {
    channel: {
      required: true,
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    channel: {
      immediate: true,
      handler: function () {
        this.initChannel();
      },
    },
  },
  data() {
    return {
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload",
      securityPolicyList: [
        { value: "none", name: "None" },
        { value: "basicAndRsa", name: "Basic128Rsa15" },
        { value: "basic", name: "Basic256" },
      ],
      securityModeList: [
        { value: "sign", name: "Sign" },
        { value: "signAndEncrypt", name: "SignAndEncrypt" },
      ],
      opcuaForm: {
        channelId: null,
        channelName: null,
        channelAddress: null,
        securityPolicy: null,
        securityMode: null,
        securityCert: null,
        signCert: null,
        pkPassword: null,
        username: null,
        password: null,
        timeout: 3,
        daqIntvl: 1000,
      },
      opcuaRule: {
        channelName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        channelAddress: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        securityPolicy: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        securityMode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        securityCert: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        signCert: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        // username: [{
        //     required: true,
        //     trigger: ['blur', 'change'],
        //     message: this.$t('validate.required')
        // }],
        // password: [{
        //     required: true,
        //     trigger: ['blur', 'change'],
        //     message: this.$t('validate.required')
        // }],
        timeout: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  methods: {
    initChannel() {
      if (this.channel) {
        this.opcuaForm.channelId = this.channel.channelId;
        this.opcuaForm.channelName = this.channel.channelName;
        if (this.channel.extendConfig) {
          let customize = JSON.parse(this.channel.extendConfig);
          this.opcuaForm.channelAddress = customize.channelAddress;
          this.opcuaForm.securityPolicy = customize.securityPolicy;
          this.opcuaForm.securityMode = customize.securityMode;
          this.opcuaForm.securityCert = customize.securityCert;
          this.opcuaForm.signCert = customize.signCert;
          this.opcuaForm.pkPassword = customize.pkPassword;
          this.opcuaForm.username = customize.username;
          this.opcuaForm.password = customize.password;
          this.opcuaForm.timeout = customize.timeout;
          this.opcuaForm.daqIntvl = customize.daqIntvl;
        }
      }
    },
    saveChannel() {
      this.$refs.opcuaForm.validate((v) => {
        if (v) {
          if (
            this.opcuaForm.securityPolicy == "basicAndRsa" ||
            this.opcuaForm.securityPolicy == "basic"
          ) {
            if (!this.opcuaForm.securityCert || !this.opcuaForm.signCert) {
              this.$message({
                message: this.$t("iot.protocol.certNull"),
                type: "error",
              });
              return;
            }
          }
          let param = this.buildParam();
          // 回写数据
          this.cancelEditChannel(param);
        }
      });
    },
    changeSecurityPolicy() {
      if (this.opcuaForm.securityPolicy === "none") {
        this.opcuaForm.securityMode = "none";
      } else if (
        !this.opcuaForm.securityMode ||
        this.opcuaForm.securityMode === "none"
      ) {
        this.opcuaForm.securityMode = "sign";
      }
    },
    buildParam() {
      let param = {
        channelId: this.opcuaForm.channelId,
        channelName: this.opcuaForm.channelName,
        extendConfig: null,
      };
      param.extendConfig = JSON.stringify({
        daqIntvl: this.opcuaForm.daqIntvl,
        channelAddress: this.opcuaForm.channelAddress,
        securityPolicy: this.opcuaForm.securityPolicy,
        securityMode:
          this.opcuaForm.securityMode == null
            ? "none"
            : this.opcuaForm.securityMode,
        securityCert:
          this.opcuaForm.securityPolicy === "none"
            ? null
            : this.opcuaForm.securityCert,
        signCert:
          this.opcuaForm.securityPolicy === "none"
            ? null
            : this.opcuaForm.signCert,
        pkPassword:
          this.opcuaForm.securityPolicy === "none"
            ? null
            : this.opcuaForm.pkPassword,
        username: this.opcuaForm.username,
        password: this.opcuaForm.password,
        timeout: this.opcuaForm.timeout,
      });
      return param;
    },
    cancelEditChannel(v) {
      this.$emit("cancelEditChannel", v);
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    uploadSecurityCertSuccess(files) {
      this.opcuaForm.securityCert = files[0].fileId;
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
    },
    uploadSignCertSuccess(files) {
      this.opcuaForm.signCert = files[0].fileId;
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
    },
    downloadCert(fileId) {
      downFile(fileId);
    },
  },
};
</script>
