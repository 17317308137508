var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "customizeForm",
          attrs: {
            "label-position": "top",
            rules: _vm.customizeRule,
            model: _vm.customizeForm,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("iot.protocol.daqIntvl"),
                        prop: "daqIntvl",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: !_vm.editable,
                          maxlength: "60",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.customizeForm.daqIntvl,
                          callback: function ($$v) {
                            _vm.$set(_vm.customizeForm, "daqIntvl", $$v)
                          },
                          expression: "customizeForm.daqIntvl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "扩展配置", prop: "extendConfig" } },
                    [
                      _c("ace-editor", {
                        key: "extendConfig",
                        staticStyle: { border: "1px solid #ebecec" },
                        attrs: { lang: "json", theme: "chrome", height: "320" },
                        on: { init: _vm.editorInit },
                        model: {
                          value: _vm.customizeForm.extendConfig,
                          callback: function ($$v) {
                            _vm.$set(_vm.customizeForm, "extendConfig", $$v)
                          },
                          expression: "customizeForm.extendConfig",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }