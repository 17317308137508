<template>
  <div>
    <div align="right">
      <el-button
        style="margin-right: 10px"
        type="text"
        icon="el-icon-refresh"
        @click="refreshData()"
        >{{ $t("commons.refresh") }}</el-button
      >
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
      </finalTable>
    </div>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import moment from "moment";
import { getDeviceOpeHistory } from "@/api/ruge/vlink/device/device";
export default {
  name: "deviceOpeHistory",
  components: {
    finalTable,
  },
  props: ["currentRow"],
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "deviceStatus",
            label: "状态",
            width: "",
          },
          {
            prop: "reportTime",
            label: "时间",
            width: "420",
          },
        ],
        tableData: [],
        searchLineConfig: {
          deviceStatus: {
            type: "selector",
            label: "状态",
            value: "",
            placeholder: "请选择状态",
            actionType: "goSearch",
            optionList: [
              {
                label: "离线",
                value: "OFFLINE",
              },
              {
                label: "在线",
                value: "ONLINE",
              },
            ],
          },
          reportTime: {
            type: "dateRange",
            actionType: "goSearch",
            value: [null, null],
          },
        },
        detailConfig: {
          deviceStatus: {
            type: "enumerationColumn",
            emuList: {
              ONLINE: "在线",
              OFFLINE: "离线",
            },
          },
          reportTime: {
            type: "dateFormat",
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      listQuery: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
        listLoading: false,
        deviceStatus: null,
      },
    };
  },
  watch: {
    listQuery: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    refreshData() {
      this.getTableData();
    },
    getTableData() {
      this.listQuery.listLoading = true;
      const { productKey, deviceName } = this.currentRow;
      const { current, rowCount, deviceStatus, startTime, endTime } =
        this.listQuery;
      getDeviceOpeHistory({
        productKey,
        deviceName,
        current,
        rowCount,
        deviceStatus,
        startTime,
        endTime,
      })
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.listQuery.listLoading = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.reportTime && datas.params.reportTime.length > 0) {
          this.listQuery.startTime = datas.params.reportTime[0]
            ? new Date(datas.params.reportTime[0]).getTime()
            : null;
          this.listQuery.endTime = datas.params.reportTime[1]
            ? new Date(datas.params.reportTime[1]).getTime()
            : null;
          delete datas.params.reportTime;
        }
        this.listQuery = {
          ...this.listQuery,
          ...datas.params,
        };
        this.getTableData();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getTableData();
      }
    },
  },
};
</script>

<style>
</style>