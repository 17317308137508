var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "extend-edit-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            title: "扩展信息",
            width: "860px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c("DynamicForm", {
            ref: "dynamicForm",
            attrs: { dataset: _vm.dataset.extendJson },
          }),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: { click: _vm.dialogCloseHandler },
                },
                [_vm._v("取消")]
              ),
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: { click: _vm.saveHandler },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }